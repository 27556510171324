import {ScegliCorsoComponent} from "./superamento-corso-steps/scegli-corso/scegli-corso.component";
import {Injector, Type} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {SuperamentoCorsoMassivoDataI} from "./superamento-corso-steps/superamento-corso-steps-interface";
import {ScegliStudentiComponent} from "./superamento-corso-steps/scegli-studenti/scegli-studenti.component";
import {
    CompilaInfoSuperamentoComponent
} from "./superamento-corso-steps/compila-info-superamento/compila-info-superamento.component";
import {
    ConfirmSuperamentoCorsoComponent
} from "./superamento-corso-steps/confirm-superamento-corso/confirm-superamento-corso.component";
import {AuthorityType} from "../../../../api-clients/generated/services";
import {ApprovazioneSpeseMassivaDataI} from "./approvazione-spesa-steps/approvazione-spese-steps-interface";
import {ScegliSpeseComponent} from "./approvazione-spesa-steps/scegli-spese/scegli-spese.component";
import {
    ConfirmApprovazioneSpeseComponent
} from "./approvazione-spesa-steps/confirm-approvazione-spese/confirm-approvazione-spese.component";
import {
    CompilaInfoApprovazioneComponent
} from "./approvazione-spesa-steps/compila-info-approvazione/compila-info-approvazione.component";
import {
    ApprovazioneTrasversaliMassivaDataI
} from "./approvazione-trasversale-steps/approvazione-trasversale-steps-interface";
import {
    ScegliTrasversaliComponent
} from "./approvazione-trasversale-steps/scegli-trasversali/scegli-trasversali.component";
import {
    CompilaInfoApprovazioneTrasversaliComponent
} from "./approvazione-trasversale-steps/compila-info-approvazione-trasversali/compila-info-approvazione-trasversali.component";
import {
    ConfirmApprovazioneTrasversaliComponent
} from "./approvazione-trasversale-steps/confirm-approvazione-trasversali/confirm-approvazione-trasversali.component";
import {ApprovazionePianiMassivaDataI} from "./approvazione-piani-steps/approvazione-piani-steps-interface";
import {
    ScegliStudentiPianiComponent
} from "./approvazione-piani-steps/scegli-studenti-piani/scegli-studenti-piani.component";
import {
    CompilaInfoApprovazionePianiComponent
} from "./approvazione-piani-steps/compila-info-approvazione-piani/compila-info-approvazione-piani.component";
import {
    ConfirmApprovazionePianiComponent
} from "./approvazione-piani-steps/confirm-approvazione-piani/confirm-approvazione-piani.component";
import moment from "moment";
import {
    ScegliPeriodiRicercaComponent
} from "./approvazione-ricerca-steps/scegli-periodi-ricerca/scegli-periodi-ricerca.component";
import {ApprovazioneRicercaMassivaDataI} from "./approvazione-ricerca-steps/approvazione-ricerca-steps-interface";
import {
    CompilaInfoApprovazioneRicercaComponent
} from "./approvazione-ricerca-steps/compila-info-approvazione-ricerca/compila-info-approvazione-ricerca.component";
import {
    ConfirmApprovazioneRicercaComponent
} from "./approvazione-ricerca-steps/confirm-approvazione-ricerca/confirm-approvazione-ricerca.component";
import {
    ScegliPeriodiMobilitaComponent
} from "./approvazione-mobilita-steps/scegli-periodi-mobilita/scegli-periodi-mobilita.component";
import {
    CompilaInfoApprovazioneMobilitaComponent
} from "./approvazione-mobilita-steps/compila-info-approvazione-mobilita/compila-info-approvazione-mobilita.component";
import {ApprovazioneMobilitaMassivaDataI} from "./approvazione-mobilita-steps/approvazione-mobilita-steps-interface";
import {
    ConfirmApprovazioneMobilitaComponent
} from "./approvazione-mobilita-steps/confirm-approvazione-mobilita/confirm-approvazione-mobilita.component";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "./operazioni-massive.component";
import {ShowWithDeliberaStepComponent} from "./common-steps/show-with-delibera-step/show-with-delibera-step.component";
import {
    AutorizzazioneMobilitaMassivaDataI
} from "./autorizzazione-mobilita-steps/autorizzazione-mobilita-steps-interface";
import {
    ConfirmAutorizzazioneMobilitaComponent
} from "./autorizzazione-mobilita-steps/confirm-autorizzazione-mobilita/confirm-autorizzazione-mobilita.component";
import {
    ScegliStudentiAutorizzazioneMobilitaComponent
} from "./autorizzazione-mobilita-steps/scegli-studenti-autorizzazione-mobilita/scegli-studenti-autorizzazione-mobilita.component";
import {
    ApprovazioneRegistroAttivitaMassivaDataI
} from "./approvazione-registro-attivita-steps/approvazione-registro-attivita-steps-interface";
import {
    ScegliRegistriAttivitaComponent
} from "./approvazione-registro-attivita-steps/scegli-registri-attivita/scegli-registri-attivita.component";
import {
    CompilaInfoApprovazioneRegistroComponent
} from "./approvazione-registro-attivita-steps/compila-info-approvazione/compila-info-approvazione-registro.component";
import {
    ConfirmApprovazioneRegistroAttivitaComponent
} from "./approvazione-registro-attivita-steps/confirm-approvazione-registro-attivita/confirm-approvazione-registro-attivita.component";
import {
    ShowWithDeliberaForPlanStepComponent,
    ShowWithDeliberaForPlanStepI, StudyPlanWithDeliberaMode
} from "./common-steps/show-with-delibera-for-plan-step/show-with-delibera-for-plan-step.component";
import {ApprovazioneMissioniMassivaDataI} from "./approvazione-missioni-steps/approvazione-missioni-steps-interface";
import {ScegliMissioniComponent} from "./approvazione-missioni-steps/scegli-missioni/scegli-missioni.component";
import {
    ConfirmApprovazioneMissioniComponent
} from "./approvazione-missioni-steps/confirm-approvazione-missioni/confirm-approvazione-missioni.component";
import {
    ApprovazioneValidazioneMobilitaMassivaDataI
} from "./approvazione-validazione-mobilita-steps/approvazione-validazione-mobilita-steps-interface";
import {
    ScegliRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/scegli-richieste-validazione-mobilita/scegli-richieste-validazione.component";
import {
    CompilaInfoApprovazioneRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/compila-info-approvazione-richieste-validazione/compila-info-approvazione-richieste-validazione.component";
import {
    ConfirmApprovazioneRichiesteValidazioneComponent
} from "./approvazione-validazione-mobilita-steps/confirm-approvazione-richieste-validazione/confirm-approvazione-richieste-validazione.component";
import {
    ApprovazioneConclusioneMobilitaMassivaDataI
} from "./approvazione-conclusione-mobilita-steps/approvazione-conclusione-mobilita-steps-interface";
import {
    ScegliConclusioneMobilitaComponent
} from "./approvazione-conclusione-mobilita-steps/scegli-conclusione-mobilita/scegli-conclusione-mobilita.component";
import {
    ConfirmApprovazioneConclusioneMobilitaComponent
} from "./approvazione-conclusione-mobilita-steps/confirm-approvazione-conclusione-mobilita/confirm-approvazione-conclusione-mobilita.component";

// models

export enum OperazioneMassivaTipo {
    SUPERAMENTO_CORSO="SUPERAMENTO_CORSO",
    APPROVAZIONE_SPESE="APPROVAZIONE_SPESE",
    APPROVAZIONE_TRASVERSALI="APPROVAZIONE_TRASVERSALI",
    APPROVAZIONE_PIANI="APPROVAZIONE_PIANI",
    APPROVAZIONE_RICERCA="APPROVAZIONE_RICERCA",
    APPROVAZIONE_MOBILITA="APPROVAZIONE_MOBILITA",
    AUTORIZZAZIONE_MOBILITA="AUTORIZZAZIONE_MOBILITA",
    AUTORIZZAZIONE_REGISTRO_ATTIVITA="AUTORIZZAZIONE_REGISTRO_ATTIVITA",
    APPROVAZIONE_MISSIONI = 'APPROVAZIONE_MISSIONI',
    APPROVAZIONE_VALIDAZIONE_MOBILITA = 'APPROVAZIONE_VALIDAZIONE_MOBILITA',
    APPROVAZIONE_CONCLUSIONE_MOBILITA = 'APPROVAZIONE_CONCLUSIONE_MOBILITA'
}

export interface OperazioneMassivaI {
    descrizione: string;
    tipo: OperazioneMassivaTipo;
}

export interface OperazioneMassivaStepConfig {
    stepName: string;
    stepState: string;
    component: Type<any>;
    dataInjector: Injector;
    previewStringFn: (value: any) => string;
    hideNavButtons?: boolean;
    hideBack?: boolean;
}

export interface OperazioneMassivaDataI {
    showWithDelibera?: boolean;
    deliberaMode?: StudyPlanWithDeliberaMode;
    showWithDeliberaTranslatedLabel?: string;
    refreshData?: boolean;
}


// get operations and steps utils

export function getOperazioniDisponibiliBySottoruolo(sottoruolo: AuthorityType): OperazioneMassivaI[] {
    switch (sottoruolo) {
        case AuthorityType.DOCENTECORSO:
            return [
                { descrizione: "massive_operations.superamento_corso.op_title", tipo: OperazioneMassivaTipo.SUPERAMENTO_CORSO}
            ];
        case AuthorityType.COORDINATORE:
            return [
                { descrizione: "massive_operations.approvazione_trasversali.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_TRASVERSALI},
                { descrizione: "massive_operations.approvazione_mobilita.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_MOBILITA},
                { descrizione: "massive_operations.approvazione_ricerca.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_RICERCA},
                { descrizione: "massive_operations.approvazione_piani.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_PIANI},
                { descrizione: "massive_operations.approvazione_spese.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_SPESE},
                { descrizione: "massive_operations.autorizzazione_mobilita.op_title", tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA},
                { descrizione: "massive_operations.approvazione_registro_attivita.op_title", tipo: OperazioneMassivaTipo.AUTORIZZAZIONE_REGISTRO_ATTIVITA},
                { descrizione: "massive_operations.approvazione_missioni.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_MISSIONI},
                { descrizione: "massive_operations.approvazione_validazione_mobilita.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA},
                { descrizione: "massive_operations.approvazione_conclusione_mobilita.op_title", tipo: OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA},
            ];
        default:
            return [];
    }
}

export function getStepsByOperazione(tipo: OperazioneMassivaTipo, injector: Injector): OperazioneMassivaStepConfig[] {
    switch (tipo) {
        case OperazioneMassivaTipo.SUPERAMENTO_CORSO:
            return getSuperamentoCorsoMassivoConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_SPESE:
            return getApprovazioneSpeseMassivaConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_TRASVERSALI:
            return getApprovazioneTrasversaliMassivaConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_PIANI:
            return getApprovazionePianiMassivaConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_RICERCA:
            return getApprovazioneRicercaMassivaConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_MOBILITA:
            return getApprovazioneMobilitaMassivaConfig(injector);
        case OperazioneMassivaTipo.AUTORIZZAZIONE_MOBILITA:
            return getAutorizzazioneMobilitaMassivaConfig(injector);
        case OperazioneMassivaTipo.AUTORIZZAZIONE_REGISTRO_ATTIVITA:
            return getApprovazioneRegistroAttivitaMassivaConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_MISSIONI:
            return getApprovazioneMissioniMassivaConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_VALIDAZIONE_MOBILITA:
            return getApprovazioneValidazioneMobilitaMassivaConfig(injector);
        case OperazioneMassivaTipo.APPROVAZIONE_CONCLUSIONE_MOBILITA:
            return getApprovazioneConclusioneMobilitaMassivaConfig(injector);
        default:
            return [];
    }
}


// configs creator methods

export function getSuperamentoCorsoMassivoConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<SuperamentoCorsoMassivoDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.superamento_corso.steps.choose_course',
            component: ScegliCorsoComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                /*if(stepperDataSuperamento.corsoSelected){
                    return '- ' + stepperDataSuperamento.corsoSelected?.denominazione;
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.choose_students',
            component: ScegliStudentiComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
               /* if(stepperDataSuperamento.studentiSelected?.length > 0){
                    return '- ' + stepperDataSuperamento.studentiSelected.map(s => s.nome + ' ' + s.cognome).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.add_compile_info',
            component: CompilaInfoSuperamentoComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.superamento_corso.steps.confirm',
            component: ConfirmSuperamentoCorsoComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperDataSuperamento: SuperamentoCorsoMassivoDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneSpeseMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneSpeseMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_spese.steps.choose_budgets',
            component: ScegliSpeseComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
               /* if(stepperData.speseSelected?.length > 0){
                    return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese.steps.add_compile_info',
            component: CompilaInfoApprovazioneComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_spese.steps.confirm',
            component: ConfirmApprovazioneSpeseComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneSpeseMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneValidazioneMobilitaMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneValidazioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.choose_requests',
            component: ScegliRichiesteValidazioneComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
               /* if(stepperData.speseSelected?.length > 0){
                    return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.add_compile_info',
            component: CompilaInfoApprovazioneRichiesteValidazioneComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_validazione_mobilita.steps.confirm',
            component: ConfirmApprovazioneRichiesteValidazioneComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}


export function getApprovazioneConclusioneMobilitaMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneConclusioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_conclusione_mobilita.steps.choose_periods',
            component: ScegliConclusioneMobilitaComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
               /* if(stepperData.speseSelected?.length > 0){
                    return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_conclusione_mobilita.steps.confirm',
            component: ConfirmApprovazioneConclusioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneConclusioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneMissioniMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneMissioniMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_missioni.steps.choose_budgets',
            component: ScegliMissioniComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneMissioniMassivaDataI) => {
               /* if(stepperData.speseSelected?.length > 0){
                    return '- ' + stepperData.speseSelected?.map(s => s.codiceSpesa).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_missioni.steps.confirm',
            component: ConfirmApprovazioneMissioniComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneMissioniMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneRegistroAttivitaMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRegistroAttivitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_registro_attivita.steps.choose_registri',
            component: ScegliRegistriAttivitaComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_registro_attivita.steps.add_compile_info',
            component: CompilaInfoApprovazioneRegistroComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_registro_attivita.steps.confirm',
            component: ConfirmApprovazioneRegistroAttivitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneRegistroAttivitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneTrasversaliMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneTrasversaliMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_trasversali.steps.with_delibera',
            component: ShowWithDeliberaStepComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: OperazioneMassivaDataI) => {
               /* if(!!stepperData.showWithDeliberaTranslatedLabel){
                    return ' - ' + stepperData.showWithDeliberaTranslatedLabel;
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'phone',
            stepName: 'massive_operations.approvazione_trasversali.steps.choose_trasversali',
            component: ScegliTrasversaliComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                if(stepperData.attivitaSelected?.length > 0){
                    return '- ' + stepperData.attivitaSelected?.map(a => a.denominazione)?.join(', ');
                } else {
                    return '';
                }
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_trasversali.steps.add_compile_info',
            component: CompilaInfoApprovazioneTrasversaliComponent,
            dataInjector: Injector.create({providers: [
                { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
            ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                /*if(stepperData.deliberaCollegio){
                    return '- ' + stepperData.deliberaCollegio?.numero + ' - ' + moment(stepperData?.deliberaCollegio?.data, 'YYYY-MM-DD').format('DD/MM/YYYY');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_trasversali.steps.confirm',
            component: ConfirmApprovazioneTrasversaliComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneTrasversaliMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazionePianiMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazionePianiMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_piani.steps.with_delibera',
            component: ShowWithDeliberaForPlanStepComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: OperazioneMassivaDataI) => {
                /*if(!!stepperData.showWithDeliberaTranslatedLabel){
                    return ' - ' + stepperData.showWithDeliberaTranslatedLabel;
                } else {
                    return '';
                }
                 */
                return '';
            }
        },
        {
            stepState: 'phone',
            stepName: 'massive_operations.approvazione_piani.steps.choose_students',
            component: ScegliStudentiPianiComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
                /*if(stepperData.studentiSelected?.length > 0){
                    return '- ' + stepperData.studentiSelected?.map(s => s.cognome + ' ' + s.nome).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_piani.steps.add_compile_info',
            component: CompilaInfoApprovazionePianiComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
               /* if(stepperData.deliberaCollegio){
                    return '- ' + stepperData.deliberaCollegio?.numero + ' - ' + moment(stepperData?.deliberaCollegio?.data, 'YYYY-MM-DD').format('DD/MM/YYYY');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_piani.steps.confirm',
            component: ConfirmApprovazionePianiComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazionePianiMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneRicercaMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRicercaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_ricerca.steps.choose_periods',
            component: ScegliPeriodiRicercaComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                /*if(stepperData.periodiSelected?.length > 0){
                    return '- ' + stepperData.periodiSelected?.map(s =>
                        s?.codiceMobilita + ' (' + s?.studenteCiclo?.utente?.cognome + ' ' + s?.studenteCiclo?.utente?.nome + ')'
                    ).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_ricerca.steps.add_compile_info',
            component: CompilaInfoApprovazioneRicercaComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_ricerca.steps.confirm',
            component: ConfirmApprovazioneRicercaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneRicercaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getApprovazioneMobilitaMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<ApprovazioneRicercaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.approvazione_mobilita.steps.choose_periods',
            component: ScegliPeriodiMobilitaComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
               /* if(stepperData.periodiSelected?.length > 0){
                    return '- ' + stepperData.periodiSelected?.map(s =>
                        s?.codiceMobilita + ' (' + s?.studenteCiclo?.utente?.cognome + ' ' + s?.studenteCiclo?.utente?.nome + ')'
                    ).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_mobilita.steps.add_compile_info',
            component: CompilaInfoApprovazioneMobilitaComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.approvazione_mobilita.steps.confirm',
            component: ConfirmApprovazioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: ApprovazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

export function getAutorizzazioneMobilitaMassivaConfig(injector: Injector): OperazioneMassivaStepConfig[] {
    const stepFlowData = new BehaviorSubject<AutorizzazioneMobilitaMassivaDataI>({});
    return [
        {
            stepState: 'phone',
            hideBack: true,
            stepName: 'massive_operations.autorizzazione_mobilita.steps.choose_students',
            component: ScegliStudentiAutorizzazioneMobilitaComponent,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: AutorizzazioneMobilitaMassivaDataI) => {
                /*if(stepperData.studentiSelected?.length > 0){
                    return '- ' + stepperData.studentiSelected?.map(s => s.cognome + ' ' + s.nome).join(', ');
                } else {
                    return '';
                }*/
                return '';
            }
        },
        {
            stepState: 'chat',
            stepName: 'massive_operations.autorizzazione_mobilita.steps.confirm',
            component: ConfirmAutorizzazioneMobilitaComponent,
            hideNavButtons: true,
            dataInjector: Injector.create({providers: [
                    { provide: OPERAZIONE_MASSIVA_DATA$, useValue: stepFlowData },
                    { provide: CAN_GO_AHEAD$, useValue: new BehaviorSubject<boolean>(false) }
                ], parent: injector}),
            previewStringFn: (stepperData: AutorizzazioneMobilitaMassivaDataI) => {
                return '';
            }
        },
    ];
}

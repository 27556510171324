import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {Router} from '@angular/router';
import {PathEnum} from '../../../app.routing';
import {NavigationService} from '../../../core/navigation/navigation.service';
import {MatDrawer} from "@angular/material/sidenav";
import {StudentDetailsComponent} from "../../../modules/landing/student-details/student-details.component";
import {
    AggiornamentoPianoDiStudiStatus, AttivitaExtraPianoDiStudiStatus,
    PianoDiStudiStatus, TicketPriority, TicketStatus,
    TipoModificaCorsoPianoDiStudi
} from "../../../../api-clients/generated/services";
import {
    PianoDiStudiStatusExtended
} from "../../../modules/landing/cycle/training-offer/dialog-invalid-study-plans/dialog-invalid-study-plans.component";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationDialogListDataI, FuseConfirmationService} from "../../../../@fuse/services/confirmation";


export interface TornaIndietro {
    title?: string;
    href: string;
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Input() title: string;
    @Input() tornIndietro: {title: string; path: PathEnum};
    @Input() subtitle: string;
    @Input() isInternalMenuHeader: boolean;
    @Output() toogleInternalMenuEmitter: EventEmitter<boolean>= new EventEmitter<boolean>();
    @Input() showMiniLoader: boolean;
    @Output() filterMenuEmitter: EventEmitter<boolean>= new EventEmitter<boolean>();
    @Input()  showFilterButton: any;
    @Input() dataChip: PianoDiStudiStatusExtended;
    @Input() dataChipDialogList: string[];
    @Input() showChip: boolean = false;

    constructor(private router: Router,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService) {
    }

    goBack(): void{
        this.router.navigateByUrl(this.tornIndietro.path);
    }

    toogleInternalMenu() {
        this.toogleInternalMenuEmitter.emit(true);
    }

    protected readonly PianoDiStudiStatus = PianoDiStudiStatus;
    protected readonly PianoDiStudiStatusExtended = PianoDiStudiStatusExtended;

    ifNotValidOpenPlanNotValidDialog() {
        if(this.dataChip === PianoDiStudiStatusExtended.APPROVATONONVALIDO) {
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            const notValidityRaisons: FuseConfirmationDialogListDataI[] = this.dataChipDialogList
                ?.sort()
                ?.map(r => ({
                    mainText: r
                }));
            this.fuseConfirmationService.open({
                    title: get(translation, 'study_plan_status.approved_not_valid', null),
                    message: get(translation, 'study_plan_status.approved_not_valid_message', null),
                    icon: {
                        show: true,
                        name: 'mat_outline:warning',
                        color: 'warning'
                    },
                    onBackdrop: {
                        show: false,
                        backdrop: true
                    },
                    listData: notValidityRaisons,
                    actions: [
                        {
                            color: 'accent',
                            label: get(translation, 'common.close', null), icon: 'close',
                        },
                    ]
                },
            );
        }
    }

}

import {
    AuthorityType,
    DeliberaDiProgrammazioneMobilitaFiltered,
    GetDeliberaDTO,
    PageDeliberaDiProgrammazioneMobilitaFiltered,
    PagePeriodoDiMobilitaStudenteInfoView,
    PageStudenteCicloUtenteViewImpl,
    PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus,
    RichiestaDiRetribuzioneMobilitaInfoView,
    RichiestaDiRetribuzioneMobilitaStatus,
    TipoPeriodoEnum
} from "../../../../api-clients/generated/services";
import {ConfigCol, numFmtEuro} from "../../../shared/service/excel.service";
import moment from "moment";
import {makeFilename, openFileInBlankWindow, removeUnderscore} from "../../../shared/utils/utils";
import {
    ConfigurazioneColonna,
    ExpandedGenericTable,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../shared/components/table/model/generic-table-model";
import {DEFAULT_PAGE_SIZE} from "./mobility-dashboard.component";
import {PathEnum} from "../../../app.routing";
import {Translation, TranslocoService} from "@ngneat/transloco";
import {get, isEmpty, isNil} from "lodash";
import {RemoveUnderscoresPipe} from "../../../shared/pipe/remove-underscores.pipe";
import {UpperCasePipe} from "@angular/common";
import {filter, switchMap, tap} from "rxjs/operators";
import {finalize, take, takeUntil} from "rxjs";
import {CicloCorsoRuoloInterface} from "../../../shared/interface/CicloCorsoRuoloInterface";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {hideDelibera} from "../../../../@fuse/services/confirmation";
import {HttpResponse} from "@angular/common/http";

export const PERIODI_EXCEL_SHEET_CONFIG: ConfigCol[] = [
    {
        key: 'dettaglioStudenteLink',
        translocoHeader: '',
        width: 22,
    },
    {
        key: 'codiceMobilita',
        translocoHeader: 'mobility.mobility_code',
        width: 17
    },
    {
        key: 'tipoPeriodoEnum',
        translocoHeader: 'mobility.period_type',
        width: 20
    },
    {
        key: 'studenteNomeCompleto',
        translocoHeader: 'students_list.student',
        width: 25
    },
    {
        key: 'studenteMatricola',
        translocoHeader: 'mobility.enrollment_number',
        width: 15
    },
    {
        key: 'studenteEmail',
        translocoHeader: 'mobility.student_email',
        width: 30
    },
    {
        key: 'stato',
        translocoHeader: 'mobility.state',
        width: 15
    },
    {
        key: 'dataInizio',
        translocoHeader: 'mobility.start_date',
        width: 12,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'dataFine',
        translocoHeader: 'mobility.end_date',
        width: 12,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'strutturaEsteraOspitanteDescrizione',
        translocoHeader: 'mobility.foreign_university',
        width: 35
    },
    {
        key: 'tipologiaMobilitaDescrizione',
        translocoHeader: 'mobility.mobility_type',
        width: 20
    },
    {
        key: 'tipologiaBonusDescrizione',
        translocoHeader: 'mobility.bonus_tipology',
        width: 20
    },
    {
        key: 'tipologiaInterventoSupporto',
        translocoHeader: 'mobility.support_tipology',
        width: 20
    },
    {
        key: 'sedeCotutela',
        translocoHeader: 'mobility.cotutela',
        width: 20
    },
    {
        key: 'sedeDoctorEuropeaus',
        translocoHeader: 'mobility.doctor_europeaus',
        width: 20
    },
    {
        key: 'importo',
        translocoHeader: 'common.amount',
        width: 10,
        style: {
            numFmt: numFmtEuro
        }
    },
    {
        key: 'approvalNumber',
        translocoHeader: 'budget.approval_number',
        width: 20
    },
];

function buildLinkForDettaglioStudente(periodo: PeriodoDiMobilitaStudenteInfoView, basePath: string) {
    const fragment = periodo?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ? 'mobilita' : 'ricerca';
    return basePath + '/' + PathEnum.STUDENTS + '/' + periodo?.studenteCiclo?.utente?.id + '/' + PathEnum.MOBILITA + '#' + fragment;
}

export function mapPeriodiForExcel(periodi: PeriodoDiMobilitaStudenteInfoView[], currentBaseURL: string, translation: Translation): any[] {

    return periodi.map(periodo => ({
        ...periodo,
        strutturaEsteraOspitanteDescrizione: (periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione)
            + ' (' + periodo?.nazioneStrutturaEstera?.codice + ')',
        tipologiaMobilitaDescrizione: periodo?.tipologiaMobilita?.descrizione,
        tipologiaBonusDescrizione: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
        dataInizio: !!periodo?.dataInizio ? moment(periodo?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        dataFine: !!periodo?.dataFine ? moment(periodo?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        studenteNomeCompleto: periodo?.studenteCiclo?.utente?.cognome + ' ' + periodo?.studenteCiclo?.utente?.nome,
        studenteMatricola: periodo?.studenteCiclo?.matricola,
        studenteEmail: periodo?.studenteCiclo?.emailAteneo?.toLowerCase(),
        tipologiaInterventoSupporto: periodo?.tipoInterventoSupporto?.descrizione,
        stato: removeUnderscore(periodo?.stato),
        tipoPeriodoEnum: removeUnderscore(periodo?.tipoPeriodoEnum),
        dettaglioStudenteLink:  { text: get(translation, 'mobility.go_to_detail', null), hyperlink: buildLinkForDettaglioStudente(periodo, currentBaseURL) },
    }))
}

export function buildMobilityRequestsTableConfiguration(pagePeriodi: PagePeriodoDiMobilitaStudenteInfoView,
                                                        pageSize: number = DEFAULT_PAGE_SIZE,
                                                        currentSottoruolo: AuthorityType,
                                                        currentRuolo: AuthorityType,
                                                        translation: Translation,
                                                        isResearch?: boolean,
                                                        filterStatiRetribuzione?: RichiestaDiRetribuzioneMobilitaStatus[]): GenericTableConfigurationModel {
    const periodiDiMobilitaStudenteForUI = pagePeriodi?.content?.map((periodo: PeriodoDiMobilitaStudenteInfoView) =>
        ({
            ...periodo,
            strutturaEsteraOspitanteDescrizione: (periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione)
                + ' (' + periodo?.nazioneStrutturaEstera?.codice + ')',
            tipologiaMobilitaDescrizione: periodo?.tipologiaMobilita?.descrizione,
            tipologiaBonusDescrizione: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
            dataRichiestaFormatted: moment(periodo?.dataRichiesta).format('DD/MM/YYYY HH:mm'),
            dataInizio: !!periodo?.dataInizio ? moment(periodo?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
            dataFine: !!periodo?.dataFine ? moment(periodo?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
            studenteUIMapped: [{
                ...periodo?.studenteCiclo,
                nome: periodo?.studenteCiclo?.utente?.nome,
                cognome: periodo?.studenteCiclo?.utente?.cognome,
                codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale
            }],
            richiesteRetribuzione: periodo.richiesteDiRetribuzioneMobilita
                ?.filter(val => !filterStatiRetribuzione || filterStatiRetribuzione.includes(val.stato))
                ?.map(periodoRetribuzione => ({
                ...periodoRetribuzione,
                periodiFormatted: periodoRetribuzione.periodi?.sort(
                    (p1, p2) =>
                        moment(p1.dataInizio, 'YYYY-MM-DD').unix() - moment(p2.dataInizio, 'YYYY-MM-DD').unix()
                )?.map(p =>
                    get(translation, 'common.from', null) + ' ' +
                    moment(p?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') + ' ' +
                    get(translation, 'common.to', null) + ' ' +
                    moment(p?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY'))?.
                join(', ')
            }))
        }));
    let config: GenericTableConfigurationModel = {
        configuration: {
            data: periodiDiMobilitaStudenteForUI,
            totalElements: pagePeriodi?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.mobility_code',
                    colonnaKey: 'codiceMobilita',
                    flex: 11,
                },
                /*{
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.request_date',
                    colonnaKey: 'dataRichiestaFormatted',
                    flex: 11,
                },*/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.mobility_type',
                    colonnaKey: 'tipologiaMobilitaDescrizione',
                    flex: 11,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 30,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'mobility.state',
                    colonnaKey: 'stato',
                    flex: 15,
                    statusType: PeriodoDiMobilitaStudenteStatus
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.start_date',
                    colonnaKey: 'dataInizio',
                    flex: 9,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.end_date',
                    colonnaKey: 'dataFine',
                    flex: 9,
                },
                /*                {
                                    tipo: TipoColonnaEnum.NUMBER,
                                    nomeColonna: 'mobility.days',
                                    colonnaKey: 'giorni',
                                    flex: 7,
                                },*/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.foreign_university',
                    colonnaKey: 'strutturaEsteraOspitanteDescrizione',
                    flex: 18,
                },
                /*                    {
                                        tipo: TipoColonnaEnum.STRING,
                                        nomeColonna: 'mobility.mobility_type',
                                        colonnaKey: 'tipologiaMobilitaDescrizione',
                                        flex: 15,
                                    },
                                    {
                                        tipo: TipoColonnaEnum.STRING,
                                        nomeColonna: 'mobility.bonus_tipology',
                                        colonnaKey: 'tipologiaBonusDescrizione',
                                        flex: 15,
                                    },*/
                /*                    {
                                        tipo: TipoColonnaEnum.STRING,
                                        nomeColonna: 'mobility.note',
                                        colonnaKey: 'note',
                                        flex: 20,
                                    },*/
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 7,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'event',
                            click: TipoClickEnum.GO_TO_MOBILITA_STUDENTE,
                            tooltip: 'common.go_to_mobility',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'mobility.info',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_MOBILITY_REQUEST_DELIBERA,
                            tooltip: 'mobility.show_delibera',
                            color: 'accent',
                            show: (stato, periodo: PeriodoDiMobilitaStudenteInfoView) => !!periodo?.delibera?.data &&  !hideDelibera.includes(currentRuolo),
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'download',
                            click: TipoClickEnum.DOWNLOAD_CONCLUSION_MOBILITY_REQUEST_ATTACHMENTS,
                            tooltip: 'mobility.download_conclusion_attachments',
                            color: 'accent',
                            show: (stato, periodo: PeriodoDiMobilitaStudenteInfoView) => periodo?.urlAttestatiDiConclusione?.length > 0,
                            valueShow: 'stato'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: isResearch ? 'custom_table.no_data_research' :  'custom_table.no_data_mobility',
            expandRow: buildConfigurationForRetributionRequests(currentSottoruolo, currentRuolo)
        }
    };
    return config;
}

export function buildDelibereTableConfiguration(pageDelibere: PageDeliberaDiProgrammazioneMobilitaFiltered,
                                                        pageSize: number = DEFAULT_PAGE_SIZE,
                                                        currentSottoruolo: AuthorityType,
                                                        currentRuolo: AuthorityType): GenericTableConfigurationModel {
    const delibereForUI = pageDelibere?.content?.map((deliberaDiProgrammazione: DeliberaDiProgrammazioneMobilitaFiltered) =>
        ({
            ...deliberaDiProgrammazione,
            numero: deliberaDiProgrammazione?.delibera?.numero,
            dataFormatted: moment(deliberaDiProgrammazione?.delibera?.data).format('DD/MM/YYYY'),
            codiceCorsoDiStudiEsse3: deliberaDiProgrammazione?.delibera?.codiceCorsoDiStudiEsse3,
            url: deliberaDiProgrammazione?.delibera?.url
        }));
    let config: GenericTableConfigurationModel = {
        configuration: {
            data: delibereForUI,
            totalElements: pageDelibere?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.approval_number',
                    colonnaKey: 'numero',
                    flex: 45,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'documents_dashboard.approval_date',
                    colonnaKey: 'dataFormatted',
                    flex: 40,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.course',
                    colonnaKey: 'codiceCorsoDiStudiEsse3',
                    flex: 40,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 15,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.DOWNLOAD,
                            tooltip: 'documents_dashboard.approval_download',
                            show: (value, element) => element?.url,
                            color: 'accent',
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW_DELIBERA_MOBILITA_DETAIL,
                            tooltip: 'mobility.show_detail_delibera',
                            color: 'accent',
                            valueShow: 'numero'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.EDIT,
                            tooltip: 'mobility.delibera_edit',
                            color: 'accent',
                            valueShow: 'numero',
                            show: (value, element) => currentSottoruolo === AuthorityType.GRUPPOPTA || currentSottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO,
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.DELETE,
                            tooltip: 'mobility.delibera_delete',
                            color: 'accent',
                            valueShow: 'numero',
                            show: (value, element) => currentSottoruolo === AuthorityType.GRUPPOPTA || currentSottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO,
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data'
        }
    };
    return config;
}


function downloadDeliberaFn(periodo: PeriodoDiMobilitaStudenteInfoView, componentRef: any) {
    const activeLang = componentRef.translocoService.getActiveLang();
    const translation = componentRef.translocoService.getTranslation().get(activeLang);
    let nomeFile = periodo?.delibera?.url;
    const requestBody: GetDeliberaDTO = {
        nomeFile: nomeFile,
        numeroDelibera: periodo?.delibera?.numero
    }
    componentRef.fuseConfirmationService.showLoader();
    componentRef.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
        filter(Boolean),
        take(1),
        tap((info: CicloCorsoRuoloInterface) => componentRef.currentRuolo = info.ruolo),
        switchMap(() => componentRef.delibereService.downloadDelibera(requestBody, 'response')),
        takeUntil(componentRef.destroy$),
        finalize(() => {
            componentRef.fuseConfirmationService.hideLoader();
        })
    ).subscribe({
        next: (fileResponse: HttpResponse<Blob>) => {
            const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
            //const fileNameToDownload = makeFilename(fileName);
            openFileInBlankWindow(fileResponse?.body, fileName);
            componentRef.fuseConfirmationService.openSnackBar({
                message: get(translation, 'student.file_show_success', null),
                type: SnackbarTypes.Success,
            });
        },
        error: (err) => {
            console.error(err);
            componentRef.fuseConfirmationService.openSnackBar({
                message: get(translation, 'mobility.file_download_error', null),
                type: SnackbarTypes.Warning,
            });
        }
    });
}

export function infoAggiuntivePeriodoMobilitaDashboard(periodo: PeriodoDiMobilitaStudenteInfoView,
                                                       translation: Translation,
                                                       currentLang: string,
                                                       componentRef: any,
                                                       currentRuolo: AuthorityType) {
    const yesString = get(translation,'common.yes', null);
    const noString = get(translation,'common.no', null);
    const data = {
        readonlyData: [
            {
                key: 'mobility.mobility_code',
                value: periodo?.codiceMobilita,
            },
            {
                key: 'mobility.period_type',
                value: periodo?.tipoPeriodoEnum,
                pipe: RemoveUnderscoresPipe,
            },
            {
                key: 'mobility.student',
                value: periodo?.studenteCiclo?.utente?.cognome + ' ' + periodo?.studenteCiclo?.utente?.nome,
                pipe: UpperCasePipe
            },
            {
                key: 'mobility.start_date',
                value: periodo?.dataInizio,
            },
            {
                key: 'mobility.end_date',
                value: periodo?.dataFine,
                hide: !periodo?.dataFine
            },
            {
                key: 'mobility.mobility_type',
                value: periodo?.tipologiaMobilita?.descrizione,
            },
            {
                key: 'mobility.bonus_tipology',
                value: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
                hide: !periodo?.tipologiaBonus?.descrizioneTipologiaBonus
            },
            {
                key: 'mobility.support_tipology',
                value: periodo?.tipoInterventoSupporto?.descrizione,
                hide: !periodo?.tipoInterventoSupporto?.descrizione
            },
            {
                key: 'mobility.foreign_university',
                value: periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione,
            },
            {
                key: 'mobility.foreign_university_nationality_expanded',
                value: currentLang === 'it' ? periodo?.nazioneStrutturaEstera?.descrizioneIt : periodo?.nazioneStrutturaEstera?.descrizione,
                hide: !(periodo as PeriodoDiMobilitaStudenteInfoView)?.nazioneStrutturaEstera?.descrizioneIt,
            },
/*            {
                key: 'mobility.cotutela',
                value: periodo?.cotutela ? yesString : noString,
            },*/
/*            {
                key: 'mobility.place_cotutela',
                value: periodo?.sedeCotutela,
                hide: !periodo?.cotutela
            },*/
            {
                key: 'mobility.doctor_europeaus',
                value: periodo?.doctorEuropeaus ? yesString : noString,
            },
            {
                key: 'mobility.place_doctor_europeaus',
                value: periodo?.sedeDoctorEuropeaus,
                hide: !periodo?.doctorEuropeaus
            },
/*            {
                key: 'budget.refuse_reason',
                value: periodo?.motivazioneRifiuto,
                hide: !periodo?.motivazioneRifiuto
            },*/
            {
                key: 'budget.approval_date',
                value: !!periodo?.delibera?.data ?
                    moment(periodo?.delibera?.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                hide: !periodo?.delibera?.data || currentRuolo === AuthorityType.STUDENTE
            },
            {
                key: 'budget.approval_number',
                value: periodo?.delibera?.numero,
                hide: !periodo?.delibera?.numero || currentRuolo === AuthorityType.STUDENTE,
                showVisualizzaButton: !!periodo?.delibera?.url,
                buttonAction: () => downloadDeliberaFn(periodo, componentRef),
            },
/*            {
                key: 'mobility.cancel_cause',
                value: periodo?.motivoAnnullamento,
                hide: !periodo?.motivoAnnullamento
            },*/
            {
                key: 'mobility.refuse_completion_request_reason',
                value: periodo?.motivoRifiutoRichiestaConclusione,
                hide: !periodo?.motivoRifiutoRichiestaConclusione
            },
        ],
    };
    return data;
}

export function buildConfigurationForRetributionRequests(currentSottoruolo: AuthorityType,
                                                         currentRuolo: AuthorityType): ExpandedGenericTable {
    return {
        expand: true,
        tableTitle: 'mobility.partial_request_mixed',
        dataKey: 'richiesteRetribuzione',
        parentIdKey: 'codiceMobilita',
        hidePaginator: true,
        configurazioneTabella: [
            {
                tipo: TipoColonnaEnum.STRING,
                nomeColonna: 'mobility.periods',
                colonnaKey: 'periodiFormatted',
                flex: 25,
            },
            {
                tipo: TipoColonnaEnum.CHIP_STATUS,
                nomeColonna: 'mobility.retribution_status',
                colonnaKey: 'stato',
                flex: 25,
                statusType: RichiestaDiRetribuzioneMobilitaStatus,
                sottoruoloKey: 'sottoruoloPrimoApprovatore'
            },
            {
                tipo: TipoColonnaEnum.AMOUNT,
                nomeColonna: 'mobility.retribution_amount',
                colonnaKey: 'importo',
                flex: 15,
            },
            {
                tipo: TipoColonnaEnum.STRING,
                nomeColonna: 'mobility.days',
                colonnaKey: 'giorni',
                flex: 15,
            },
            {
                nomeColonna: ' ',
                colonnaKey: 'action',
                flex: 20,
                tipo: TipoColonnaEnum.ACTION_MENU,
                valueCheck: 'l',
                button: [
                    {
                        nameIconButton: 'download',
                        click: TipoClickEnum.DOWNLOAD_RETRIBUTION_MOBILITY_ATTACHMENTS,
                        tooltip: 'mobility.download_partial_request_attachments',
                        color: 'accent',
                        show: (stato, richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) => richiestaRetribuzione.urlFileAttestato?.length > 0,
                        valueShow: 'stato'
                    },
                    {
                        nameIconButton: 'visibility',
                        click: TipoClickEnum.SHOW_REFUSE_REASON,
                        tooltip: 'mobility.show_refuse_reason',
                        color: 'accent',
                        show: (stato, richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) => !!richiestaRetribuzione.motivazioneRifiuto,
                        valueShow: 'stato'
                    },
                    {
                        nameIconButton: 'visibility',
                        click: TipoClickEnum.SHOW_RETRIBUTION_MOBILITA_DETAILS,
                        show: (stato, richiestaRetribuzione: RichiestaDiRetribuzioneMobilitaInfoView) =>
                            !!richiestaRetribuzione.dettaglioCalcoloImporto ||
                            !!richiestaRetribuzione.notePrimoApprovatore ||
                            !!richiestaRetribuzione.noteSecondoApprovatore ||
                            !!richiestaRetribuzione.noteValidatorePta,
                        tooltip: 'mobility.show_retribution_details',
                        color: 'accent',
                        valueShow: 'stato'
                    },
                ]
            }

        ],
    };
}

export function buildAutorizzazioniTableConfiguration(pageStudente: PageStudenteCicloUtenteViewImpl,
                                                      translocoService: TranslocoService,
                                                      currentSottoruolo: AuthorityType,
                                                      pageSize: number = DEFAULT_PAGE_SIZE): GenericTableConfigurationModel {
    const activeLang = translocoService.getActiveLang();
    const translation = translocoService.getTranslation().get(activeLang);
    return {
        configuration: {
            data: pageStudente?.content?.map(studenteContent => ({
                ...studenteContent,
                codiceFiscale: studenteContent?.codiceFiscale?.toUpperCase(),
                studente: studenteContent.cognome + ' ' + studenteContent.nome,
                supervisore: (studenteContent?.supervisore?.cognome || studenteContent?.supervisore?.nome ) ?
                    ((studenteContent?.supervisore?.cognome || '') + ' ' + (studenteContent?.supervisore?.nome || '')).toUpperCase() : '',
                tipologiaBorsa: (studenteContent?.tipoBorsaPerCiclo?.descrizioneTipoBorsa),
                curriculum: (studenteContent?.percorsoDiStudi?.descrizionePercorsoDiStudi),
                dataIscrizione: formatDay(studenteContent?.dataIscrizione),
                tasseInRegola: isNil(studenteContent.tasseInRegola) ? '' : get(translation, studenteContent.tasseInRegola ? 'common.yes' : 'common.no')
            })),
            sticky: true,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            totalElements: pageStudente.totalElements,
            isPaginatedBE: true,
            messaggioDatiAssenti: 'custom_table.no_data_autorizzazioni',
            hidePaginator: false,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.student',
                    colonnaKey: 'studente',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.fiscal_code',
                    colonnaKey: 'codiceFiscale',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'menu_filter.enrollment_number',
                    colonnaKey: 'matricola',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.university_email',
                    colonnaKey: 'emailAteneo',
                    flex: 30,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 15,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'event',
                            click: TipoClickEnum.GO_TO_MOBILITA_STUDENTE,
                            tooltip: 'common.go_to_mobility',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.REVOKE_MOBILITA_AUTORIZZAZIONE,
                            tooltip: 'mobility.revoke_autorization',
                            color: 'accent',
                            valueShow: 'numero',
                            show: (value, element) => currentSottoruolo === AuthorityType.COORDINATORE,
                        },

                    ]
                }
            ]
        }
    };
}

export function  formatDay(date: string) {
    return moment(new Date(date)).format('DD/MM/YYYY');
}

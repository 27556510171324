import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {
    AggiornamentoPianoDiStudiStatus,
    AuthorityType,
    CicliCorsiDiStudiService,
    MediaService,
    MobilitaRequestSelectFormValuesDTO,
    PageStudenteCicloPianoDiStudiViewImpl,
    PeriodoDiMobilitaStudenteStatus,
    PianiDiStudiService, PianoDiStudiStatus,
    SpesaStudenteStatus,
    StudenteCicloMotivoStatus, StudenteCicloPianoDiStudiViewImpl,
    StudenteCicloStatus,
    StudenteCicloUtenteViewImpl,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {GenericTableComponent} from "../../../../../shared/components/table/generic-table/generic-table.component";
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {DEFAULT_PAGE_SIZE} from "../approvazione-piani-formativi.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    StudyPlanFilters,
    StudyPlansFilterService
} from "../study-plans-sidebar-filter-container-request/study-plans-filter.service";
import {TranslocoService} from "@ngneat/transloco";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {ExcelService} from "../../../../../shared/service/excel.service";
import {finalize, Observable, of, switchMap, take, takeUntil} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {
    getLanguagePathByAggiornamentoPianoDiStudiStatus, getLanguagePathByPianoDiStudiStatus,
    getLanguagePathByStudenteCicloMotivoStatus,
    getLanguagePathByStudenteCicloStatus,
    makeFilenameFromFE
} from "../../../../../shared/utils/utils";
import {buildStudyPlansConfiguration} from "../../requests-utils";
import {PathEnum} from "../../../../../app.routing";
import {get, isArray, isEmpty, isNil} from "lodash";
import {PageEvent} from "@angular/material/paginator";
import fs from "file-saver";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";


@Component({
    selector: 'app-table-study-plans',
    templateUrl: './table-study-plans.component.html',
    styleUrls: ['./table-study-plans.component.scss']
})
export class TableStudyPlansComponent extends AbstractDefaultComponent implements OnInit {

    @Input() currentRuolo: AuthorityType;
    @Output() toogleFilterContainer$ = new EventEmitter<{ hasToogleInternalMenu: boolean }>();
    table: GenericTableComponent;

    @ViewChild(GenericTableComponent) set genericTable(gt: GenericTableComponent) {
        this.table = gt;
    }

    tableConfiguration: GenericTableConfigurationModel;
    page: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    showMiniLoader: boolean;
    loading: boolean;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    error: boolean;
    isFirstTime: boolean = true;

    constructor(protected fuseConfirmationService: FuseConfirmationService,
                protected localStorageService: LocalStorageService,
                protected appInitService: AppInitService,
                protected studyPlansFilterService: StudyPlansFilterService,
                protected translocoService: TranslocoService,
                protected logoutService: LogoutService,
                protected router: Router,
                protected mediaService: MediaService,
                protected sanitizer: DomSanitizer,
                protected dialog: MatDialog,
                protected excelService: ExcelService,
                protected cicliCorsiDiStudiService: CicliCorsiDiStudiService,
                private pianiDiStudiService: PianiDiStudiService,
                protected studentiCicloService: StudentiCicloService) {
        super();
        this.fieldsLabelMap = this.studyPlansFilterService.fieldsLabelMap;
    }

    ngOnInit(): void {
        this.studyPlansFilterService.checkApplyFilterClick$.asObservable().pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.studyPlansFilterService.page = 0;
            if (value) {
                this.getData(this.isFirstTime, this.studyPlansFilterService?.filterApplied, this.studyPlansFilterService.page, this.studyPlansFilterService.pageSize, 'cognome', true);
            } else {
                this.getData(this.isFirstTime, this.studyPlansFilterService?.filterApplied, this.studyPlansFilterService.page, this.studyPlansFilterService.pageSize);
            }
        })
    }


    getData(isFirstTime?: boolean, filters?: StudyPlanFilters, page: number = 0, size: number = 10,
            sort?: string , valueForm?: boolean): void {
        this.error = false;

        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(value => this.getData$(isFirstTime, filters, page, size, sort, valueForm))
        ).subscribe({
            next: (value) => {
                this.studyPlansFilterService.mainFormGroup?.patchValue(filters, {emitEvent: true});
                this.currentFilterListChipLabels = this.getListChipsLabel();
            },
            error: (err) => {
                console.log(err);
                this.error = true;
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => this.logoutService.goToHome(),
                    () => this.getData(isFirstTime, filters, page, size, sort, valueForm),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }


    getData$(isFirstTime?: boolean, filters: StudyPlanFilters = {}, page: number = 0, size: number = DEFAULT_PAGE_SIZE,
             sort?: string, valueForm?: boolean): Observable<PageStudenteCicloPianoDiStudiViewImpl> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }

        if(this.studyPlansFilterService?.applyFiltersQuickly?.lastUpdateStatus){
            filters.lastUpdateStatus = this.studyPlansFilterService?.applyFiltersQuickly?.lastUpdateStatus;
            this.studyPlansFilterService.applyFiltersQuickly = undefined;
        }
        console.log(filters, 'filters')

       return this.studentiCicloService.getPianiDiStudiStudentiForm(
            !!filters?.nominative ? filters?.nominative : undefined,
            undefined,
            !!filters?.approvedStatus ? filters?.approvedStatus : undefined,
            !isNil(filters?.approvedValidity) ? !filters?.approvedValidity : undefined,
            !!filters?.lastUpdateStatus ? filters?.lastUpdateStatus : undefined,
            undefined,
            undefined,
            page, size, ).pipe(
            tap((res) => {
                this.tableConfiguration = buildStudyPlansConfiguration(res, this.pageSize, page);
                this.studyPlansFilterService.filterApplied = filters;
                this.studyPlansFilterService.pageSize = size;
                this.studyPlansFilterService.page = page;
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                if (isFirstTime) {
                    this.isFirstTime = false;
                    this.loading = false;
                } else {
                    this.fuseConfirmationService.hideLoader();
                }
            })
        );
    }

    tableClickAction($event: ClickEvent): void {
        switch ($event.tipoClick) {
            case TipoClickEnum.SHOW:
                this.openPlanInNewTab(($event.value as StudenteCicloPianoDiStudiViewImpl)?.studenteCicloPianoDiStudiView?.idUtente);
                break;
        }
    }

    openPlanInNewTab(id: string): void {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([PathEnum.STUDENTS, id, PathEnum.STUDY_PLAN])
        );
        window.open(url, '_blank');
    }


    pageAction($event: PageEvent) {
        this.studyPlansFilterService.page = $event.pageIndex;
        this.studyPlansFilterService.pageSize = $event.pageSize;
        this.getData(false, undefined, $event.pageIndex, $event.pageSize);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.studyPlansFilterService.filterApplied)) {
            const entries: [string, any][] = Object.entries(this.studyPlansFilterService.filterApplied);
            const filteredEntries = entries?.filter(item =>
                typeof item === 'boolean' || item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {

                            return get(translation, this.fieldsLabelMap.get(element), element);
                        } else {
                            return element;
                        }
                    } else if (typeof element === 'boolean') {
                        return get(translation, element ? 'common.yes' : 'common.no');
                    } else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    } else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    } else if (!!element && isArray(element) && Object.values(PianoDiStudiStatus)?.find(e => element.includes(e))) {
                            return element.map(e => get(translation, getLanguagePathByPianoDiStudiStatus(e), e))?.join(', ');
                    } else if (!!element && isArray(element) && Object.values(AggiornamentoPianoDiStudiStatus)?.find(e => element.includes(e))) {
                        return element.map(e => get(translation, getLanguagePathByAggiornamentoPianoDiStudiStatus(e), e))?.join(', ');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Labels chips', labels);
            labels = labels.map(label => label?.endsWith(': ') ? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }

    getDataForFilters(): void {
        this.toogleFilterContainer$.emit({hasToogleInternalMenu: true});
    }

    reset(): void {
        this.studyPlansFilterService.reset();
    }

    openExportPdfDialog() {
        const appliedFiltersFormValues = this.studyPlansFilterService.filterApplied
        const appliedFiltersNumber = Object.values(appliedFiltersFormValues).filter(f => !!f).length;
        if (appliedFiltersNumber > 0) {
            const activeLang = this.translocoService.getActiveLang();
            const translation = this.translocoService.getTranslation().get(activeLang);
            this.fuseConfirmationService.open({
                    title: get(translation, 'requests.export_pdf_study_plans', null),
                    message: get(translation, 'requests.export_dialog_message', null),
                    icon: {
                        name: 'mat_outline:info',
                        color: 'info'
                    },
                    onBackdrop: {
                        show: true,
                        backdrop: false
                    },
                    actions: [
                        {
                            color: 'accent',
                            label: get(translation, 'dialog.cancel', null),
                        },
                        {
                            color: 'primary',
                            label: get(translation, 'mobility.export_without_filters', null),
                            function: () => this.exportPdfRequest()
                        },
                        {
                            color: 'primary',
                            label: get(translation, 'mobility.export_with_filters', null),
                            function: () => this.exportPdfRequest(appliedFiltersFormValues)
                        }]
                }
            );
        } else {
            this.exportPdfRequest();
        }



    }

    private exportPdfRequest(filters?: any) {
        this.fuseConfirmationService.showLoader();
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const statiPeriodiDiMobilita = [];
        if (filters?.mobilitaDaApprovareSupervisore) {
            statiPeriodiDiMobilita.push(PeriodoDiMobilitaStudenteStatus.INSERITO);
        }
        if (filters?.mobilitaDaApprovareCoordinatore) {
            statiPeriodiDiMobilita.push(PeriodoDiMobilitaStudenteStatus.APPROVATOPARZIALE);
        }
        this.studentiCicloService.getStudentiForm(
            !!filters?.nominativo ? filters?.nominativo : undefined,
            !!filters?.codiceFiscale ? filters?.codiceFiscale : undefined,
            !!filters?.statoCarriera ? filters?.statoCarriera : undefined,
            !!filters?.statoCarrieraMotivazione ? filters?.statoCarrieraMotivazione : undefined,
            !!filters?.ateneoProvenienza ? filters?.ateneoProvenienza : undefined,
            !!filters?.nazionalita ? filters?.nazionalita : undefined,
            !!filters?.idCorsoInOffertaFormativa?.id ? filters?.idCorsoInOffertaFormativa?.id : undefined,
            undefined,
            this.getUltimoStatoAggiornamentoPianoByRole(filters?.statoUltimoAggiornamentoPianoDiStudi),
            true,
            this.getStatoSpesaDaApprovareByRole(filters?.statoSpesaStudente),
            undefined,
            !!filters?.codiceFiscaleSupervisore?.codiceFiscale ? filters.codiceFiscaleSupervisore?.codiceFiscale : undefined,
            !!filters?.ssdSupervisore ? filters.ssdSupervisore : undefined,
            !!filters?.afferenzaOrganizzativaSupervisore ? filters.afferenzaOrganizzativaSupervisore : undefined,
            !!filters?.codiceFiscaleCosupervisore?.codiceFiscale ? filters.codiceFiscaleCosupervisore?.codiceFiscale : undefined,
            !!filters?.ssdCosupervisore ? filters.ssdCosupervisore : undefined,
            !!filters?.afferenzaOrganizzativaCosupervisore ? filters.afferenzaOrganizzativaCosupervisore : undefined,
            undefined,
            !!filters?.codiceFiscaleDocenteCorso?.codiceFiscale ? filters.codiceFiscaleDocenteCorso?.codiceFiscale : undefined,
            statiPeriodiDiMobilita,
            !!filters?.codiceTipoBorsaPerCiclo ? filters.codiceTipoBorsaPerCiclo : undefined,
            undefined,
        ).pipe(
            switchMap((value) => {
               return this.pianiDiStudiService.getPdfUltimiAggiornamentiPianiDiStudiByUtenteIds(
                    {
                        utenteIds: value.content.map(student => student?.idUtente)
                    }
                )
            }),
            take(1),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (pdf: Blob) => {
                if(pdf){
                    const fileTypeForName = 'PIANI_FORMATIVI';
                    const outputFileName = makeFilenameFromFE(this.localStorageService.getCicloCorsoRuolo(), '.pdf', fileTypeForName);
                    fs.saveAs(pdf, outputFileName);

                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'student.file_download_success', null),
                        type: SnackbarTypes.Success,
                    });
                } else {
                    this.fuseConfirmationService.openSnackBar({
                        message: get(translation, 'requests.no_study_plans', null),
                        type: SnackbarTypes.Warning,
                    });
                }
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err);
            }
        });
    }


    getUltimoStatoAggiornamentoPianoByRole(statoUltimoAggiornamentoPianoDiStudi: boolean): AggiornamentoPianoDiStudiStatus[] | undefined {
        if (statoUltimoAggiornamentoPianoDiStudi) {
            return [AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE];
        } else {
            return undefined;
        }
    }

    getStatoSpesaDaApprovareByRole(statoSpesaDaApprovare: boolean): SpesaStudenteStatus | undefined {
        if (statoSpesaDaApprovare) {
            if (this.currentRuolo === AuthorityType.COORDINATORE) {
                return SpesaStudenteStatus.APPROVATOPARZIALE;
            } else if (this.currentRuolo === AuthorityType.SUPERVISORE || this.currentRuolo === AuthorityType.COSUPERVISORE) {
                return SpesaStudenteStatus.DAAPPROVARE;
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    restore(): void {
        this.studyPlansFilterService.restore();
    }
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize, of, take, takeUntil} from 'rxjs';
import {MatTabGroup} from '@angular/material/tabs';
import {FormControl, FormGroup} from "@angular/forms";
import {
    AbstractDefaultComponent
} from "../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {filter, first, switchMap, tap} from "rxjs/operators";
import {
    AddDeliberaDTO,
    AuthorityType, CorsoDiStudiViewImpl, DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService, GetDeliberaDTO,
    PageDeliberaInfoViewImpl, UpdateDeliberaDTO
} from "../../../../api-clients/generated/services";
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum
} from "../../../shared/components/table/model/generic-table-model";
import {AppInitService} from "../../../shared/service/app-init.service";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {LogoutService} from "../../../shared/service/logout.service";
import {TranslocoService} from "@ngneat/transloco";
import {LocalStorageService} from "../../../shared/service/local-storage.service";
import {buildDelibereTableConfiguration, buildFormConfigForAddNewDelibera} from "./gestione-documenti-utils";
import {PageEvent} from "@angular/material/paginator";
import {CicloCorsoRuoloInterface} from "../../../shared/interface/CicloCorsoRuoloInterface";
import {makeFilename, openFileInBlankWindow} from "../../../shared/utils/utils";
import * as fs from "file-saver";
import {get} from "lodash";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {HttpEvent, HttpResponse} from "@angular/common/http";
import {GenericComponentDialogConfig} from "../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../layout/common/generic-components/generic-components-enum";
import {optionFile} from "../../../shared/costants/app-constants";
import {
    GenericDialogComponent
} from "../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import moment from "moment/moment";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TipoMobilitaDashboardTab} from "../mobility-dashboard/mobility-dashboard.component";
import {MatDrawer} from "@angular/material/sidenav";
import {
    DelibereSidebarFilterContainerComponent
} from "../mobility-dashboard/delibere-sidebar-filter-container/delibere-sidebar-filter-container.component";
import {FuseMediaWatcherService} from "../../../../@fuse/services/media-watcher";
import {PathEnum} from "../../../app.routing";

export const DEFAULT_PAGE_SIZE = 10;


@Component({
    selector: 'app-gestione-documenti',
    templateUrl: './gestione-documenti.component.html',
    styleUrls: ['./gestione-documenti.component.scss']
})
export class GestioneDocumentiComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    loading: boolean = false;
    title: string;
    subtitle: string;
    _tabsGroup: MatTabGroup;
    showSelect: boolean = false;
    sottoruoli: string[];
    ctrlSelectFormControl: FormControl = new FormControl(null);
    activityTabSelectedIndex = 0;
    private pageSize: number = DEFAULT_PAGE_SIZE;
    private page: number;
    isReadOnly: boolean;
    @ViewChild(MatTabGroup) set tabsGroup(tabsGroup: MatTabGroup)  {
        if(tabsGroup && !this._tabsGroup) {
            this._tabsGroup = tabsGroup;
            this.handleFragmentNavigation(tabsGroup);
        }
    }
    @ViewChild('drawer') drawer: MatDrawer;
    sidebarContainerComponent: DelibereSidebarFilterContainerComponent;

    @ViewChild('sidebarContainer', { static: false }) set sidebarContainer(c: DelibereSidebarFilterContainerComponent){
        if(!!c) {
            this.sidebarContainerComponent = c;
        }
    }
    allCourse = 'ALL'

    delibereListTableConfiguration: GenericTableConfigurationModel;
    currentRuolo: AuthorityType;
    currentSubroleCanAccess: boolean;
    isSubroleSelected: boolean;
    mustChooseSubrole: boolean;
    protected readonly AuthorityType = AuthorityType;
    ctrlCorso: FormControl = new FormControl(this.allCourse);
    corsi: Array<CorsoDiStudiViewImpl> | undefined;
    drawerOpened: boolean = false;
    currentSottoruolo: AuthorityType;

    constructor(
        private activatedRoute: ActivatedRoute,
        public appInitService: AppInitService,
        private fuseConfirmationService: FuseConfirmationService,
        private logoutService: LogoutService,
        private translocoService: TranslocoService,
        private localStorageService: LocalStorageService,
        private delibereService: DelibereService,
        private dialog: MatDialog,
        private router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService
    ) {
        super();
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({matchingAliases}) => {
                if (matchingAliases.includes('md')) {
                    this.drawerOpened = false;
                } else {
                    this.drawerOpened = false;
                }
            });
    }

    ngOnInit(): void {
        this.appInitService.cleanCorsoAmm();
        this.isReadOnly = this.appInitService.isAreaAdministrator;
        this.getSottoruoliRequest();
        this.subscribeToSottoruoliValueChange();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if(!this.appInitService.isAreaAdministrator) {
            this.appInitService.cleanCorsoAmm();
        }
    }

    isDocenteOrPta(ruolo?: AuthorityType): boolean {
        return ruolo === AuthorityType.DOCENTE || ruolo === AuthorityType.PERSONALEPTA;
    }

    setCurrentCorso(codiceCorsoStudiAmm: string): void {
        if(codiceCorsoStudiAmm !== this.allCourse){
            this.appInitService.setCodiceCorsoStudiAmm(codiceCorsoStudiAmm);
        }else {
            this.appInitService.setCodiceCorsoStudiAmm(undefined);
        }
        this.getDelibereRequest(false, 0);
    }
    getSottoruoliRequest(): void {
        this.loading = true;
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            tap(value => this.currentRuolo = value?.ruolo),
            switchMap((value) => {
                if (!this.appInitService.isAreaAdministrator && this.isDocenteOrPta(this.currentRuolo)) {
                    return this.appInitService.currentSottoruoliUtente$.pipe(first());
                } else {
                    return of(null);
                }
            }),
            tap((sottoruoli: AuthorityType[]) => {
                if (!!sottoruoli) {
                    this.sottoruoli = sottoruoli;
                    this.loading = false;
                    this.setCurrentSottoruolo();
                    this.mustChooseSubrole = true//this.sottoruoli?.length > 1;
                    /*if(this.ctrlSelectFormControl?.value === AuthorityType.COORDINATORE) {
                        // doing the request when the page load for the first time
                        //this.subscribeToFilterChanges(true);
                        this.getDelibereRequest(true);
                        this.currentSubroleCanAccess = true;
                    } else {
                        this.currentSubroleCanAccess = false;
                    }*/
                } else {
                    if(this.appInitService.isAreaAdministrator){
                        this.corsi = this.appInitService.getCorsiAmm();
                    }
                    this.mustChooseSubrole = false;
                    // if you see the page and there are no sottoruoli you can execute the request
                    // doing the request when the page load for the first time
                    //this.subscribeToFilterChanges(true);
                    this.getDelibereRequest(true);
                    this.currentSubroleCanAccess = true;
                }
            }),
            takeUntil(this.destroy$),
        ).subscribe({
                next: (value) => {
                },
                error: (err) => {
                    this.loading = false;
                    this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                        () => this.logoutService.goToHome(),
                        () => this.getSottoruoliRequest(),
                        'common.go_to_home',
                        err?.error?.message);
                }
            }
        );
    }

    private subscribeToSottoruoliValueChange() {
        this.ctrlSelectFormControl.valueChanges.subscribe(sottoruolo => {
            this.localStorageService.setSottoruoloCiclo(sottoruolo);
            this.appInitService.cicloCorsoRuoloSelected$.next(this.appInitService.cicloCorsoRuoloSelected);
            this.currentSottoruolo = sottoruolo;
            if(sottoruolo === AuthorityType.COORDINATORE
                || sottoruolo === AuthorityType.GRUPPOPTA
                || sottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO
                || sottoruolo === AuthorityType.DIRETTOREDIDIPARTIMENTO
                || sottoruolo === AuthorityType.GRUPPODICOORDINAMENTO) {
                //this.subscribeToFilterChanges();
                this.getDelibereRequest();
                this.currentSubroleCanAccess = true;
            } else {
                this.delibereListTableConfiguration = undefined;
                this.currentSubroleCanAccess = false;
            }
            this.isSubroleSelected = true;
        });
    }

    setCurrentSottoruolo(): void {
        const savedSottoruolo: AuthorityType = this.localStorageService?.dipartimentoRuoloCiclo?.sottoruolo;
        if (!!savedSottoruolo) {
            const findedSottoruolo = this.sottoruoli.includes(savedSottoruolo);
            if (findedSottoruolo) {
                this.ctrlSelectFormControl.setValue(savedSottoruolo, {emitEvent: false});
                this.isSubroleSelected = true;
                if(savedSottoruolo === AuthorityType.COORDINATORE
                    || savedSottoruolo === AuthorityType.GRUPPOPTA
                    || savedSottoruolo === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO
                    || savedSottoruolo === AuthorityType.DIRETTOREDIDIPARTIMENTO
                    || savedSottoruolo === AuthorityType.GRUPPODICOORDINAMENTO) {
                    //this.subscribeToFilterChanges();
                    this.getDelibereRequest(true);
                    this.currentSubroleCanAccess = true;
                }
            }
        }
        this.localStorageService.setSottoruoloCiclo(this.ctrlSelectFormControl?.value);
        this.appInitService.cicloCorsoRuoloSelected$.next(this.appInitService.cicloCorsoRuoloSelected);
        this.currentSottoruolo = this.ctrlSelectFormControl?.value;
    }

    handleFragmentNavigation(tabsGroup: MatTabGroup){
        this.activatedRoute.fragment.pipe(takeUntil(this.destroy$), first()).subscribe((fragment: string) => {
            console.log('typed fragment', fragment);
            const tabs = (tabsGroup?._tabs as any)._results;
            console.log(tabs);
            const matchIndex = tabs?.findIndex(x => x.ariaLabel === fragment);
            console.log('matched fragment with tab at position', matchIndex);
            if (matchIndex >= 0) {
                tabsGroup.selectedIndex = matchIndex;
            }
        });
    }





    private getDelibereRequest(isFirstTime?: boolean,
                               page: number = 0,
                               size: number = DEFAULT_PAGE_SIZE,
                               sort?: string) {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.searchDelibereForm(undefined, undefined, page, size)),
            takeUntil(this.destroy$),
            finalize(() => {
                if (isFirstTime) {
                    this.loading = false;
                } else {
                    this.fuseConfirmationService.hideLoader();
                }
            })
        ).subscribe({
            next: (pageDelibera: PageDeliberaInfoViewImpl) => {
                this.delibereListTableConfiguration = buildDelibereTableConfiguration(pageDelibera, this.pageSize, this.currentRuolo, this.ctrlSelectFormControl.value, this.appInitService.isAreaAdministrator);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => this.logoutService.goToHome(),
                    () => this.getDelibereRequest(isFirstTime, page, size, sort),
                    'common.go_to_home',
                    err?.error?.message);
                console.log(err)
            }
        });
    }

    tableClickAction($event: ClickEvent) {
        switch ($event?.tipoClick) {
            case TipoClickEnum.EDIT:
                this.openAddEditDeliberaDialog($event.value);
                break;
            case TipoClickEnum.DELETE:
                this.openDeleteDeliberaDialog($event.value);
                break;
            case TipoClickEnum.DOWNLOAD:
                this.downloadDeliberaRequest($event.value);
                break;
            case TipoClickEnum.DOWNLOAD_NOTA_TRASMISSIONE:
                this.downloadNotaTrasmRequest($event.value);
                break;
        }
    }

    pageAction($event: PageEvent) {
        this.pageSize = $event.pageSize;
        this.page = $event.pageIndex;
        this.getDelibereRequest(false, this.page, this.pageSize);
    }

    openAddEditDeliberaDialog(deliberaToEdit?: DeliberaInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: !!deliberaToEdit ? 'documents_dashboard.approval_edit' : 'documents_dashboard.add_approval',
            icon: {
                show: true,
                name: !!deliberaToEdit ? 'mat_outline:edit' : 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    icon: 'check',
                    function: (form, dialogRef) =>
                        !!deliberaToEdit ? this.updateDeliberaRequest(form, form.getRawValue(), dialogRef, deliberaToEdit) : this.addDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: buildFormConfigForAddNewDelibera(),
            valueForm: {
                fileDelibera: !!deliberaToEdit?.url ? makeFilename(deliberaToEdit?.url) : undefined,
                dataDelibera: !!deliberaToEdit?.data ? moment(deliberaToEdit?.data, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                numeroDelibera: deliberaToEdit?.numero,
                fileNotaTrasmissione: !!deliberaToEdit?.urlNotaDiTrasmissione ? makeFilename(deliberaToEdit?.urlNotaDiTrasmissione) : undefined,
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private openDeleteDeliberaDialog(delibera: DeliberaInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'documents_dashboard.approval_delete', null)
                + ' ' + delibera?.numero + ' - ' + delibera?.data,
            message: get(translation, 'documents_dashboard.approval_delete_message', null),
            icon: {
                name: 'mat_outline:delete',
                color: 'error'
            },
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
                {
                    color: 'primary',
                    label: get(translation, 'common.confirm', null), icon: 'check',
                    function: () => this.deleteDeliberaRequest(delibera?.id)
                }]
            }
        );
    }

    private downloadDeliberaRequest(delibera: DeliberaInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const getDeliberaBody: GetDeliberaDTO = {
            numeroDelibera: delibera?.numero,
            nomeFile: delibera?.url
        }
        this.fuseConfirmationService.showLoader();

        if (this.appInitService.isAreaAdministrator) {
            this.appInitService.setCodiceCorsoStudiAmm(delibera.codiceCorsoDiStudiEsse3)
        }

        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
            switchMap(() => this.delibereService.downloadDelibera(getDeliberaBody, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                if (this.appInitService.isAreaAdministrator) {
                    this.appInitService.cleanCorsoAmm();
                    this.appInitService.setCodiceCorsoStudiAmm(delibera.codiceCorsoDiStudiEsse3)
                }
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                //const fileNameToDownload = makeFilename(fileName);
                openFileInBlankWindow(fileResponse?.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }


    private downloadNotaTrasmRequest(delibera: DeliberaInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const getDeliberaBody: GetDeliberaDTO = {
            numeroDelibera: delibera?.numero,
            nomeFile: delibera?.urlNotaDiTrasmissione
        }
        this.fuseConfirmationService.showLoader();

        if (this.appInitService.isAreaAdministrator) {
            this.appInitService.setCodiceCorsoStudiAmm(delibera.codiceCorsoDiStudiEsse3)
        }

        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
            switchMap(() => this.delibereService.downloadDelibera(getDeliberaBody, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                if (this.appInitService.isAreaAdministrator) {
                    this.appInitService.cleanCorsoAmm();
                    this.appInitService.setCodiceCorsoStudiAmm(delibera.codiceCorsoDiStudiEsse3)
                }
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                //const fileNameToDownload = makeFilename(fileName);
                openFileInBlankWindow(fileResponse?.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }


    private deleteDeliberaRequest(id: string) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            tap((info: CicloCorsoRuoloInterface) => this.currentRuolo = info.ruolo),
            switchMap(() => this.delibereService.deleteDelibera(id)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: () => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'common.operation_success', null),
                    type: SnackbarTypes.Success,
                });
                this.getDelibereRequest(false, this.page, this.pageSize);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.deleteDeliberaRequest(id), 'dialog.cancel',
                    err?.error?.message);
                console.log(err)
            }
        });
    }

    private addDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera, rawValue.fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                dialogRef.close();
                this.getDelibereRequest(false, this.page, this.pageSize);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.addDeliberaRequest(form, rawValue, dialogRef), 'dialog.cancel',
                    err?.error?.message);
            }
        });
    }


    private updateDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>, delibera: DeliberaInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: UpdateDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera
        }
        const file = rawValue.fileDelibera === makeFilename(delibera?.url) ? undefined : rawValue.fileDelibera;
        const fileNotaTrasmissione = rawValue.fileDelibera === makeFilename(delibera?.urlNotaDiTrasmissione) ? undefined : rawValue.fileNotaTrasmissione;
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.updateDeliberaForm(delibera?.id, requestBody, file, fileNotaTrasmissione)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_updated', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                dialogRef.close();
                this.getDelibereRequest(false, this.page, this.pageSize);
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.updateDeliberaRequest(form, rawValue, dialogRef, delibera), 'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    protected readonly TipoMobilitaDashboardTab = TipoMobilitaDashboardTab;

    getSidebar(){
        return this.sidebarContainerComponent;
    }

    toggleFilterContainer(hasToogleInternalMenu: boolean) {
        this.getSidebar()?.setFilterApplied();
        if (hasToogleInternalMenu) {
            this.drawer.toggle();
        }
    }


    resetFilters() {
        this.getSidebar()?.resetFilterApplied();
    }

    closeDrawer() {
        this.drawer.close();
    }

    navigateToAssociazioneDelibere() {
        this.router.navigate([ PathEnum.OPERAZIONI_MASSIVE_CARICAMENTO_DELIBERE]);
    }
}




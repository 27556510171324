import {ConfigCol, numFmtEuro} from "../../../shared/service/excel.service";
import moment from "moment";
import {
    annoRiferimentoToRomanNumeral,
    getLanguagePathByAttivitaTrasversale, getLanguagePathByMissioneStatus, getLanguagePathByPeriodiRegistroStatus,
    getLanguagePathBySpesaStudenteStatus, getStatoAutorizzazione,
    removeUnderscore
} from "../../../shared/utils/utils";
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../shared/components/table/model/generic-table-model";
import {PathEnum} from "../../../app.routing";
import {Translation, TranslocoService} from "@ngneat/transloco";
import {get, isNil, sortBy} from "lodash";
import {DEFAULT_PAGE_SIZE} from "../audit/audit.component";
import {
    PianoDiStudiStatusExtended
} from "../cycle/training-offer/dialog-invalid-study-plans/dialog-invalid-study-plans.component";
import {computePlanStatusExtendedChipAccordingStatusAndValidity} from "../student-details/student-details.component";
import {formatDay} from "../mobility-dashboard/mobility-dashboard-utils";
import {
    StatoAutorizzazione
} from "./approvazione-periodi-di-mobilita-ricerca/tabs-periodi-mobilita-ricerca/tabs-periodi-mobilita-ricerca.component";
import {
    AggiornamentoPianoDiStudiStatus, AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO,
    AttivitaTrasversaleStatus, AuthorityType,
    CorsoPianoDiStudiPerExportViewImpl, DatiPercorsoDiStudi,
    MissioneAndStudenteInfoView,
    MissioneStatus,
    PageCorsoPianoDiStudiPerExportViewImpl,
    PageMissioneAndStudenteInfoView,
    PagePeriodoDiMobilitaStudenteInfoView,
    PagePeriodoRegistroAttivitaInfoView,
    PageSpesaStudentePerApprovMassivaInfoView, PageStudenteCicloPianoDiStudiViewImpl, PageStudenteCicloUtenteViewImpl,
    PeriodoDiMobilitaStudenteInfoView,
    PeriodoDiMobilitaStudenteStatus,
    PeriodoRegistroAttivitaInfoView, PeriodoRegistroAttivitaStatus,
    SpesaStudenteInfoViewImpl,
    SpesaStudentePerApprovMassivaInfoView,
    SpesaStudenteStatus,
    TipoPeriodoEnum
} from "../../../../api-clients/generated/services";

export const PERIODI_EXCEL_SHEET_CONFIG: ConfigCol[] = [
    {
        key: 'codiceMobilita',
        translocoHeader: 'mobility.mobility_code',
        width: 17
    },
    {
        key: 'tipoPeriodoEnum',
        translocoHeader: 'mobility.period_type',
        width: 20
    },
    {
        key: 'studenteNomeCompleto',
        translocoHeader: 'students_list.student',
        width: 25
    },
    {
        key: 'studenteMatricola',
        translocoHeader: 'mobility.enrollment_number',
        width: 15
    },
    {
        key: 'studenteEmail',
        translocoHeader: 'mobility.student_email',
        width: 30
    },
    {
        key: 'stato',
        translocoHeader: 'mobility.state',
        width: 15
    },
    {
        key: 'dataInizio',
        translocoHeader: 'mobility.start_date',
        width: 12,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'dataFine',
        translocoHeader: 'mobility.end_date',
        width: 12,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'strutturaEsteraOspitanteDescrizione',
        translocoHeader: 'mobility.foreign_university',
        width: 35
    },
    {
        key: 'tipologiaMobilitaDescrizione',
        translocoHeader: 'mobility.mobility_type',
        width: 20
    },
    {
        key: 'tipologiaBonusDescrizione',
        translocoHeader: 'mobility.bonus_tipology',
        width: 20
    },
    {
        key: 'tipologiaInterventoSupporto',
        translocoHeader: 'mobility.support_tipology',
        width: 20
    },
    {
        key: 'sedeCotutela',
        translocoHeader: 'mobility.cotutela',
        width: 20
    },
    {
        key: 'sedeDoctorEuropeaus',
        translocoHeader: 'mobility.doctor_europeaus',
        width: 20
    },
    {
        key: 'importo',
        translocoHeader: 'common.amount',
        width: 10,
        style: {
            numFmt: numFmtEuro
        }
    },
    {
        key: 'approvalNumber',
        translocoHeader: 'budget.approval_number',
        width: 20
    },
];

function buildLinkForDettaglioStudente(periodo: PeriodoDiMobilitaStudenteInfoView, basePath: string) {
    const fragment = periodo?.tipoPeriodoEnum === TipoPeriodoEnum.SOGGIORNOESTERO ? 'mobilita' : 'ricerca';
    return basePath + '/' + PathEnum.STUDENTS + '/' + periodo?.studenteCiclo?.utente?.id + '/' + PathEnum.MOBILITA + '#' + fragment;
}

export function mapPeriodiForExcel(periodi: PeriodoDiMobilitaStudenteInfoView[], currentBaseURL: string, translation: Translation): any[] {

    return periodi.map(periodo => ({
        ...periodo,
        strutturaEsteraOspitanteDescrizione: (periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione)
            + ' (' + periodo?.nazioneStrutturaEstera?.codice + ')',
        tipologiaMobilitaDescrizione: periodo?.tipologiaMobilita?.descrizione,
        tipologiaBonusDescrizione: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
        dataInizio: !!periodo?.dataInizio ? moment(periodo?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        dataFine: !!periodo?.dataFine ? moment(periodo?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        studenteNomeCompleto: periodo?.studenteCiclo?.utente?.cognome + ' ' + periodo?.studenteCiclo?.utente?.nome,
        studenteMatricola: periodo?.studenteCiclo?.matricola,
        studenteEmail: periodo?.studenteCiclo?.emailAteneo?.toLowerCase(),
        tipologiaInterventoSupporto: periodo?.tipoInterventoSupporto?.descrizione,
        stato: removeUnderscore(periodo?.stato),
        tipoPeriodoEnum: removeUnderscore(periodo?.tipoPeriodoEnum),
        dettaglioStudenteLink:  { text: get(translation, 'mobility.go_to_detail', null), hyperlink: buildLinkForDettaglioStudente(periodo, currentBaseURL) },
    }))
}

export function buildMobilityRequestsTableConfiguration(pagePeriodi: PagePeriodoDiMobilitaStudenteInfoView,
                                                        pageSize: number = DEFAULT_PAGE_SIZE,
                                                        page?: number,
                                                        isRicerca?: boolean): GenericTableConfigurationModel {
    const periodiDiMobilitaStudenteForUI = pagePeriodi?.content?.map((periodo: PeriodoDiMobilitaStudenteInfoView) =>
        ({
            ...periodo,
            strutturaEsteraOspitanteDescrizione: (periodo?.strutturaEsteraOspitante?.descrizione || periodo?.strutturaEsteraNonCodificata?.descrizione)
                + ' (' + periodo?.nazioneStrutturaEstera?.codice + ')',
            tipologiaMobilitaDescrizione: periodo?.tipologiaMobilita?.descrizione,
            tipologiaBonusDescrizione: periodo?.tipologiaBonus?.descrizioneTipologiaBonus,
            dataRichiestaFormatted: moment(periodo?.dataRichiesta).format('DD/MM/YYYY HH:mm'),
            dataInizio: !!periodo?.dataInizio ? moment(periodo?.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
            dataFine: !!periodo?.dataFine ? moment(periodo?.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
            studenteUIMapped: [{
                ...periodo?.studenteCiclo,
                nome: periodo?.studenteCiclo?.utente?.nome,
                cognome: periodo?.studenteCiclo?.utente?.cognome,
                codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale
            }]
        }));
    return {
        configuration: {
            pageBE: page,
            data: periodiDiMobilitaStudenteForUI,
            totalElements: pagePeriodi?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.mobility_code',
                    colonnaKey: 'codiceMobilita',
                    flex: 11,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.period_type',
                    colonnaKey: 'tipoPeriodoEnum',
                    flex: 11,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'mobility.state',
                    colonnaKey: 'stato',
                    flex: 10,
                    statusType: PeriodoDiMobilitaStudenteStatus,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.start_date',
                    colonnaKey: 'dataInizio',
                    flex: 9,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.end_date',
                    colonnaKey: 'dataFine',
                    flex: 9,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.foreign_university',
                    colonnaKey: 'strutturaEsteraOspitanteDescrizione',
                    flex: 18,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'person',
                            click: TipoClickEnum.GO_TO_MOBILITA_STUDENTE,
                            tooltip: 'mobility.go_to_detail_only',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: isRicerca ? 'custom_table.no_data_research' :  'custom_table.no_data_mobility',
        }
    };
}



export function buildConfigurationForSpeseRequest(spese: PageSpesaStudentePerApprovMassivaInfoView,
                                                  pageSize = DEFAULT_PAGE_SIZE,
                                                  pageBE: number): GenericTableConfigurationModel {
    return {
        configuration: {
            data: spese.content.map(value => ({
                ...value,
                studenteUIMapped: [{
                    utenteNome:  value?.budgetStudente?.studenteCiclo?.utente?.nome,
                    utenteCognome:  value?.budgetStudente?.studenteCiclo?.utente?.cognome,
                    utenteMail: value?.budgetStudente?.studenteCiclo?.utente?.mail,
                    nome: value?.budgetStudente?.studenteCiclo?.utente?.nome,
                    cognome: value?.budgetStudente?.studenteCiclo?.utente?.cognome,
                    studenteCodiceFiscale: value?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                    codiceFiscale: value?.budgetStudente?.studenteCiclo?.utente?.codiceFiscale,
                    studenteMatricola: value?.budgetStudente?.studenteCiclo?.matricola,
                    urlImmagineProfilo: value?.budgetStudente?.studenteCiclo?.utente?.urlImmagineProfilo,
                    utenteTelefono: value?.budgetStudente?.studenteCiclo?.utente?.telefono
                }]
            })),
            pageBE,
            totalElements: spese?.totalElements,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.description',
                    colonnaKey: 'descrizione',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.codice_spesa',
                    colonnaKey: 'codiceSpesa',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.type',
                    colonnaKey: 'tipologia',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.appr_amount',
                    colonnaKey: 'importoApprovato',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.AMOUNT,
                    nomeColonna: 'budget.req_amount',
                    colonnaKey: 'importoRichiesto',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'budget.state',
                    colonnaKey: 'stato',
                    flex: 20,
                    statusType: SpesaStudenteStatus,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'account_balance_wallet',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'common.go_to_budget',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW_NOTES,
                            tooltip: 'budget.show_notes',
                            color: 'accent',
                            show: (nota?: string) => (!!nota && nota !== ''),
                            valueShow: 'nota'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_REFUSE_REASON,
                            tooltip: 'mobility.show_refuse_reason',
                            color: 'accent',
                            show: (motivazioniRifiuto, element: SpesaStudentePerApprovMassivaInfoView) => !!motivazioniRifiuto && element.stato === SpesaStudenteStatus.RIFIUTATO,
                            valueShow: 'motivazioneRifiuto'
                        },
                        {
                            nameIconButton: 'download',
                            click: TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST,
                            tooltip: 'budget.show_attachment_file',
                            color: 'accent',
                            show: (urlAllegatiRichiesta, spesa: SpesaStudenteInfoViewImpl) => spesa.urlAllegatiRichiesta?.length > 0,
                            valueShow: 'urlAllegatiRichiesta'
                        },
                    ]
                }
            ],
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_budget'
        }
    };
}



export function buildIdForTable(attivita: CorsoPianoDiStudiPerExportViewImpl) {
    if(attivita.idAttivitaProposta){
        return 'PROP_ ' + attivita?.idAttivitaProposta;
    } else if(attivita.idAttivitaPresunta){
        return 'PRES_ ' + attivita?.idAttivitaPresunta;
    } else {
        return attivita?.idAttivitaTrasversale;
    }
}

export function buildAttivitaFormativeConfiguration(pageAttivita: PageCorsoPianoDiStudiPerExportViewImpl, pageSize: number, page: number,
                                                    isInApprovalMode = false): GenericTableConfigurationModel {
    return {
        configuration: {
            data: pageAttivita?.content
                ?.map(attivita => ({
                    ...attivita,
                    annoInsegnamentoFormatted: annoRiferimentoToRomanNumeral(attivita.annoInsegnamento),
                    studenteUIMapped: [{
                        utenteNome: attivita?.utenteNome,
                        utenteCognome: attivita?.utenteCognome,
                        utenteMail: attivita?.utenteMail,
                        nome: attivita?.utenteNome,
                        cognome: attivita?.utenteCognome,
                        studenteCodiceFiscale: attivita?.studenteCodiceFiscale,
                        codiceFiscale: attivita?.studenteCodiceFiscale,
                        studenteMatricola: attivita?.studenteMatricola,
                        urlImmagineProfilo: attivita?.urlImmagineProfilo,
                        utenteTelefono: attivita?.utenteTelefono
                    }],
                    categoryFormatted: attivita.denominazioneCategoria,
                    idAttivitaTrasversaleForTable: buildIdForTable(attivita),
                    dettaglioAttivitaTrasversaleForShow: attivita.dettaglioAttivitaTrasversale ?? attivita.denominazione
                })),
            sticky: true,
            pageSize: pageSize,
            pageBE: page,
            pageSizeOptions: [10, 20, 30, 40, 50],
            totalElements: pageAttivita.totalElements,
            isPaginatedBE: true,
            messaggioDatiAssenti: 'custom_table.no_data_activities',
            hidePaginator: false,
            selection: isInApprovalMode ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'dettaglioAttivitaTrasversaleForShow',
                selectedColumnKeyPrimaryKey: 'id',
            } : undefined,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.detail',
                    colonnaKey: 'dettaglioAttivitaTrasversale',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_typology',
                    colonnaKey: 'denominazione',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'support.category',
                    colonnaKey: 'categoryFormatted',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'budget.year',
                    colonnaKey: 'annoInsegnamentoFormatted',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'requests.description_CFU',
                    colonnaKey: 'descrizioneCfu',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'cycle_doctorate.number_of_credits_required',
                    colonnaKey: 'cfuPrevisti',
                    flex: 6,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'budget.state',
                    colonnaKey: 'statoAttivitaTrasversale',
                    flex: 20,
                    statusType: AttivitaTrasversaleStatus,
                    hideColonna: isInApprovalMode,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    hideColonna: isInApprovalMode,
                    button: [
                        {
                            nameIconButton: 'library_books',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'common.go_to_study_plan',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.description',
                            color: 'accent'
                        },
                    ]
                }
            ],
        }
    };
}


export function buildConfigurationForRegistriAttivitaRequest(pagePeriodi: PagePeriodoRegistroAttivitaInfoView,
                                                             pageSize: number,
                                                             page: number,
                                                             isInApprovalMode = false): GenericTableConfigurationModel {
    return {
        configuration: {
            data: pagePeriodi?.content
                ?.map(periodo => ({
                    ...periodo,
                    studenteUIMapped: [{
                        utenteNome: periodo?.studenteCiclo?.utente?.nome,
                        utenteCognome: periodo?.studenteCiclo?.utente?.cognome,
                        nome: periodo?.studenteCiclo?.utente?.nome,
                        cognome: periodo?.studenteCiclo?.utente?.cognome,
                        utenteMail: periodo?.studenteCiclo?.utente?.mail?.toLowerCase(),
                        studenteCodiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale,
                        codiceFiscale: periodo?.studenteCiclo?.utente?.codiceFiscale,
                        studenteMatricola: periodo?.studenteCiclo?.matricola,
                        urlImmagineProfilo: periodo?.studenteCiclo?.utente?.urlImmagineProfilo,
                        utenteTelefono: periodo?.studenteCiclo?.utente?.telefono
                    }],
                    dataInizioFormatted: periodo.dataInizio ? moment(periodo.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                    dataFineFormatted: periodo.dataFine ? moment(periodo.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                    dataRichiestaFormatted: periodo.dataRichiesta ? moment(periodo.dataRichiesta).format('DD/MM/YYYY  HH:mm') : undefined,
                    dataInizioAndFineFormatted: (periodo.dataInizio ? moment(periodo.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined) + ' - '
                        + (periodo.dataFine ? moment(periodo.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined)
                })),
            sticky: true,
            pageSize: pageSize,
            pageBE: page,
            pageSizeOptions: [10, 20, 30, 40, 50],
            totalElements: pagePeriodi.totalElements,
            isPaginatedBE: true,
            messaggioDatiAssenti: 'cycle_doctorate.no_period_outside',
            hidePaginator: false,
            selection: isInApprovalMode ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'dataInizioAndFineFormatted',
                selectedColumnKeyPrimaryKey: 'id',
            } : undefined,
            disabledCssClass: 'generic-table-disabled-row',
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'mobility.request_date',
                    colonnaKey: 'dataRichiestaFormatted',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.start_date',
                    colonnaKey: 'dataInizioFormatted',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.end_date',
                    colonnaKey: 'dataFineFormatted',
                    flex: 20,
                },

                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'budget.state',
                    colonnaKey: 'stato',
                    flex: 20,
                    statusType: PeriodoRegistroAttivitaStatus,
                    hideColonna: isInApprovalMode,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'assignment',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'common.go_to_activity_log',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_REFUSE_REASON,
                            tooltip: 'mobility.show_refuse_reason',
                            color: 'accent',
                            show: (motivazioniRifiuto, element: PeriodoRegistroAttivitaInfoView) => !!motivazioniRifiuto && element.stato === PeriodoRegistroAttivitaStatus.RIFIUTATO,
                            valueShow: 'motivazioneRifiuto'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_ACTIVITY_LOG_STUDENT,
                            tooltip: 'activity_log.show_activity_log_student',
                            show: (value, element) => element.urlExportPdf,
                            valueShow: 'stato',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_ACTIVITY_LOG_SUPERVISOR,
                            tooltip: 'activity_log.show_activity_log_supervisor',
                            show: (value, element) => element.urlDocPrimoApprovatore,
                            valueShow: 'stato',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_ACTIVITY_LOG_STUDENT_FILE,
                            tooltip: 'activity_log.show_activity_log_student_file',
                            show: (value, element) => element.urlDocRichiedente,
                            valueShow: 'stato',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_ACTIVITY_LOG_COORDINATOR,
                            tooltip: 'activity_log.show_activity_log_coordinator',
                            show: (value, element) => element.urlDocSecondoApprovatore,
                            valueShow: 'stato',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'close',
                            click: TipoClickEnum.REFUSE_ACTIVITY_LOG_COORDINATOR,
                            tooltip: 'activity_log.refuse_activity_log_coordinator',
                            show: (value, element) => isInApprovalMode && element.stato === PeriodoRegistroAttivitaStatus.APPROVATOPARZIALE,
                            valueShow: 'stato',
                            color: 'accent'
                        },
                    ]
                }
            ],
        }
    };
}




export const REGISTRO_EXCEL_SHEET_CONFIG: ConfigCol[] = [
    {
        key: 'dataRichiestaFormatted',
        translocoHeader: 'mobility.request_date',
        width: 25
    },
    {
        key: 'dataInizioFormatted',
        translocoHeader: 'common.start_date',
        width: 17
    },
    {
        key: 'dataFineFormatted',
        translocoHeader: 'common.end_date',
        width: 20,
    },
    {
        key: 'nominativo',
        translocoHeader: 'mobility.student',
        width: 30
    },
    {
        key: 'statoLabel',
        translocoHeader: 'budget.state',
        width: 30,
    }

]

export const MISSIONE_EXCEL_SHEET_CONFIG: ConfigCol[] = [
    {
        key: 'dataInizioFormatted',
        translocoHeader: 'common.start_date',
        width: 25
    },
    {
        key: 'dataFineFormatted',
        translocoHeader: 'common.end_date',
        width: 17
    },
    {
        key: 'descrizione',
        translocoHeader: 'common.description',
        width: 17
    },
    {
        key: 'annoRiferimentoFormatted',
        translocoHeader: 'common.year',
        width: 20,
    },
    {
        key: 'upb',
        translocoHeader: 'missions.upb',
        width: 20,
    },
    {
        key: 'nominativo',
        translocoHeader: 'mobility.student',
        width: 30
    },
    {
        key: 'statoLabel',
        translocoHeader: 'budget.state',
        width: 30,
    }

]


export const SPESE_EXCEL_SHEET_CONFIG: ConfigCol[] = [
    {
        key: 'codiceSpesa',
        translocoHeader: 'budget.codice_spesa',
        width: 25
    },
    {
        key: 'descrizione',
        translocoHeader: 'budget.description',
        width: 17
    },
    {
        key: 'importoRichiesto',
        translocoHeader: 'budget.req_amount',
        width: 20,
        style: {
            numFmt: numFmtEuro
        }
    },
    {
        key: 'tipologia',
        translocoHeader: 'common.type',
        width: 30
    },
    {
        key: 'stato',
        translocoHeader: 'requests.state',
        width: 30,
    },
    {
        key: 'dataRichiesta',
        translocoHeader: 'mobility.request_date',
        width: 15,
        style: { numFmt: 'DD/MM/YYYY' }
    },
    {
        key: 'studenteNomeCompleto',
        translocoHeader: 'common.nominative',
        width: 30,
    },

    {
        key: 'annoRiferimento',
        translocoHeader: 'common.year',
        width: 15
    },
    {
        key: 'utenteMail',
        translocoHeader: 'common.email',
        width: 20
    },
    {
        key: 'studenteCodiceFiscale',
        translocoHeader: 'common.fiscal_code',
        width: 20
    },
    {
        key: 'studenteMatricola',
        translocoHeader: 'common.enrollment_number',
        width: 20
    },
    {
        key: 'nota',
        translocoHeader: 'budget.nota',
        width: 30,
    },

]

export function mapSpeseForExcel(spese: SpesaStudentePerApprovMassivaInfoView[], translation: Translation): any[] {
    return spese.map(spesa => ({
        ...spesa,
        dataRichiesta: !!spesa?.dataRichiesta ? moment(spesa?.dataRichiesta, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        studenteNomeCompleto: spesa?.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + spesa?.budgetStudente?.studenteCiclo?.utente?.nome,
        tipologia: removeUnderscore(spesa?.tipologia),
        annoRiferimento: removeUnderscore(spesa?.annoRiferimento),
        stato: get(translation, getLanguagePathBySpesaStudenteStatus(spesa.stato)) as SpesaStudenteStatus,
    }))
}

export function mapRegistroAttivita(registro: PeriodoRegistroAttivitaInfoView[], translation: Translation): any[] {
    return registro.map(value => ({
        ...value,
        statoLabel: get(translation, getLanguagePathByPeriodiRegistroStatus(value.stato)),
        nominativo:  ((value?.studenteCiclo?.utente?.cognome || '') + ' ' +  (value?.studenteCiclo?.utente?.nome || '')).trim(),
        dataInizioFormatted: value.dataInizio ? moment(value.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        dataFineFormatted: value.dataFine ? moment(value.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        dataRichiestaFormatted: value.dataRichiesta ? moment(value.dataRichiesta).format('DD/MM/YYYY  HH:mm') : undefined,
        dataInizioAndFineFormatted: (value.dataInizio ? moment(value.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined) + ' - '
            + (value.dataFine ? moment(value.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined)
    }))
}

export function mapMissioni(missioni: MissioneAndStudenteInfoView[], translation: Translation): any[] {
    return missioni.map(missione => ({
        ...missione,
        statoLabel: get(translation, getLanguagePathByMissioneStatus(missione.stato)),
        nominativo:  ((missione?.studenteCiclo?.utente?.cognome || '') + ' ' +  (missione?.studenteCiclo?.utente?.nome || '')).trim(),
        dataInizioFormatted: missione.dataInizio ? moment(missione.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        dataFineFormatted: missione.dataFine ? moment(missione.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
        dataInizioAndFineFormatted: (missione.dataInizio ? moment(missione.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined) + ' - '
            + (missione.dataFine ? moment(missione.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined),
        annoRiferimentoFormatted: annoRiferimentoToRomanNumeral(missione?.annoRiferimento)
    }))
}

export function mapAttivitaForExcel(attivita: CorsoPianoDiStudiPerExportViewImpl[], translation: Translation): any[] {
    const mappedAttivita = attivita.map(value => ({
        ...value,
        studenteNomeCompleto: value?.utenteCognome + ' ' + value.utenteNome,
        statoAttivitaTrasversale: get(translation, getLanguagePathByAttivitaTrasversale(value.statoAttivitaTrasversale)) as SpesaStudenteStatus,
        presenzaDocumentoDottorando:  get(translation, value.presenzaDocumentoDottorando ? 'common.yes' : 'common.no'),
        presenzaDocumentoSupervisore:  get(translation, value.presenzaDocumentoSupervisore ? 'common.yes' : 'common.no'),
        presenzaDeliberaCoordinatore:  get(translation, value.presenzaDeliberaCoordinatore ? 'common.yes' : 'common.no'),
        categoryDenomination: value.denominazioneCategoria,
    }))
    return sortBy(mappedAttivita, [
        a => a.categoryDenomination?.toLowerCase(),
        a => a.denominazione?.toLowerCase()
    ], ['asc', 'asc']);
}


export const ATTIVITA_EXCEL_SHEET_CONFIG: ConfigCol[] = [
    {
        key: 'categoryDenomination',
        translocoHeader: 'support.category',
        width: 40
    },
    {
        key: 'denominazione',
        translocoHeader: 'requests.denomination',
        width: 25
    },
    {
        key: 'descrizione',
        translocoHeader: 'requests.description',
        width: 17
    },
    {
        key: 'annoInsegnamento',
        translocoHeader: 'common.year',
        width: 17
    },
    /*    {
            key: 'tipo',
            translocoHeader: 'common.type',
            width: 20,
        },*/
    {
        key: 'dettaglioAttivitaTrasversale',
        translocoHeader: 'common.detail',
        width: 30
    },
    {
        key: 'statoAttivitaTrasversale',
        translocoHeader: 'requests.state',
        width: 30,
    },
    {
        key: 'descrizioneCfu',
        translocoHeader: 'requests.state',
        width: 30,
    },
    {
        key: 'presenzaDocumentoDottorando',
        translocoHeader: 'cycle_doctorate.student_document_required',
        width: 30,
    },
    {
        key: 'tipoDocumentoDottorando',
        translocoHeader: 'cycle_doctorate.student_document',
        width: 30,
    },
    {
        key: 'presenzaDocumentoSupervisore',
        translocoHeader: 'cycle_doctorate.supervisor_document_required',
        width: 30,
    },

    {
        key: 'presenzaDeliberaCoordinatore',
        translocoHeader: 'cycle_doctorate.coordinator_required',
        width: 30,
    },
    {
        key: 'studenteNomeCompleto',
        translocoHeader: 'common.nominative',
        width: 30,
    },
    {
        key: 'utenteMail',
        translocoHeader: 'common.email',
        width: 20
    },
    {
        key: 'studenteCodiceFiscale',
        translocoHeader: 'common.fiscal_code',
        width: 20
    },
    {
        key: 'studenteMatricola',
        translocoHeader: 'common.enrollment_number',
        width: 20
    },
]

export const buildStudyPlansConfiguration = (pageStudente: PageStudenteCicloPianoDiStudiViewImpl,
                                             pageSize: number = DEFAULT_PAGE_SIZE,
                                             pageBE: number,
                                             isInApprovalMode = false): GenericTableConfigurationModel  =>{
    return {
        configuration: {
            data: pageStudente?.content?.map(studenteContent => ({
                ...studenteContent,
                studente: studenteContent.studenteCicloPianoDiStudiView?.cognome + ' ' + studenteContent.studenteCicloPianoDiStudiView?.nome,
                emailAteneo: studenteContent.studenteCicloPianoDiStudiView?.emailAteneo?.toLowerCase(),
                matricola: studenteContent.studenteCicloPianoDiStudiView?.matricola,
                approvedPlanStatusAndValidity: computePlanStatusExtendedChipAccordingStatusAndValidity({
                    status: studenteContent.studenteCicloPianoDiStudiView?.pianoDiStudiStatus,
                    validity: !studenteContent.studenteCicloPianoDiStudiView?.isPianoNonValido
                }),
                idUltimoAggiornamentoPiano: studenteContent?.studenteCicloPianoDiStudiView?.idUltimoAggiornamentoPiano,
                lastUpdateStatus: studenteContent?.studenteCicloPianoDiStudiView?.statoUltimoAggiornamentoPiano
            })),
            pageBE,
            sticky: true,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            totalElements: pageStudente.totalElements,
            isPaginatedBE: true,
            messaggioDatiAssenti: 'custom_table.no_data_study_plan_dashboard',
            hidePaginator: false,
            selection: isInApprovalMode ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'studente',
                selectedColumnKeyPrimaryKey: 'idUltimoAggiornamentoPiano',
            } : undefined,
            disabledCssClass: 'generic-table-disabled-row',
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.student',
                    colonnaKey: 'studente',
                    flex: isInApprovalMode ? 30 : 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'menu_filter.enrollment_number',
                    colonnaKey: 'matricola',
                    flex: isInApprovalMode ? 20 : 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.university_email',
                    colonnaKey: 'emailAteneo',
                    flex: isInApprovalMode ? 40 : 30,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'approvedPlanStatusAndValidity',
                    flex: 15,
                    statusType: PianoDiStudiStatusExtended,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore',
                    tooltip: (value) => value === PianoDiStudiStatusExtended.APPROVATONONVALIDO ? 'study_plan_status.approved_not_valid' : undefined,
                    showIconInChipStatus: (value) => value === PianoDiStudiStatusExtended.APPROVATONONVALIDO ? {
                        icon: 'warning_amber',
                        classes: 'text-amber-500'
                    } : undefined,
                    hideColonna: isInApprovalMode,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'study_plan_status.last_update_status',
                    colonnaKey: 'lastUpdateStatus',
                    flex: 15,
                    statusType: AggiornamentoPianoDiStudiStatus,
                    hideColonna: isInApprovalMode,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 7,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'library_books',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.go_to_study_plan',
                            color: 'accent',
                        },
                        {
                            nameIconButton: 'close',
                            click: TipoClickEnum.REFUSE_COORDINATOR,
                            tooltip: 'student.not_approves_your_study_plan',
                            valueShow: 'stato',
                            show: (value, element) => isInApprovalMode,
                            color: 'accent'
                        },
                    ]
                }
            ],
        }
    };
}

export function createTableConfigurationForCourses(attivitaOfferta: Array<AttivitaOffertaFormativaOfCurrentDocenteCorsoCountDTO>,
                                                   approvedMode: boolean = false): GenericTableConfigurationModel {
    const listUnsorted = attivitaOfferta?.map(item => {
        const numDottorandiInAttesa =  item.totDottorandi - item.numDottorandiSuperato;
        return ({
            ...item.attivitaOffertaFormativa,
            curriculumRiferimento: createPercorsoDiStudiListString(item.attivitaOffertaFormativa?.percorsiDiStudi),
            docentiTutti: [...(item.attivitaOffertaFormativa.docenti || []), ...(item.attivitaOffertaFormativa.docentiExtraOffertaFormativa || [])],
            numDottorandiSuperato:   item.numDottorandiSuperato !== 1 ?  item.numDottorandiSuperato  + ' Dottorandi' :  item.numDottorandiSuperato + ' Dottorando',
            totDottorandi: item.totDottorandi,
            numDottorandiInAttesa: numDottorandiInAttesa !== 1 ? numDottorandiInAttesa  + ' Dottorandi' : numDottorandiInAttesa + ' Dottorando',
        });
    });
    const list = sortBy(listUnsorted, [
        attivita => attivita.denominazione?.toLowerCase()
    ], ['asc']);
    return {
        configuration: {
            data: list,
            totalElements: list?.length,
            isPaginatedBE: false,
            sticky: true,
            singleChoiceSelection: true,
            selection: approvedMode ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'denominazione',
                selectedColumnKeyPrimaryKey: 'id',
            } : undefined,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.activity_denomination',
                    colonnaKey: 'denominazione',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'common.cfu',
                    colonnaKey: 'cfu',
                    flex: 5,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'total_hour_table.column_total_hour',
                    colonnaKey: 'ore',
                    flex: 3,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.disbursement_period',
                    colonnaKey: 'periodoErogazione',
                    flex: 10,
                },

                /*                    {
                                        tipo: TipoColonnaEnum.STRING,
                                        nomeColonna: 'cycle_doctorate.reference_curriculum',
                                        colonnaKey: 'curriculumRiferimento',
                                        flex: 22,
                                    },*/
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'cycle_doctorate.ssd',
                    colonnaKey: 'ssd',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'cycle_doctorate.final_check',
                    colonnaKey: 'verificaFinale',
                    flex: 4,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'cycle_doctorate.professor',
                    colonnaKey: 'docentiTutti',
                    flex: 20,
                    isChipDisabled: true
                },
                {
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'study_plan_status.archived_activity',
                    colonnaKey: 'isArchiviato',
                    flex: 8,
                    convertiValoreBoolean: element => !!element ? 'common.yes' : 'common.no'
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.performed',
                    colonnaKey: 'numDottorandiSuperato',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.not_performed',
                    colonnaKey: 'numDottorandiInAttesa',
                    flex: 10,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 8,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'common.description',
                            color: 'accent'
                        }
                    ]
                },
            ],
            hidePaginator: false,
            pageSize: list?.length,
            css: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'min-width': 'unset'
            },
            messaggioDatiAssenti: 'custom_table.no_data_activities'
        }
    };
}

export function buildAutorizzazioniTableConfiguration(pageStudente: PageStudenteCicloUtenteViewImpl,
                                                      translocoService: TranslocoService,
                                                      currentSottoruolo: AuthorityType,
                                                      pageSize: number = DEFAULT_PAGE_SIZE): GenericTableConfigurationModel {
    const activeLang = translocoService.getActiveLang();
    const translation = translocoService.getTranslation().get(activeLang);
    return {
        configuration: {
            data: pageStudente?.content?.map(studenteContent => ({
                ...studenteContent,
                studente: studenteContent.cognome + ' ' + studenteContent.nome,
                supervisore: (studenteContent?.supervisore?.cognome || studenteContent?.supervisore?.nome ) ?
                    ((studenteContent?.supervisore?.cognome || '') + ' ' + (studenteContent?.supervisore?.nome || '')).toUpperCase() : '',
                tipologiaBorsa: (studenteContent?.tipoBorsaPerCiclo?.descrizioneTipoBorsa),
                curriculum: (studenteContent?.percorsoDiStudi?.descrizionePercorsoDiStudi),
                dataIscrizione: formatDay(studenteContent?.dataIscrizione),
                tasseInRegola: isNil(studenteContent.tasseInRegola) ? '' : get(translation, studenteContent.tasseInRegola ? 'common.yes' : 'common.no'),
                autorizzazione: getStatoAutorizzazione(!!studenteContent.idDeliberaDiProgrammazioneMobilita, studenteContent.autorizzazioneMobilita)
            })),
            sticky: true,
            pageSize: pageSize,
            pageSizeOptions: [10, 20, 30, 40, 50],
            totalElements: pageStudente.totalElements,
            isPaginatedBE: true,
            messaggioDatiAssenti: 'custom_table.no_data_autorizzazioni',
            hidePaginator: false,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.student',
                    colonnaKey: 'studente',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'menu_filter.enrollment_number',
                    colonnaKey: 'matricola',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.date_of_registration',
                    colonnaKey: 'dataIscrizione',
                    flex: 8,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.university_email',
                    colonnaKey: 'emailAteneo',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.supervisor',
                    colonnaKey: 'supervisore',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'menu_filter.type_of_study_grant',
                    colonnaKey: 'tipologiaBorsa',
                    flex: 14,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'students_list.curriculum',
                    colonnaKey: 'curriculum',
                    flex: 15,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'common.status',
                    colonnaKey: 'autorizzazione',
                    flex: 20,
                    statusType: StatoAutorizzazione,
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 15,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'event',
                            click: TipoClickEnum.GO_TO_MOBILITA_STUDENTE,
                            tooltip: 'common.go_to_mobility',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'delete',
                            click: TipoClickEnum.REVOKE_MOBILITA_AUTORIZZAZIONE,
                            tooltip: 'mobility.revoke_autorization',
                            color: 'accent',
                            valueShow: 'numero',
                            show: (value, element) => element.autorizzazioneMobilita &&  currentSottoruolo === AuthorityType.COORDINATORE,
                        },
                        {
                            nameIconButton: 'description',
                            click: TipoClickEnum.SHOW_DELIBERA_MOBILITA_DETAIL,
                            tooltip: 'mobility.show_detail_delibera',
                            color: 'accent',
                            valueShow: 'idDeliberaDiProgrammazioneMobilita',
                            show: (value, element) => !!value,
                        },
                    ]
                }
            ]
        }
    };
}

export function createPercorsoDiStudiListString(percorsiDiStudi: Array<DatiPercorsoDiStudi> | undefined) {
    return percorsiDiStudi?.map(c => c.descrizionePercorsoDiStudi).join(', ').toUpperCase();
}

export function buildMissioniConfiguration(pageMissioni: PageMissioneAndStudenteInfoView,
                                           pageSize: number,
                                           page: number,
                                           isInApprovalMode = false): GenericTableConfigurationModel {
    return {
        configuration: {
            data: pageMissioni?.content
                ?.map(missione => ({
                    ...missione,
                    studenteUIMapped: [{
                        utenteNome: missione?.studenteCiclo?.utente?.nome,
                        utenteCognome: missione?.studenteCiclo?.utente?.cognome,
                        nome: missione?.studenteCiclo?.utente?.nome,
                        cognome: missione?.studenteCiclo?.utente?.cognome,
                        utenteMail: missione?.studenteCiclo?.utente?.mail?.toLowerCase(),
                        studenteCodiceFiscale: missione?.studenteCiclo?.utente?.codiceFiscale,
                        codiceFiscale: missione?.studenteCiclo?.utente?.codiceFiscale,
                        studenteMatricola: missione?.studenteCiclo?.matricola,
                        urlImmagineProfilo: missione?.studenteCiclo?.utente?.urlImmagineProfilo,
                        utenteTelefono: missione?.studenteCiclo?.utente?.telefono
                    }],
                    dataInizioFormatted: missione.dataInizio ? moment(missione.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                    dataFineFormatted: missione.dataFine ? moment(missione.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined,
                    dataInizioAndFineFormatted: missione.luogo + ',' + ((missione.dataInizio ? moment(missione.dataInizio, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined) + ' - '
                        + (missione.dataFine ? moment(missione.dataFine, 'YYYY-MM-DD').format('DD/MM/YYYY') : undefined)),
                    annoRiferimentoFormatted: annoRiferimentoToRomanNumeral(missione?.annoRiferimento)
                })),
            sticky: true,
            pageSize: pageSize,
            pageBE: page,
            pageSizeOptions: [10, 20, 30, 40, 50],
            totalElements: pageMissioni.totalElements,
            isPaginatedBE: true,
            messaggioDatiAssenti: 'cycle_doctorate.no_period_outside',
            hidePaginator: false,
            selection: isInApprovalMode ? {
                isSelectable: true,
                selectedColumnKeyToShow: 'dataInizioAndFineFormatted',
                selectedColumnKeyPrimaryKey: 'id',
            } : undefined,
            disabledCssClass: 'generic-table-disabled-row',
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.start_date',
                    colonnaKey: 'dataInizioFormatted',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.end_date',
                    colonnaKey: 'dataFineFormatted',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.description',
                    colonnaKey: 'descrizione',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'common.year',
                    colonnaKey: 'annoRiferimentoFormatted',
                    flex: 7,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'missions.upb',
                    colonnaKey: 'upb',
                    flex: 12,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'mobility.student',
                    colonnaKey: 'studenteUIMapped',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'budget.state',
                    colonnaKey: 'stato',
                    flex: 15,
                    statusType: MissioneStatus,
                    hideColonna: isInApprovalMode,
                    sottoruoloKey: 'sottoruoloPrimoApprovatore'
                },
                {
                    nomeColonna: ' ',
                    colonnaKey: 'action',
                    flex: 9,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    valueCheck: 'l',
                    button: [
                        {
                            nameIconButton: 'work_outline',
                            click: TipoClickEnum.GO_TO,
                            tooltip: 'common.go_to_mission',
                            color: 'accent',
                            valueShow: 'stato'
                        },
                        {
                            nameIconButton: 'visibility',
                            click: TipoClickEnum.SHOW_REFUSE_REASON,
                            tooltip: 'mobility.show_refuse_reason',
                            color: 'accent',
                            show: (motivazioniRifiuto, element: MissioneAndStudenteInfoView) => !!motivazioniRifiuto && element.stato === MissioneStatus.RIFIUTATA,
                            valueShow: 'motivazioneRifiuto'
                        },
                        {
                            nameIconButton: 'close',
                            click: TipoClickEnum.REFUSE_COORDINATOR,
                            tooltip: 'missions.refuse',
                            show: (value, element) => isInApprovalMode,
                            valueShow: 'stato',
                            color: 'accent'
                        },
                    ]
                }
            ],
        }
    };
}


<ng-container *transloco="let t">

    <form [formGroup]="formGroup">
        <mat-form-field appearance="outline"
                        class="min-w-120 mt-4">
            <mat-label>{{t('massive_operations.with_delibera_select_label')}}</mat-label>
            <mat-select formControlName="withDelibera">
                <mat-option [value]="undefined">-</mat-option>
                <mat-option *ngFor="let option of Object.values(StudyPlanWithDeliberaMode)"
                            [value]="option">
                    {{t(getOptionLabel(option))}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>

</ng-container>

<ng-container *transloco="let t">


    <div class="flex flex-col w-full min-h-[20rem] items-center justify-center"
         *ngIf="loading; else stepTemplate">
        <app-loading-screen class="w-full h-full">
        </app-loading-screen>
    </div>


    <ng-template #stepTemplate>
        <div class="mt-4">
            <fuse-alert class="w-full"
                        [showIcon]="true"
                        [appearance]="'outline'"
            >{{t('massive_operations.superamento_corso.hint_choose_course')}}</fuse-alert>
        </div>
        <div class="flex items-center w-full flex-row justify-end mt-2">
            <button mat-flat-button color="primary"
                    (click)="openFilters()">
                <mat-icon class="mr-2">manage_search</mat-icon>
                {{t('menu_filter.advanced_search')}}
            </button>
        </div>

        <div class="flex flex-row justify-between pl-2 mr-2 w-full" >

            <div class="mt-0.5 px-2 py-4 flex flex-row gap-4">
                <!-- TESTO FILTRI APPLICATI -->
                <div class=" text-2xl font-semibold mt-[0.35rem]">{{t('students_list.applied_filters')}}</div>
                <!--RESET BUTTON -->
                <button mat-flat-button
                        *ngIf="currentFilterListChipLabels?.length > 0"
                        (click)="resetFilters()"
                        [color]="'accent'">
                    <mat-icon class="mr-2">delete</mat-icon>
                    <span>{{t('menu_filter.reset')}}</span>
                </button>
            </div>


        </div>

        <div class="w-full flex flex-row justify-center items-center">
            <!-- CHIPS -->
            <div class="w-full flex items-center">

                <div class="flex flex-col gap-1.5">

                    <mat-chip-list class="w-full">
                        <mat-chip color="primary" class="justify-between text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600">
                            {{t('common.display_activities_with_phd_not_perfomed')}}
                        </mat-chip>
                        <ng-container *ngFor="let chip of currentFilterListChipLabels">
                            <mat-chip color="primary" class="justify-between">
                                {{chip | removeUnderscores}}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>
            </div>
        </div>

        <div style="border-radius: 6px; border: 1px solid #e2e8f0;" class="mb-6 mt-4">
            <app-generic-table *ngIf="courseTableConfiguration"
                               [configuration]="courseTableConfiguration"
                               (clickAction)="tableClickAction($event)"
                               (tableDataEmitter)="onCourseDataTableChanged($event)">
            </app-generic-table>
        </div>
    </ng-template>



</ng-container>

import {
    AuthorityType,
    StudenteCicloMotivoStatus,
    StudenteCicloStatus
} from "../../../../../../api-clients/generated/services";
import {Injectable} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {BehaviorSubject, map, Observable, Subject} from "rxjs";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {filter} from "rxjs/operators";
import {StatoAutorizzazione} from "../tabs-periodi-mobilita-ricerca/tabs-periodi-mobilita-ricerca.component";


export interface AutorizzazioniFilters {
    nominativo?: string;
    nazionalita?: string;
    ateneoProvenienza?: string;
    codiceFiscale?: string;
    statoCarriera?: StudenteCicloStatus[];
    statoCarrieraMotivazione?: StudenteCicloMotivoStatus[];
    stato?: string | StatoAutorizzazione;
    hasDeliberaDiProgrammazioneMobilita?: boolean;
    hasAutorizzazioneMobilita?: boolean;
}

export interface ApplyFiltersQuicklyAutorizzazioniI {
    nominativo?: string;
    nazionalita?: string;
    ateneoProvenienza?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AutorizzazioniFilterManagerRequestService {

    mainFormGroup: FormGroup | undefined;
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$= new BehaviorSubject(undefined);
    filterApplied: any;
    applyFiltersQuickly: ApplyFiltersQuicklyAutorizzazioniI;
    defaultFormPathList = [
        'nominativo',
        'ateneoProvenienza',
        'nazionalita',
        'statoCarriera',
        'statoCarrieraMotivazione',
        'stato',
    ];
    coordinatoreFormPathList = [
        'nominativo',
        'ateneoProvenienza',
        'nazionalita',
        'statoCarriera',
        'statoCarrieraMotivazione',
        'stato',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['nominativo', 'menu_filter.nominative'],
        ['ateneoProvenienza', 'students_list.university_of_origin'],
        ['nazionalita', 'students_list.nationality'],
        ['statoCarriera', 'menu_filter.career_status'],
        ['statoCarrieraMotivazione', 'menu_filter.career_status_reason'],
        ['stato', 'common.status']
    ]);
    openDrawer = new Subject();



    constructor(private localStorageService: LocalStorageService,
                private formBuilder: FormBuilder) {
    }

    init(): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            if(!!this.mainFormGroup) {
                this.mainFormGroup.reset();
                this.patchFiltersQuickly();
            } else {
                this.mainFormGroup = this.createDefaultFormGroup();
                this.applyFiltersQuickly = undefined;
            }
        }
    }

    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominativo: this.applyFiltersQuickly?.nominativo || null,
            ateneoProvenienza: this.applyFiltersQuickly?.ateneoProvenienza || null,
            nazionalita: this.applyFiltersQuickly?.nazionalita || null,
            statoCarriera: null,
            statoCarrieraMotivazione: null,
            stato: null,
        });
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    appliedFiltersObsevable$(): Observable<any> {
        return this.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            map(() => this.mainFormGroup?.value)
        );
    }

    getFieldsByRole(ruolo?: AuthorityType, sottoruolo?: AuthorityType, isAreaAdministration?: boolean): string[] {
        switch(ruolo) {
            case AuthorityType.SUPERADMIN:
            case AuthorityType.ADMIN:
            case AuthorityType.PTAATENEO:
            case AuthorityType.PTAATENEOCSVANS:
                if(isAreaAdministration){
                    return [...this.defaultFormPathList,];
                } else {
                    return this.defaultFormPathList;
                }
            default:
                switch (sottoruolo) {
                    case AuthorityType.COORDINATORE:
                        return this.coordinatoreFormPathList;
                    default:
                        return this.defaultFormPathList;
                }
        }


    }

    patchFiltersQuickly(): void {
        if(!!this.applyFiltersQuickly && !!this.mainFormGroup){
            this.mainFormGroup.patchValue(this.applyFiltersQuickly);
        } else if (!!this.applyFiltersQuickly){
            this.mainFormGroup = this.createDefaultFormGroup();
            this.mainFormGroup.patchValue(this.applyFiltersQuickly);
        }
    }
}

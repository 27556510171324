import {Component, OnDestroy, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {OperazioneMassivaTipo} from "../../operazioni-massive/operazioni-massive-config";
import {RequestAbstract} from "../request-abstract";
import {OperazioniMassiveComponent} from "../../operazioni-massive/operazioni-massive.component";
import {MatDrawer} from "@angular/material/sidenav";
import {takeUntil} from "rxjs";
import {StudyPlansFilterService} from "./study-plans-sidebar-filter-container-request/study-plans-filter.service";
import {TableStudyPlansComponent} from "./table-study-plans-request/table-study-plans.component";
import {AuthorityType} from "../../../../../api-clients/generated/services";
import {ApplyFiltersQuicklyStudentsI} from "../../students-list/students-list.component";
import {Router} from "@angular/router";
import {AppInitService} from "../../../../shared/service/app-init.service";

export const DEFAULT_PAGE_SIZE = 10;
@Component({
    selector: 'app-approvazione-piani-formativi',
    templateUrl: './approvazione-piani-formativi.component.html',
    styleUrls: ['./approvazione-piani-formativi.component.scss']
})
export class ApprovazionePianiFormativiComponent extends RequestAbstract implements OnInit, OnDestroy {
    @ViewChild(OperazioniMassiveComponent) operazioniMassiveComponent: OperazioniMassiveComponent;
    @ViewChildren(TableStudyPlansComponent) tableStudyPlansComponent: TableStudyPlansComponent;
    @ViewChild('drawer') drawer: MatDrawer;
    operazioneMassivaTipo = OperazioneMassivaTipo.APPROVAZIONE_PIANI;
    canRenderContent = false;
    currentProfile: AuthorityType;

    constructor(private studyPlansFilterService: StudyPlansFilterService,
                private appInitService: AppInitService,
                protected router: Router) {
        super();
        this.studyPlansFilterService.applyFiltersQuickly = router.getCurrentNavigation()?.extras?.state as ApplyFiltersQuicklyStudentsI;
        this.appInitService.cicloCorsoRuoloSelected$.pipe(takeUntil(this.destroy$)).subscribe(value => this.currentProfile = value?.sottoruolo);
    }

    getCurrentProfileCanAccess() {
        return (profile: AuthorityType) => [
            AuthorityType.COORDINATORE,
            AuthorityType.GRUPPODICOORDINAMENTO,
            AuthorityType.DIRETTOREDIDIPARTIMENTO,
        ].includes(profile);
    }

    ngOnInit(): void {
        this.studyPlansFilterService.openDrawer
            .asObservable()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.getDataForFilters();
            });


    }

    private getDataForFilters() {
        this.openDrawer();
    }

    ngOnDestroy() {
        this.studyPlansFilterService.reset();
        super.ngOnDestroy();
    }

    protected readonly AuthorityType = AuthorityType;
}

import {Route} from '@angular/router';
import {LayoutComponent} from 'app/layout/layout.component';
import {NoAuthGuard} from './core/auth/guards/noAuth.guard';
import {AuthGuard} from './core/auth/guards/auth.guard';
import {PrivacyComponent} from "./modules/landing/privacy/privacy.component";
import {TermsAndConditionsComponent} from "./modules/landing/terms-and-conditions/terms-and-conditions.component";
import {SynchronizationsGuard} from "./core/guards/synchronizations.guard";
import {MobilitaERicercaGuard} from "./core/guards/mobilita-e-ricerca.guard";
import {GestioneDocumentiGuard} from "./core/guards/gestione-documenti.guard";
import {AuditGuard} from "./core/guards/audit.guard";
import {RichiesteDaDeliberareGuard} from "./core/guards/richieste-da-deliberare.guard";
import {OperazioniMassiveGuard} from "./core/guards/operazioni-massive.guard";
import {UsersListGuard} from "./core/guards/users-list.guard";
import {AdministrationGuard} from "./core/guards/administration.guard";
import {OnlyAreaAdministrationGuard} from "./core/auth/guards/only-area-administration.guard";
import {OperazioniMassiveCaricamentoDelibereGuard} from "./core/guards/operazioni-massive-caricamento-delibere.guard";
import {ConfigurationsGuard} from "./core/guards/configurations.guard";
import {SupportGuard} from "./core/guards/support.guard";
import {BeInfoResolver} from "./core/resolvers/be-info-resolver/be-info.resolver";
import {DocentiEsterniListGuard} from "./core/guards/docenti-esterni-list.guard";
import {ConfigurationsGestionaleGuard} from "./core/guards/configurations-gestionale.guard";
import {StudentsListGuard} from "./core/guards/students-list.guard";
import {StatusStudyPlansGuard} from "./core/guards/status-study-plans.guard";
import {TrainingOfferGuard} from "./core/guards/training-offer.guard";
import {SuperamentoCorsiModule} from "./modules/landing/requests/superamento-corsi/superamento-corsi.module";
import {HasCoordinatorProfileGuard} from "./core/guards/has-coordinator-profile.guard";
import {HasDocenteCorsoProfileGuard} from "./core/guards/has-docente-corso-profile.guard";

export enum PathEnum {
    SIGN_IN = 'login',
    HOME = 'home',
    PROFILE = 'profilo',
    INFORMATION = 'informazioni-ciclo',
    CURRICULUM = 'curriculum',
    SCHOLARSHIP = 'tipologia-di-borsa',
    TRAINING_OFFER = 'offerta-formativa',
    STUDENTS= 'dottorandi',
    STUDY_PLAN ='piano-di-studi',
    ACTIVITY_LOG = 'registro-attivita',
    BUDGET = 'budget',
    ANVUR_SURVEYS = 'questionari-anvur',
    SYNCHRONIZATIONS='sincronizzazioni',
    SUPPORT='assistenza',
    PRIVACY="privacy",
    TERMS_CONDITIONS="termini-e-condizioni",
    MOBILITA="mobilita-e-ricerca",
    GESTIONE_DOCUMENTI="gestione-documenti",
    AUDIT="audit",
    REQUESTS = 'richieste-da-approvare',
    OPERAZIONI_MASSIVE="operazioni-massive",
    OPERAZIONI_MASSIVE_CARICAMENTO_DELIBERE="operazioni-massive-caricamento-delibere",
    EXTERN_SIGN_UP="esterni/completa-registrazione",
    ESTERNI_INVITI_LIST="esterni-invitati",
    ADMINISTRATOR = "amministrazione",
    BUDGET_LIST= "lista-spese",
    CONFIGURAZIONI="configurazioni",
    DOCENTI_ESTERNI_LIST="docenti-ed-esterni",
    MISSIONS="missioni",
    STATUS_STUDY_PLANS = 'stato-piani-di-studio',
    ALL_REQUESTS = 'richieste',
    TRANSVERSAL_ACTIVITIES = 'attivita-formative-di-ricerca',
    PERIODS_OF_MOBILITY_AND_RESEARCH= 'periodi-di-mobilità-e-ricerca',
    COURSES= 'attivita-didattiche-programmate',
}


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    {path: '', pathMatch: 'full', redirectTo: PathEnum.HOME},
    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                canActivate: [NoAuthGuard],
                canActivateChild: [NoAuthGuard],
                path: PathEnum.SIGN_IN,
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: PathEnum.EXTERN_SIGN_UP,
                loadChildren: () => import('app/modules/auth/extern-sign-up/extern-sign-up.module').then(m => m.AuthExternSignUpModule),
            },
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'classic'
        },
        resolve: {
            beVersion: BeInfoResolver
        },
        canActivate: [AuthGuard, OnlyAreaAdministrationGuard],
        canActivateChild: [AuthGuard, OnlyAreaAdministrationGuard],
        children: [
            {
                path: PathEnum.HOME,
                loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)
            },
            {
                path: PathEnum.PROFILE,
                loadChildren: () => import('app/modules/landing/profile/profile.module').then(m => m.ProfileModule)
            },
            {
                path: PathEnum.INFORMATION,
                loadChildren: () => import('app/modules/landing/cycle/cycle-information/cycle-information.module').then(m => m.CycleInformationModule)
            },
            {
                path: PathEnum.CURRICULUM,
                loadChildren: () => import('app/modules/landing/cycle/curriculum/curriculum.module').then(m => m.CurriculumModule)
            },
            {
                path: PathEnum.STUDENTS,
                loadChildren: () => import('app/modules/landing/students-list/students-list.module').then(m => m.StudentsListModule),
                canActivate: [StudentsListGuard],
            },
            {
                path: PathEnum.STUDENTS,
                loadChildren: () => import('app/modules/landing/student-details/student-details.module').then(m => m.StudentDetailsModule),
                canActivate: [StudentsListGuard],
            },
            {
                path: PathEnum.TRAINING_OFFER,
                loadChildren: () => import('app/modules/landing/cycle/training-offer/training-offer.module').then(m => m.TrainingOfferModule),
                canDeactivate: [TrainingOfferGuard]
            },
            {
                path: PathEnum.SCHOLARSHIP,
                loadChildren: () => import('app/modules/landing/cycle/scholarship-type/scholarship-type.module').then(m => m.ScholarshipTypeModule)
            },
            {
                path: PathEnum.SUPPORT,
                loadChildren: () => import('app/modules/landing/support/support.module').then(m => m.SupportModule),
                canDeactivate: [SupportGuard]
            },
            {
                path: PathEnum.MOBILITA,
                loadChildren: () => import('app/modules/landing/mobility-dashboard/mobility-dashboard.module').then(m => m.MobilityDashboardModule),
                canActivate: [MobilitaERicercaGuard],
            },
            {
                path: PathEnum.GESTIONE_DOCUMENTI,
                loadChildren: () => import('app/modules/landing/gestione-documenti/gestione-documenti.module').then(m => m.GestioneDocumentiModule),
                canActivate: [GestioneDocumentiGuard],
            },
            {
                path: PathEnum.OPERAZIONI_MASSIVE,
                loadChildren: () => import('app/modules/landing/operazioni-massive/operazioni-massive.module').then(m => m.OperazioniMassiveModule),
                canActivate: [OperazioniMassiveGuard],
            },
            {
                path: PathEnum.OPERAZIONI_MASSIVE_CARICAMENTO_DELIBERE,
                loadChildren: () => import('app/modules/landing/operazioni-massive-caricamento-delibere/operazioni-massive-caricamento-delibere.module').then(m => m.OperazioniMassiveCaricamentoDelibereModule),
                canActivate: [OperazioniMassiveCaricamentoDelibereGuard],
            },
/*            {
                path: PathEnum.REQUESTS,
                loadChildren: () => import('app/modules/landing/requests-to-be-resolved/requests-to-be-resolved.module').then(m => m.RequestsToBeResolvedModule),
                canActivate: [RichiesteDaDeliberareGuard],

            },*/
            {
                path: PathEnum.ESTERNI_INVITI_LIST,
                loadChildren: () => import('app/modules/landing/users-list/users-list.module').then(m => m.UsersListModule),
                canActivate: [UsersListGuard],

            },
            {
                path: PathEnum.DOCENTI_ESTERNI_LIST,
                loadChildren: () => import('app/modules/landing/docenti-esterni-list/docenti-esterni-list.module').then(m => m.DocentiEsterniListModule),
                canActivate: [DocentiEsterniListGuard],
            },
            {
                path: PathEnum.CONFIGURAZIONI,
                loadChildren: () => import('app/modules/landing/configurations-gestionale/configurations-gestionale.module').then(m => m.ConfigurationsGestionaleModule),
                canActivate: [ConfigurationsGestionaleGuard],
            },
            {
                path: PathEnum.STATUS_STUDY_PLANS,
                loadChildren: () => import('app/modules/landing/status-study-plans/status-study-plans.module').then(m => m.StatusStudyPlansModule),
                canActivate: [StatusStudyPlansGuard],
            },

            {
                path: PathEnum.ALL_REQUESTS + '/' + PathEnum.TRANSVERSAL_ACTIVITIES,
                loadChildren: () => import('app/modules/landing/requests/approvazione-attivita-formative-di-ricerca/approvazione-attivita-formative-di-ricerca.module').then(m => m.ApprovazioneAttivitaFormativeDiRicercaModule),
                canActivate: [HasCoordinatorProfileGuard],
            },

            {
                path: PathEnum.ALL_REQUESTS + '/' + PathEnum.PERIODS_OF_MOBILITY_AND_RESEARCH,
                loadChildren: () => import('app/modules/landing/requests/approvazione-periodi-di-mobilita-ricerca/approvazione-periodi-di-mobilita-ricerca.module').then(m => m.ApprovazionePeriodiDiMobilitaRicercaModule),
                canActivate: [HasCoordinatorProfileGuard],
            },
            {
                path: PathEnum.ALL_REQUESTS + '/' + PathEnum.MISSIONS,
                loadChildren: () => import('app/modules/landing/requests/approvazione-missioni/approvazione-missioni.module').then(m => m.ApprovazioneMissioniModule),
                canActivate: [HasCoordinatorProfileGuard],
            },
            {
                path: PathEnum.ALL_REQUESTS + '/' + PathEnum.STUDY_PLAN,
                loadChildren: () => import('app/modules/landing/requests/approvazione-piani-formativi/approvazione-piani-formativi.module').then(m => m.ApprovazionePianiFormativiModule),
                canActivate: [HasCoordinatorProfileGuard],
            },
            {
                path: PathEnum.ALL_REQUESTS + '/' + PathEnum.BUDGET,
                loadChildren: () => import('app/modules/landing/requests/approvazione-budget/approvazione-budget.module').then(m => m.ApprovazioneBudgetModule),
                canActivate: [HasCoordinatorProfileGuard],
            },
            {
                path: PathEnum.ALL_REQUESTS + '/' + PathEnum.ACTIVITY_LOG,
                loadChildren: () => import('app/modules/landing/requests/approvazione-registro-attivita/approvazione-registro-attivita.module').then(m => m.ApprovazioneRegistroAttivitaModule),
                canActivate: [HasCoordinatorProfileGuard],
            },
            {
                path: PathEnum.ALL_REQUESTS + '/' + PathEnum.COURSES,
                loadChildren: () => import('app/modules/landing/requests/superamento-corsi/superamento-corsi.module').then(m => m.SuperamentoCorsiModule),
                canActivate: [HasDocenteCorsoProfileGuard],
            }
        ]
    },
    {
        path: PathEnum.ADMINISTRATOR,
        component: LayoutComponent,
        data: {
            layout: 'classic',
            isAdministrator: true,
        },
        resolve: {
            beVersion: BeInfoResolver
        },
        canActivate: [AuthGuard, AdministrationGuard],
        canActivateChild: [AuthGuard, AdministrationGuard],
        children: [
            {
                path: PathEnum.HOME,
                loadChildren: () => import('app/modules/landing/home-administration/home-administration.module').then(m => m.HomeAdministrationModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.STUDENTS,
                loadChildren: () => import('app/modules/landing/students-list/students-list.module').then(m => m.StudentsListModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.ESTERNI_INVITI_LIST,
                loadChildren: () => import('app/modules/landing/users-list/users-list.module').then(m => m.UsersListModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.MOBILITA,
                loadChildren: () => import('app/modules/landing/mobility-and-research-administration/mobility-and-research-administration.module').then(m => m.MobilityAndResearchAdministrationModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.SCHOLARSHIP,
                loadChildren: () => import('app/modules/landing/cycle/scholarship-type/scholarship-type.module').then(m => m.ScholarshipTypeModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.TRAINING_OFFER,
                loadChildren: () => import('app/modules/landing/cycle/training-offer/training-offer.module').then(m => m.TrainingOfferModule),
                data: {
                    isAdministrator: true,
                },
                canDeactivate: [TrainingOfferGuard]
            },
            {
                path: PathEnum.INFORMATION,
                loadChildren: () => import('app/modules/landing/cycle/cycle-information/cycle-information.module').then(m => m.CycleInformationModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.GESTIONE_DOCUMENTI,
                loadChildren: () => import('app/modules/landing/gestione-documenti/gestione-documenti.module').then(m => m.GestioneDocumentiModule),
            },
            {
                path: PathEnum.STUDENTS,
                loadChildren: () => import('app/modules/landing/student-details/student-details.module').then(m => m.StudentDetailsModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.PROFILE,
                loadChildren: () => import('app/modules/landing/profile/profile.module').then(m => m.ProfileModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.BUDGET_LIST,
                loadChildren: () => import('app/modules/landing/budget-list/budget-list.module').then(m => m.BudgetListModule),
                data: {
                    isAdministrator: true,
                },
            },
            {
                path: PathEnum.SUPPORT,
                loadChildren: () => import('app/modules/landing/support/support.module').then(m => m.SupportModule)
            },
            {
                path: PathEnum.SYNCHRONIZATIONS,
                loadChildren: () => import('app/modules/landing/synchronizations/synchronizations.module').then(m => m.SynchronizationsModule),
                canActivate: [SynchronizationsGuard],
            },
            {
                path: PathEnum.AUDIT,
                loadChildren: () => import('app/modules/landing/audit/audit.module').then(m => m.AuditModule),
                canActivate: [AuditGuard],
            },
            {
                path: PathEnum.CONFIGURAZIONI,
                loadChildren: () => import('app/modules/landing/configurations/configurations.module').then(m => m.ConfigurationsModule),
                canActivate: [ConfigurationsGuard],
            },
            {
                path: PathEnum.DOCENTI_ESTERNI_LIST,
                loadChildren: () => import('app/modules/landing/docenti-esterni-list/docenti-esterni-list.module').then(m => m.DocentiEsterniListModule),
                data: {
                    isAdministrator: true,
                },
            },
        ]
    },
    {
        path: PathEnum.PRIVACY,
        component: PrivacyComponent
    },
    {
        path: PathEnum.TERMS_CONDITIONS,
        component: TermsAndConditionsComponent
    },
    {path: '**', pathMatch: 'full', redirectTo: PathEnum.HOME},
];

import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
    AbstractDefaultComponent
} from '../../../shared/abstracts/abstract-default-component/abstract-default-component';

import {MatDrawer} from '@angular/material/sidenav';
import {finalize, Observable, Subject, take, takeUntil} from 'rxjs';
import {FuseMediaWatcherService} from '../../../../@fuse/services/media-watcher';
import {FuseNavigationService} from '../../../../@fuse/components/navigation';
import {NavigationEnd, Router} from '@angular/router';
import {StudentDetailsService} from "./student-details.service";
import {filter, switchMap, tap} from 'rxjs/operators';
import {AppInitService} from '../../../shared/service/app-init.service';
import {
    AggiornamentoPianoDiStudiStatus,
    AuthorityType,
    PianoDiStudiStatus,
    SottoruoloUtenteCorsoDiStudiInfoView,
    SottoruoloUtenteStudenteInfoView,
    StudentiCicloService,
    UsersService
} from '../../../../api-clients/generated/services';
import {FormControl} from '@angular/forms';
import {get, head} from 'lodash';
import {LocalStorageService} from '../../../shared/service/local-storage.service';
import {FuseConfirmationService} from '../../../../@fuse/services/confirmation';
import {TranslocoService} from '@ngneat/transloco';
import {
    PianoDiStudiStatusAndValidity,
    StudentDetailManagerService
} from "../../../shared/service/student-detail-manager.service";
import {PathEnum} from "../../../app.routing";
import * as fs from "file-saver";
import {SnackbarTypes} from "../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import moment from "moment";
import {HttpResponse} from "@angular/common/http";
import {getCosupPermessoApprovazione, handleErrorOnExportPDF} from "../../../shared/utils/utils";
import {StudyPlanDraftCreationMode} from "./study-plan/tab-creation-study-plan/tab-creation-study-plan.component";
import {
    PianoDiStudiStatusExtended
} from "../cycle/training-offer/dialog-invalid-study-plans/dialog-invalid-study-plans.component";


export function computePlanStatusExtendedChipAccordingStatusAndValidity(statusAndValidity: PianoDiStudiStatusAndValidity): PianoDiStudiStatusExtended {
    const status = statusAndValidity?.status ?? PianoDiStudiStatusExtended.NONPRESENTATO; // if status not set means not presented
    const validity = statusAndValidity?.validity;
    if(status === PianoDiStudiStatus.APPROVATO && !validity){
        return PianoDiStudiStatusExtended.APPROVATONONVALIDO;
    }if(status === PianoDiStudiStatus.APPROVATO && validity){
        return PianoDiStudiStatusExtended.APPROVATOVALIDO;
    } else {
        return status;
    }
}

@Component({
    selector: 'app-student-details',
    templateUrl: './student-details.component.html',
    styleUrls: ['./student-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StudentDetailsComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    @ViewChild('drawer') drawer: MatDrawer;

    drawerMode: 'over' | 'side' = 'side';
    drawerOpened: boolean = true;
    loading = false;
    _unsubscribeAll: Subject<any> = new Subject<any>();
    internalHeaderTitle: string;
    showSelect: boolean = false;
    currentRuolo: AuthorityType;
    ctrlSelectSottoruoloFormControl: FormControl = new FormControl(null);
    sottoruoli: AuthorityType[] = Object.values(AuthorityType);
    sottoruoliUtenteStudente: Array<SottoruoloUtenteStudenteInfoView> | undefined;
    sottoruoliUtenteCorsiDiStudi: Array<SottoruoloUtenteCorsoDiStudiInfoView> | undefined;
    planStatusChip: PianoDiStudiStatusExtended;
    currentSottoruolo: AuthorityType;
    showSidebar = false;
    showRouterOutlet: boolean = false;
    ctrlShowRouterOutlet: FormControl = new FormControl(false);
    downloadStartDate: Date;
    downloadEndDate: Date;
    planInvalidReasons: string[];
    planLastEditStatus?: AggiornamentoPianoDiStudiStatus;

    constructor(private _fuseMediaWatcherService: FuseMediaWatcherService,
                private _fuseNavigationService: FuseNavigationService,
                private appInitService: AppInitService,
                private usersService: UsersService,
                private localStorageService: LocalStorageService,
                private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private studentDetailsService: StudentDetailsService,
                private studentDetailManagerService: StudentDetailManagerService,
                private router: Router,
                private _changeDetectorRef: ChangeDetectorRef,
                private studentiCicloService: StudentiCicloService) {
        super();
        this.downloadEndDate = moment().toDate();
        this.downloadStartDate = moment().subtract(7, 'days').toDate()
    }

    ngOnInit(): void {
        this.setTitleAccordingRoute();
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.destroy$)
        ).subscribe(event => {
            this.setTitleAccordingRoute();
        });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Set the drawerMode and drawerOpened if the given breakpoint is active
                if (matchingAliases.includes('md')) {
                    this.drawerMode = 'side';
                    this.drawerOpened = true;
                } else {
                    this.drawerMode = 'over';
                    this.drawerOpened = false;
                }
            });
        this.getSottoruoli$();

        this.studentDetailManagerService.checkCurrentStudyPlanStatusAndValidity$.asObservable().pipe(
            tap(statusAndValidity => {
                this.planStatusChip = statusAndValidity ? computePlanStatusExtendedChipAccordingStatusAndValidity(statusAndValidity) : undefined,
                this.planInvalidReasons = statusAndValidity ? statusAndValidity.reasons : [];
                this.planLastEditStatus = statusAndValidity?.lastEditStatus;
            })
        ).subscribe();

        this.valueChange();

        this.approvalPermission = getCosupPermessoApprovazione(
            this.studentDetailsService.studentDetails,
            this.localStorageService.getProfileResponse()?.id
        );
    }

    isDocenteOrPersonalePtaOrEsterno(ruolo?: AuthorityType): boolean {
        return (ruolo === AuthorityType.DOCENTE || ruolo === AuthorityType.PERSONALEPTA || ruolo === AuthorityType.ESTERNO);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.studentDetailsService.cleanServiceVariables();
    }


    getSottoruoli$(): void {
        this.loading = true;
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            // non metto la take(1) perché ho bisogno che l'osservabile non vada in complete dopo solo un'emissione
            // altrimenti perdo l'aggiornamento del cambio ruolo a cui deve seguire la gestione nuova dei sottoruoli,
            tap(value => this.currentRuolo = value?.ruolo),
            tap(value => this.currentSottoruolo = value?.sottoruolo),
            tap(() =>  this.setCurrentSottoruoloSePresente()),
            takeUntil(this.destroy$),
            finalize(() => this.loading = false)
        ).subscribe({
                next: (value) => {
                    this.loading = false;
                    this.showSidebar = true;
                },
            }
        );

    }


    setCurrentSottoruolo(): void {
        const savedSottoruolo: AuthorityType = this.localStorageService?.dipartimentoRuoloCiclo?.sottoruolo;
        if (!!savedSottoruolo) {
            const findedSottoruolo = this.sottoruoli.includes(savedSottoruolo);
            if (findedSottoruolo) {
                this.ctrlSelectSottoruoloFormControl.setValue(savedSottoruolo, {emitEvent: false});
            } else {
                this.ctrlSelectSottoruoloFormControl.setValue(head(this.sottoruoli), {emitEvent: false});
            }
        } else {
            this.ctrlSelectSottoruoloFormControl.setValue(head(this.sottoruoli), {emitEvent: false});
        }
        this.localStorageService.setSottoruoloCiclo(this.ctrlSelectSottoruoloFormControl?.value);
    }

    setCurrentSottoruoloSePresente(): void {
        if(!this.appInitService.isAreaAdministrator && this.isDocenteOrPersonalePtaOrEsterno(this.currentRuolo)){
            const savedSottoruolo: AuthorityType = this.currentSottoruolo;
            if (!!savedSottoruolo) {
                const findedSottoruolo = this.sottoruoli.includes(savedSottoruolo);
                if (findedSottoruolo) {
                    this.ctrlSelectSottoruoloFormControl.setValue(savedSottoruolo, {emitEvent: false});
                } else {
                    this.router.navigate([PathEnum.STUDENTS]);
                }
            } else {
                this.router.navigate([PathEnum.STUDENTS]);
            }
        }
    }

    valueChange(): void {
        this.ctrlSelectSottoruoloFormControl.valueChanges.pipe(
            tap(value => this.localStorageService.setSottoruoloCiclo(value)),
        ).subscribe();

        this.ctrlShowRouterOutlet.valueChanges.pipe(
            tap((v) => console.log('AAA ctrl router', v))
        ).subscribe(
            {
                next: (value) => {

                }
            }
        );
    }

    isSmallScreen(isSmallScreen: boolean): void {
        if (isSmallScreen) {
            this.drawer.toggle();
        }
    }


    toogleInternalMenu(hasToogleInternalMenu: boolean): void {
        if (hasToogleInternalMenu) {
            this.drawer.toggle();
        }
    }

    setTitle(title: string): void {
        this.internalHeaderTitle = title;
        console.log(this.internalHeaderTitle);
    }

    showPlanStatusChipChip(title: string): boolean {
        return title === 'sidebar.study_plan';
    }

    planUpdateStatusIfInPlanPage(): string {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (this.internalHeaderTitle === 'sidebar.study_plan'){
            if (this.planLastEditStatus === AggiornamentoPianoDiStudiStatus.DAAPPROVARE){
                return get(translation, 'study_plan_status.there_is_edit_to_approve_sup');
            } else if (this.planLastEditStatus === AggiornamentoPianoDiStudiStatus.APPROVATOPARZIALE){
                return get(translation, 'study_plan_status.there_is_edit_to_approve_coord');
            } else if (this.planLastEditStatus === AggiornamentoPianoDiStudiStatus.RIFIUTATO){
                return get(translation, 'study_plan_status.there_is_edit_to_refused');
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    setShowRouterOutlet($event: any): void {
        this.showRouterOutlet = !!$event;
    }

    protected readonly PathEnum = PathEnum;

    showDownloadInfoButton() {
        const routerSection = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
        return routerSection === PathEnum.PROFILE
            || routerSection === PathEnum.STUDY_PLAN;
    }

    showAndDisableDownloadInfoButtonForStudyPlan() {
        const routerSection = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
        return routerSection === PathEnum.STUDY_PLAN && !this.studentDetailsService.hasAPresentedPiano;
    }

    downloadInfoRequest() {
        this.fuseConfirmationService.showLoader();
        const routerSection = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
        let request: Observable<HttpResponse<Blob>>;
        switch (routerSection) {
            case PathEnum.PROFILE:
                request = this.studentiCicloService.getPdfAnagraficaStudente(this.studentDetailsService.studentId, 'response');
                break;
            case PathEnum.STUDY_PLAN:
                request = this.studentiCicloService.getPdfPianoDiStudiStudente(this.studentDetailsService.studentId, 'response');
                break;
            default:
                return;
        }
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => request),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                fs.saveAs(fileResponse.body, fileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'student.file_download_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                handleErrorOnExportPDF(err,
                    () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: get(translation, 'student.file_download_error', null),
                            type: SnackbarTypes.Warning,
                        });
                    },
                    undefined,
                    (errorObject) => {
                        this.fuseConfirmationService.openErrorDialog({error: errorObject},
                            this.translocoService,
                            () => {},
                            undefined,
                            'common.close',
                            errorObject?.message
                        )
                    });
                console.log(err)
            }
        });
    }

/*    showDownloadDateRange() {
        const routerSection = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
        return routerSection === PathEnum.ACTIVITY_LOG;
    }*/

    private setTitleAccordingRoute() {
        const currentNavigationUrl = this.router.url;
        console.log('students details router url changed', currentNavigationUrl)
        if (currentNavigationUrl.includes(PathEnum.PROFILE)) {
            this.setTitle('sidebar.academic_profile');
        } else if (currentNavigationUrl.includes(PathEnum.STUDY_PLAN)) {
            this.setTitle('sidebar.study_plan');
        } else if (currentNavigationUrl.includes(PathEnum.ACTIVITY_LOG)) {
            this.setTitle('sidebar.activity_log');
        } else if (currentNavigationUrl.includes(PathEnum.BUDGET)) {
            this.setTitle('sidebar.budget');
            /*        } else if (this.currentNavigationUrl.includes(PathEnum.ANVUR_SURVEYS)) {
                        this.setTitle('sidebar.anvur_surveys');*/
        } else if (currentNavigationUrl.includes(PathEnum.MOBILITA)) {
            this.setTitle('sidebar.mobility');
        } else if (currentNavigationUrl.includes(PathEnum.MISSIONS)) {
            this.setTitle('sidebar.missions');
        }
    }

    protected readonly AuthorityType = AuthorityType;
    approvalPermission: boolean;

    openPermessiApprovazioneInfoDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
            title: get(translation, 'student.involved_into_approval_flows', null) + ': '+
                get(translation, this.approvalPermission ? 'common.yes' : 'common.no', null),
            message: get(translation, this.approvalPermission ?
                'student.cosupervisor_can_approve_description' : 'student.cosupervisor_cant_approve_description', null),
            icon: {
                name: 'mat_outline:info',
                color: 'info'
            },
            onBackdrop: {
                show: false,
                backdrop: true
            },
            actions: [
                {
                    color: 'accent',
                    label: get(translation, 'common.close', null), icon: 'close',
                },
            ]
        });
    }
}

import {Component, Inject, OnInit} from '@angular/core';
import {
    ApprovazioneMassivaPeriodiRegistroAttivitaStudenteRequest,
    ApprovazioneMassivaPeriodiRegistroAttivitaStudenteResponse,
    ApprovazioneMassivaPeriodiRegistroAttivitaStudenteSpecifiche,
    DeliberaApprovaSpeseStudentiRequest,
    DeliberaApprovaSpeseStudentiResponse,
    EsitoOperazione, EsitoSingolaApprovazionePeriodoRegistroAttivitaStudente,
    EsitoSingolaApprovazioneSpesa,
    OperazioniMassiveService, PeriodoRegistroAttivitaInfoView, SpesaStudentePerApprovMassivaInfoView
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {get} from "lodash";
import {ApprovazioneRegistroAttivitaMassivaDataI} from "../approvazione-registro-attivita-steps-interface";
import moment from "moment";


@Component({
    selector: 'app-confirm-approvazione-registro-attivita-stepper',
    templateUrl: './confirm-approvazione-registro-attivita.component.html',
    styleUrls: ['./confirm-approvazione-registro-attivita.component.scss']
})
export class ConfirmApprovazioneRegistroAttivitaComponent extends AbstractDefaultComponent implements OnInit {

    approvazionePeriodiData: ApprovazioneRegistroAttivitaMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaApprovazionePeriodoRegistroAttivitaStudente> | undefined;
    resultsPeriodiSelected: PeriodoRegistroAttivitaInfoView[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneRegistroAttivitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneRegistroAttivitaMassivaDataI) => {
            this.approvazionePeriodiData = inputOutputData;
            if(this.approvazionePeriodiData?.periodiSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: ApprovazioneMassivaPeriodiRegistroAttivitaStudenteRequest = {
            specifiche: this.approvazionePeriodiData?.periodiSelected?.map(p => ({
                id_utente: p.studenteCiclo?.utente?.id,
                id_periodo_registro_attivita: p.id,
                fileName: this.approvazionePeriodiData?.compileInfo?.get(p.id)?.name,
            }))
        };
        const fileAllegati = [...this.approvazionePeriodiData?.compileInfo?.values()];
        console.log(this.approvazionePeriodiData)
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.approvazioneMassivaPeriodiRegistroAttivitaDottorandiForm(requestBody, fileAllegati)),
            takeUntil(this.destroy$),
            tap((approvaRegistriResponse: ApprovazioneMassivaPeriodiRegistroAttivitaStudenteResponse) => {
                this.resultsPeriodiSelected  = cloneDeep(this.approvazionePeriodiData?.periodiSelected);
                // forcing the refresh of spese
                this.operazioneMassivaData$.next({
                    periodiSelected: undefined,
                    refreshData: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaRegistriResponse: ApprovazioneMassivaPeriodiRegistroAttivitaStudenteResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaRegistriResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }


    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsPeriodiSelected?.find(s => s?.studenteCiclo?.utente?.id === id_utente);
        return studente?.studenteCiclo?.utente?.cognome + ' ' + studente?.studenteCiclo?.utente?.nome;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

    getPeriodoById(id_periodo_registro_attivita: string): PeriodoRegistroAttivitaInfoView {
        return this.resultsPeriodiSelected.find(p => p.id === id_periodo_registro_attivita);
    }

    protected readonly moment = moment;
}

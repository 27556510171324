<ng-container *transloco="let t">
    <div *ngIf="!!topbarFilterForm">

        <!-- FORMS -->
        <form [formGroup]="topbarFilterForm"
              (keydown.tab)="$event.stopPropagation()">

            <div class="flex flex-col sm:flex-row gap-3 items-center">

                <mat-form-field appearance="outline"
                                class="audit-topbar-mat-form-field datetime-form-field"
                                *ngFor="let field of currentFields">
                    <mat-label>{{t(fieldsLabelMap.get(field))}}</mat-label>
                    <input matInput type="datetime-local"
                           required
                           [formControl]="getFormControlByPath(field)">
                </mat-form-field>

                <!--RESET BUTTON -->
                <div class="flex flex-row justify-end gap-4 p-2">
                    <button mat-flat-button
                            (click)="apply()"
                            [disabled]="!datesChanged()"
                            [color]="'primary'">
                        <mat-icon class="mr-2">done</mat-icon>
                        <span>{{t('home.apply')}}</span>
                    </button>
                    <button mat-flat-button
                            (click)="resetLast24h()"
                            [color]="'accent'">
                        <mat-icon class="mr-2">delete</mat-icon>
                        <span>{{t('menu_filter.reset_last_24h')}}</span>
                    </button>
                </div>

            </div>

        </form>
    </div>
</ng-container>

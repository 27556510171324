import {Component, Inject, OnInit} from '@angular/core';
import {
    ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaRequest,
    ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaResponse,
    DeliberaApprovaSpeseStudentiRequest,
    DeliberaApprovaSpeseStudentiResponse,
    EsitoOperazione, EsitoSingolaApprovazioneRichiestaDiRetribuzioneMobilita,
    EsitoSingolaApprovazioneSpesa,
    OperazioniMassiveService, PeriodoDiMobilitaStudenteInfoView,
    RichiestaDiRetribuzioneMobilitaInfoView,
    SpesaStudentePerApprovMassivaInfoView,
    StudenteCicloIdAndUtenteInfoView
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneValidazioneMobilitaMassivaDataI} from "../approvazione-validazione-mobilita-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {get} from "lodash";
import moment from "moment";


@Component({
    selector: 'app-confirm-approvazione-richieste-validazione-stepper',
    templateUrl: './confirm-approvazione-richieste-validazione.component.html',
    styleUrls: ['./confirm-approvazione-richieste-validazione.component.scss']
})
export class ConfirmApprovazioneRichiesteValidazioneComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneRichiesteData: ApprovazioneValidazioneMobilitaMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaApprovazioneRichiestaDiRetribuzioneMobilita> | undefined;
    resultsRichiesteSelected: (RichiestaDiRetribuzioneMobilitaInfoView & {
        periodoMobilita: PeriodoDiMobilitaStudenteInfoView
    })[];
    resultsCompileInfo: any[];
    outputFormatData = 'DD/MM/YYYY';


    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneValidazioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneValidazioneMobilitaMassivaDataI) => {
            this.approvazioneRichiesteData = inputOutputData;
            if(this.approvazioneRichiesteData?.richiesteValidazioneSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaRequest = {
            specifiche: this.approvazioneRichiesteData?.richiesteValidazioneSelected?.map(s => ({
                id_utente: s.periodoMobilita?.studenteCiclo?.utente?.id,
                codice_mobilita: s.periodoMobilita?.codiceMobilita,
                id_richiesta_di_retribuzione_mobilita: s.id,
                note: this.approvazioneRichiesteData.compileInfo.find(c => c.id_richiesta_di_retribuzione_mobilita === s.id)?.nota,
                periodi: this.approvazioneRichiesteData.compileInfo.find(c => c.id_richiesta_di_retribuzione_mobilita === s.id)?.sottoperiodi
                    ?.map(p => ({dataInizio: moment(p.dataInizio).format(this.outputFormatData), dataFine: moment(p.dataFine).format(this.outputFormatData)})),
            }))
        }
        console.log(this.approvazioneRichiesteData)
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.approvazioneMassivaRichiestaDiRetribuzioneMobilitaForm(requestBody)),
            takeUntil(this.destroy$),
            tap((approveValidazioni: ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaResponse) => {
                this.resultsRichiesteSelected  = cloneDeep(this.approvazioneRichiesteData?.richiesteValidazioneSelected);
                this.resultsCompileInfo = cloneDeep(this.approvazioneRichiesteData?.compileInfo);
                // forcing the refresh of spese
                this.operazioneMassivaData$.next({
                    richiesteValidazioneSelected: undefined,
                    refreshData: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approveValidazioni: ApprovazioneMassivaRichiestaDiRetribuzioneMobilitaResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approveValidazioni?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

   /* private buildUtentiSuperamento(): UserSuperamentoCorsoMassivo[] {
        return this.approvazioneSpeseData?.studentiSelected?.map(s => ({
            id_utente: s.idUtente,
            nota_superamento: this.approvazioneSpeseData?.noteSuperamentoCustom?.find(sWithNotaCustom => s.idUtente === sWithNotaCustom.id)?.nota || this.approvazioneSpeseData?.notaSuperamento
        }))
    }*/

/*    getNotaSuperamento(studente: StudenteCicloUtenteViewImpl): string {
        return this.approvazioneSpeseData?.noteSuperamentoCustom?.find(sWithNotaCustom => studente.idUtente === sWithNotaCustom.id)?.nota || this.approvazioneSpeseData?.notaSuperamento;
    }*/

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsRichiesteSelected?.find(s => s?.periodoMobilita?.studenteCiclo?.utente?.id === id_utente);
        return studente?.periodoMobilita?.studenteCiclo?.utente?.cognome + ' ' + studente?.periodoMobilita?.studenteCiclo?.utente?.nome;
    }


    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

    protected readonly moment = moment;

    getRichiestaByIdValidazione(id_richiesta_di_retribuzione_mobilita: string) {
        return this.approvazioneRichiesteData.richiesteValidazioneSelected.find(r => r.id === id_richiesta_di_retribuzione_mobilita);
    }
    getRichiestaByIdValidazioneFromResults(id_richiesta_di_retribuzione_mobilita: string) {
        return this.resultsRichiesteSelected.find(r => r.id === id_richiesta_di_retribuzione_mobilita);
    }
    getRichiestaByIdValidazioneFromResultsCompileInfo(id_richiesta_di_retribuzione_mobilita: string) {
        return this.resultsCompileInfo.find(c => c.id_richiesta_di_retribuzione_mobilita === id_richiesta_di_retribuzione_mobilita);
    }

}

import {Component, Inject, OnInit} from '@angular/core';
import {
    DeliberaMassivaPianiDiStudiRequest,
    DeliberaMassivaPianiDiStudiResponse,
    EsitoOperazione,
    EsitoSingolaDeliberaPianoDiStudi,
    OperazioniMassiveService
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {
    ApprovazionePianiMassivaDataI,
    CompileInfoApprovazionePianiMassiva
} from "../approvazione-piani-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";


@Component({
    selector: 'app-confirm-approvazione-piani-stepper',
    templateUrl: './confirm-approvazione-piani.component.html',
    styleUrls: ['./confirm-approvazione-piani.component.scss']
})
export class ConfirmApprovazionePianiComponent extends AbstractDefaultComponent implements OnInit {

    approvazionePianiData: ApprovazionePianiMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaDeliberaPianoDiStudi> | undefined;
    resultsPianiSelected: CompileInfoApprovazionePianiMassiva[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazionePianiMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazionePianiMassivaDataI) => {
            this.approvazionePianiData = inputOutputData;
            // when there are studenti selected reset succeed and error flags
            if(this.approvazionePianiData?.compileInfo?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        console.log('confirm compile info', this.approvazionePianiData?.compileInfo)
        const requestBody: DeliberaMassivaPianiDiStudiRequest = {
            id_delibera: this.approvazionePianiData?.deliberaCollegio?.id,
            specifiche: this.approvazionePianiData?.compileInfo?.map(ci => ({
                id_piano_di_studi: ci.planId,
                id_aggiornamento: ci.aggiornamentoId,
            }))
        }
        console.log(this.approvazionePianiData)
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.approvazioneMassivaPianiDiStudiConDelibera(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaPianiResponse: DeliberaMassivaPianiDiStudiResponse) => {
                this.resultsPianiSelected  = cloneDeep(this.approvazionePianiData?.compileInfo);
                // forcing the refresh of studenti
                this.operazioneMassivaData$.next({
                    compileInfo: undefined,
                    refreshData: true,
                    deliberaCollegio: undefined,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaPianiResponse: DeliberaMassivaPianiDiStudiResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaPianiResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsPianiSelected?.find(s => s.studentId === id_utente);
        return studente?.cognome + ' ' + studente?.nome;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

}

import {Component, Inject, OnInit} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AttivitaOffertaFormativaInfoView,
    AuthorityType,
    DeliberaInfoViewImpl,
    DelibereService,
    SpesaStudenteInfoViewImpl,
    SpesaStudentePerApprovMassivaInfoView,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, switchMap, take, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {formGroupConfigInterface} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ApprovazioneSpeseMassivaDataI} from '../approvazione-spese-steps-interface';
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get} from "lodash";
import {MatDialog} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {makeFilenameFromFE} from "../../../../../shared/utils/utils";
import {HttpResponse} from "@angular/common/http";
import {CicloCorsoRuoloInterface} from "../../../../../shared/interface/CicloCorsoRuoloInterface";
import * as fs from "file-saver";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {StudentDetailsService} from "../../../student-details/student-details.service";


@Component({
    selector: 'app-info-approvazione-stepper',
    templateUrl: './compila-info-approvazione.component.html',
    styleUrls: ['./compila-info-approvazione.component.scss']
})
export class CompilaInfoApprovazioneComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    speseListConfiguration: GenericTableConfigurationModel;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    corso: AttivitaOffertaFormativaInfoView = undefined;
    compileInfoFormGroup: FormGroup = new FormGroup<any>({});
    speseSelected: SpesaStudentePerApprovMassivaInfoView[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private studentiCicloService: StudentiCicloService,
                private localStorageService: LocalStorageService,
                private studentDetailsService: StudentDetailsService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneSpeseMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneSpeseMassivaDataI) => {
            this.initForm();
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshData){
                this.canGoNext$.next(false);
            }
            // if spese selected changes reset delibera form
            if(inputOutputData.speseSelected !== this.speseSelected){
                this.speseSelected = inputOutputData?.speseSelected;
                this.speseListConfiguration = this.buildSpeseConfiguration(this.speseSelected || []);
            }
        });
        this.compileInfoFormGroup?.valueChanges?.subscribe((value) => {
            this.canGoNext$.next(this.compileInfoFormGroup.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$.getValue(),
                compileInfo: this.compileInfoFormGroup.get(['child', 'input_fields'])?.value
            });
        })
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    buildSpeseConfiguration(spese: SpesaStudentePerApprovMassivaInfoView[]): GenericTableConfigurationModel {
        const activeLang = this.translocoService.getActiveLang()
        const translation = this.translocoService.getTranslation().get(activeLang);
        return {
            configuration: {
                data: spese?.map(spesa => ({
                    ...spesa,
                    studente: spesa.budgetStudente?.studenteCiclo?.utente?.cognome + ' ' + spesa.budgetStudente?.studenteCiclo?.utente?.nome,
                    studenteForChip: [{nome: spesa.budgetStudente?.studenteCiclo?.utente?.nome, cognome: spesa.budgetStudente?.studenteCiclo?.utente?.cognome}],
                    codiceSpesaForUI: spesa.codiceSpesa,
                    importoApprovato: spesa.importoRichiesto,
                    id: spesa.codiceSpesa
                })),
                sticky: true,
                totalElements: spese?.length,
                isPaginatedBE: false,
                messaggioDatiAssenti: 'custom_table.no_data',
                hidePaginator: true,
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        show: () => false,
                        nomeColonna: 'id',
                        colonnaKey: 'id',
                        hideColonna: true,
                        flex: 0,
                        formControl: {
                            type: TypeDialogFormEnum.TEXT,
                            show: false,
                            name: 'id',
                        }
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'budget.description',
                        colonnaKey: 'descrizione',
                        flex: 20,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'budget.codice_spesa',
                        colonnaKey: 'codiceSpesaForUI',
                        flex: 20,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'common.dottorando',
                        colonnaKey: 'studenteForChip',
                        flex: 25,
                    },
                    {
                        tipo: TipoColonnaEnum.AMOUNT,
                        nomeColonna: 'budget.req_amount',
                        colonnaKey: 'importoRichiesto',
                        flex: 15,
                    },
                    {
                        tipo: TipoColonnaEnum.INPUT_AMOUNT,
                        nomeColonna: 'budget.appr_amount',
                        colonnaKey: 'importoApprovato',
                        flex: 15,
                        formControl: {
                            type: TypeDialogFormEnum.AMOUNT,
                            show: true,
                            name: 'importoApprovato',
                            required: true,
                            min: { number: 0.1, text: get(translation, 'budget.min_budget_error', null)}
                        }
                    },
                    {
                        nomeColonna: 'budget.show_attachment_file',
                        colonnaKey: 'urlAllegatiRichiesta',
                        flex: 10,
                        tipo: TipoColonnaEnum.ACTION,
                        button: [
                            {
                                nameIconButton: 'download',
                                click: TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST,
                                tooltip: 'budget.show_attachment_file',
                                color: 'accent',
                                show: (urlAllegatiRichiesta, spesa: SpesaStudenteInfoViewImpl) => spesa.urlAllegatiRichiesta?.length > 0,
                                valueShow: 'urlAllegatiRichiesta'
                            }
                        ]
                    },
                ],
            }
        };
    }

    private initForm() {
        this.canGoNext$.next(this.compileInfoFormGroup.valid);
    }

    tableClickAction($event: ClickEvent) {
        if($event.tipoClick === TipoClickEnum.SHOW_ALLEGATO_BUDGET_REQUEST) {
            this.downloadAllegatiRequest($event.value);
        }
    }

    private downloadAllegatiRequest(spesa: SpesaStudentePerApprovMassivaInfoView) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.studentiCicloService.getZipAllegatiRichiestaDiSpesaStudente(spesa?.budgetStudente?.studenteCiclo?.utente?.id, spesa?.codiceSpesa, 'response')),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (fileResponse: HttpResponse<Blob>) => {
                //const fileName = fileResponse.headers?.get('Content-Disposition')?.split('=').pop();
                const fileTypeForName = 'ALLEGATI_RICHIESTA_DI_SPESA_' + spesa?.codiceSpesa;
                const studentCicloCorsoRuolo: CicloCorsoRuoloInterface = {
                    codiceCorsoStudi: spesa?.budgetStudente?.studenteCiclo?.codiceCorsoDiStudiEsse3,
                    ciclo:  spesa?.budgetStudente?.studenteCiclo?.numeroCicloDottorato + '',
                    ruolo: AuthorityType.STUDENTE,
                    denominazioneCorsoStudi: ''
                }
                const outputFileName = makeFilenameFromFE(studentCicloCorsoRuolo, '.zip', fileTypeForName);
                fs.saveAs(fileResponse?.body, outputFileName);
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_show_success', null),
                    type: SnackbarTypes.Success,
                });
            },
            error: (err) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation, 'budget.file_download_error', null),
                    error: err, type: SnackbarTypes.Error,
                });
                console.log(err)
            }
        });
    }


}

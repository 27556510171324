/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddDeliberaDiProgrammazioneMobilitaDTO } from '../model/addDeliberaDiProgrammazioneMobilitaDTO';
import { AddImportoGiornalieroMaggiorazioneRequest } from '../model/addImportoGiornalieroMaggiorazioneRequest';
import { AggiornamentoPeriodoDiMobilitaStudenteInfoView } from '../model/aggiornamentoPeriodoDiMobilitaStudenteInfoView';
import { AteneoEsteroMobilitaInfoViewImpl } from '../model/ateneoEsteroMobilitaInfoViewImpl';
import { DeliberaDiProgrammazioneMobilitaInfoView } from '../model/deliberaDiProgrammazioneMobilitaInfoView';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GetExportMobilitaDTO } from '../model/getExportMobilitaDTO';
import { GetPdfDecretoPagamentoPeriodiDiMobilitaDTO } from '../model/getPdfDecretoPagamentoPeriodiDiMobilitaDTO';
import { ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView } from '../model/importoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView';
import { LastExportDateDTO } from '../model/lastExportDateDTO';
import { MobilitaRequestSelectFormValuesDTO } from '../model/mobilitaRequestSelectFormValuesDTO';
import { PageDecretoPagamentoPeriodiDiMobilitaInfoView } from '../model/pageDecretoPagamentoPeriodiDiMobilitaInfoView';
import { PageDeliberaDiProgrammazioneMobilitaFiltered } from '../model/pageDeliberaDiProgrammazioneMobilitaFiltered';
import { PageJobDTO } from '../model/pageJobDTO';
import { PagePeriodoDiMobilitaStudenteInfoView } from '../model/pagePeriodoDiMobilitaStudenteInfoView';
import { PeriodoDiMobilitaStudenteStatus } from '../model/periodoDiMobilitaStudenteStatus';
import { RichiestaDiRetribuzioneMobilitaStatus } from '../model/richiestaDiRetribuzioneMobilitaStatus';
import { StatoTemporaleEnum } from '../model/statoTemporaleEnum';
import { StrutturaEsteraNonCodificataInfoViewImpl } from '../model/strutturaEsteraNonCodificataInfoViewImpl';
import { TipoPeriodoEnum } from '../model/tipoPeriodoEnum';
import { UpdateDeliberaDiProgrammazioneMobilitaDTO } from '../model/updateDeliberaDiProgrammazioneMobilitaDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PeriodiDiMobilitaService {

    protected basePath = 'https://api-dev-1-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette di aggiungere una nuova delibera di programmazione mobilità.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDeliberaDiProgrammazioneMobilita(body: AddDeliberaDiProgrammazioneMobilitaDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<DeliberaDiProgrammazioneMobilitaInfoView>>;
    public addDeliberaDiProgrammazioneMobilita(body: AddDeliberaDiProgrammazioneMobilitaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DeliberaDiProgrammazioneMobilitaInfoView>>>;
    public addDeliberaDiProgrammazioneMobilita(body: AddDeliberaDiProgrammazioneMobilitaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DeliberaDiProgrammazioneMobilitaInfoView>>>;
    public addDeliberaDiProgrammazioneMobilita(body: AddDeliberaDiProgrammazioneMobilitaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addDeliberaDiProgrammazioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<DeliberaDiProgrammazioneMobilitaInfoView>>('post',`${this.basePath}/api/v1/mobilita/delibera-di-programmazione/add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette il censimento di un nuovo importo giornaliero di maggiorazione per i periodi di mobilità.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addImportoGiornalieroMaggiorazione(body: AddImportoGiornalieroMaggiorazioneRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView>>;
    public addImportoGiornalieroMaggiorazione(body: AddImportoGiornalieroMaggiorazioneRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView>>>;
    public addImportoGiornalieroMaggiorazione(body: AddImportoGiornalieroMaggiorazioneRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView>>>;
    public addImportoGiornalieroMaggiorazione(body: AddImportoGiornalieroMaggiorazioneRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addImportoGiornalieroMaggiorazione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView>>('post',`${this.basePath}/api/v1/mobilita/add-importo-giornaliero-maggiorazione`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di eliminare una delibera di programmazione mobilità.
     * 
     * @param idDeliberaDiProgrammazioneMobilita 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDeliberaDiProgrammazioneMobilita(idDeliberaDiProgrammazioneMobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DeliberaDiProgrammazioneMobilitaInfoView>>;
    public deleteDeliberaDiProgrammazioneMobilita(idDeliberaDiProgrammazioneMobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DeliberaDiProgrammazioneMobilitaInfoView>>>;
    public deleteDeliberaDiProgrammazioneMobilita(idDeliberaDiProgrammazioneMobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DeliberaDiProgrammazioneMobilitaInfoView>>>;
    public deleteDeliberaDiProgrammazioneMobilita(idDeliberaDiProgrammazioneMobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idDeliberaDiProgrammazioneMobilita === null || idDeliberaDiProgrammazioneMobilita === undefined) {
            throw new Error('Required parameter idDeliberaDiProgrammazioneMobilita was null or undefined when calling deleteDeliberaDiProgrammazioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DeliberaDiProgrammazioneMobilitaInfoView>>('delete',`${this.basePath}/api/v1/mobilita/delibera-di-programmazione/${encodeURIComponent(String(idDeliberaDiProgrammazioneMobilita))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array della delibera di una richiesta di periodo di mobilità studente.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadExportMobilita(body: GetExportMobilitaDTO, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadExportMobilita(body: GetExportMobilitaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadExportMobilita(body: GetExportMobilitaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadExportMobilita(body: GetExportMobilitaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling downloadExportMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/mobilita/download`,
            {
             responseType: "blob" as "json",
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista degli aggiornamenti di un periodo di mobilità a partire dal .
     * 
     * @param codiceMobilita 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAggiornamentiPeriodoDiMobilitaByCodiceMobilita(codiceMobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AggiornamentoPeriodoDiMobilitaStudenteInfoView>>;
    public getAggiornamentiPeriodoDiMobilitaByCodiceMobilita(codiceMobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AggiornamentoPeriodoDiMobilitaStudenteInfoView>>>;
    public getAggiornamentiPeriodoDiMobilitaByCodiceMobilita(codiceMobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AggiornamentoPeriodoDiMobilitaStudenteInfoView>>>;
    public getAggiornamentiPeriodoDiMobilitaByCodiceMobilita(codiceMobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (codiceMobilita === null || codiceMobilita === undefined) {
            throw new Error('Required parameter codiceMobilita was null or undefined when calling getAggiornamentiPeriodoDiMobilitaByCodiceMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AggiornamentoPeriodoDiMobilitaStudenteInfoView>>('get',`${this.basePath}/api/v1/mobilita/${encodeURIComponent(String(codiceMobilita))}/aggiornamenti`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista degli atenei esteri eventualmente filtrati per nazione.
     * 
     * @param codiceNazione 
     * @param cittaAteneo 
     * @param descrizioneAteneo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAteneiEsteriMobilitaForm(codiceNazione?: string, cittaAteneo?: string, descrizioneAteneo?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AteneoEsteroMobilitaInfoViewImpl>>;
    public getAteneiEsteriMobilitaForm(codiceNazione?: string, cittaAteneo?: string, descrizioneAteneo?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AteneoEsteroMobilitaInfoViewImpl>>>;
    public getAteneiEsteriMobilitaForm(codiceNazione?: string, cittaAteneo?: string, descrizioneAteneo?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AteneoEsteroMobilitaInfoViewImpl>>>;
    public getAteneiEsteriMobilitaForm(codiceNazione?: string, cittaAteneo?: string, descrizioneAteneo?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (codiceNazione !== undefined) {
            formParams = formParams.append('codiceNazione', <any>codiceNazione) as any || formParams;
        }
        if (cittaAteneo !== undefined) {
            formParams = formParams.append('cittaAteneo', <any>cittaAteneo) as any || formParams;
        }
        if (descrizioneAteneo !== undefined) {
            formParams = formParams.append('descrizioneAteneo', <any>descrizioneAteneo) as any || formParams;
        }

        return this.httpClient.request<Array<AteneoEsteroMobilitaInfoViewImpl>>('post',`${this.basePath}/api/v1/mobilita/atenei-esteri`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il dettaglio di una delibera di programmazione mobilità.
     * 
     * @param idDeliberaDiProgrammazione 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeliberaDiProgrammazioneMobilita(idDeliberaDiProgrammazione: string, observe?: 'body', reportProgress?: boolean): Observable<DeliberaDiProgrammazioneMobilitaInfoView>;
    public getDeliberaDiProgrammazioneMobilita(idDeliberaDiProgrammazione: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliberaDiProgrammazioneMobilitaInfoView>>;
    public getDeliberaDiProgrammazioneMobilita(idDeliberaDiProgrammazione: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliberaDiProgrammazioneMobilitaInfoView>>;
    public getDeliberaDiProgrammazioneMobilita(idDeliberaDiProgrammazione: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idDeliberaDiProgrammazione === null || idDeliberaDiProgrammazione === undefined) {
            throw new Error('Required parameter idDeliberaDiProgrammazione was null or undefined when calling getDeliberaDiProgrammazioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeliberaDiProgrammazioneMobilitaInfoView>('get',`${this.basePath}/api/v1/mobilita/delibera-di-programmazione/${encodeURIComponent(String(idDeliberaDiProgrammazione))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le informazioni dei job di export dei periodi di mobilità.
     * 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExportMobilitaJobs(page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageJobDTO>;
    public getExportMobilitaJobs(page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageJobDTO>>;
    public getExportMobilitaJobs(page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageJobDTO>>;
    public getExportMobilitaJobs(page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PageJobDTO>('get',`${this.basePath}/api/v1/mobilita/exports`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli importi giornalieri di maggiorazione censiti per i periodi di mobilità.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getImportiGiornalieriMaggiorazione(observe?: 'body', reportProgress?: boolean): Observable<Array<ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView>>;
    public getImportiGiornalieriMaggiorazione(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView>>>;
    public getImportiGiornalieriMaggiorazione(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView>>>;
    public getImportiGiornalieriMaggiorazione(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ImportoGiornalieroMaggiorazionePeriodiDiMobilitaInfoView>>('get',`${this.basePath}/api/v1/mobilita/get-importi-giornalieri-maggiorazione`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce date e ora dell&#x27;ultimo export per ANS eseguito.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLastDataTimeOfExportPerAnsJob(observe?: 'body', reportProgress?: boolean): Observable<LastExportDateDTO>;
    public getLastDataTimeOfExportPerAnsJob(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LastExportDateDTO>>;
    public getLastDataTimeOfExportPerAnsJob(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LastExportDateDTO>>;
    public getLastDataTimeOfExportPerAnsJob(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LastExportDateDTO>('get',`${this.basePath}/api/v1/mobilita/get-last-ans-export-date`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le liste di valori da mostrare nelle select della form di richiesta periodo di mobilità.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMobilitaRequestSelectFormValues(observe?: 'body', reportProgress?: boolean): Observable<MobilitaRequestSelectFormValuesDTO>;
    public getMobilitaRequestSelectFormValues(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MobilitaRequestSelectFormValuesDTO>>;
    public getMobilitaRequestSelectFormValues(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MobilitaRequestSelectFormValuesDTO>>;
    public getMobilitaRequestSelectFormValues(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MobilitaRequestSelectFormValuesDTO>('get',`${this.basePath}/api/v1/mobilita/form-select-values`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un decreto di pagamento di periodi di mobilità.
     * 
     * @param body 
     * @param codiceDecreto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfDecretoPagamentoPeriodiDiMobilita(body: GetPdfDecretoPagamentoPeriodiDiMobilitaDTO, codiceDecreto: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfDecretoPagamentoPeriodiDiMobilita(body: GetPdfDecretoPagamentoPeriodiDiMobilitaDTO, codiceDecreto: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfDecretoPagamentoPeriodiDiMobilita(body: GetPdfDecretoPagamentoPeriodiDiMobilitaDTO, codiceDecreto: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfDecretoPagamentoPeriodiDiMobilita(body: GetPdfDecretoPagamentoPeriodiDiMobilitaDTO, codiceDecreto: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getPdfDecretoPagamentoPeriodiDiMobilita.');
        }

        if (codiceDecreto === null || codiceDecreto === undefined) {
            throw new Error('Required parameter codiceDecreto was null or undefined when calling getPdfDecretoPagamentoPeriodiDiMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/mobilita/decreti-di-pagamento/${encodeURIComponent(String(codiceDecreto))}/pdf`,
            {
             responseType: "blob" as "json",
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i periodi di mobilità degli studenti con i filtri applicati.
     * 
     * @param codiceMobilita 
     * @param tipoPeriodoEnum 
     * @param nominativoStudente 
     * @param codiceFiscaleStudente 
     * @param matricolaStudente 
     * @param periodoDiMobilitaStatus 
     * @param statoTemporaleEnum 
     * @param periodoRicercaDa 
     * @param periodoRicercaA 
     * @param annoAccademico 
     * @param nomeStrutturaEstera 
     * @param codiceNazioneStrutturaEstera 
     * @param hasDataFineNull 
     * @param codiceTipologiaMobilita 
     * @param numeroTipologiaBonus 
     * @param codiceTipoInterventoSupporto 
     * @param dataUltimaModificaAfter 
     * @param statiRichiesteDiRetribuzioneMobilita 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiDiMobilitaStudenteForm(codiceMobilita?: string, tipoPeriodoEnum?: TipoPeriodoEnum, nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, periodoDiMobilitaStatus?: PeriodoDiMobilitaStudenteStatus, statoTemporaleEnum?: StatoTemporaleEnum, periodoRicercaDa?: string, periodoRicercaA?: string, annoAccademico?: string, nomeStrutturaEstera?: string, codiceNazioneStrutturaEstera?: string, hasDataFineNull?: boolean, codiceTipologiaMobilita?: string, numeroTipologiaBonus?: number, codiceTipoInterventoSupporto?: string, dataUltimaModificaAfter?: Date, statiRichiesteDiRetribuzioneMobilita?: Array<RichiestaDiRetribuzioneMobilitaStatus>, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PagePeriodoDiMobilitaStudenteInfoView>;
    public getPeriodiDiMobilitaStudenteForm(codiceMobilita?: string, tipoPeriodoEnum?: TipoPeriodoEnum, nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, periodoDiMobilitaStatus?: PeriodoDiMobilitaStudenteStatus, statoTemporaleEnum?: StatoTemporaleEnum, periodoRicercaDa?: string, periodoRicercaA?: string, annoAccademico?: string, nomeStrutturaEstera?: string, codiceNazioneStrutturaEstera?: string, hasDataFineNull?: boolean, codiceTipologiaMobilita?: string, numeroTipologiaBonus?: number, codiceTipoInterventoSupporto?: string, dataUltimaModificaAfter?: Date, statiRichiesteDiRetribuzioneMobilita?: Array<RichiestaDiRetribuzioneMobilitaStatus>, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PagePeriodoDiMobilitaStudenteInfoView>>;
    public getPeriodiDiMobilitaStudenteForm(codiceMobilita?: string, tipoPeriodoEnum?: TipoPeriodoEnum, nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, periodoDiMobilitaStatus?: PeriodoDiMobilitaStudenteStatus, statoTemporaleEnum?: StatoTemporaleEnum, periodoRicercaDa?: string, periodoRicercaA?: string, annoAccademico?: string, nomeStrutturaEstera?: string, codiceNazioneStrutturaEstera?: string, hasDataFineNull?: boolean, codiceTipologiaMobilita?: string, numeroTipologiaBonus?: number, codiceTipoInterventoSupporto?: string, dataUltimaModificaAfter?: Date, statiRichiesteDiRetribuzioneMobilita?: Array<RichiestaDiRetribuzioneMobilitaStatus>, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PagePeriodoDiMobilitaStudenteInfoView>>;
    public getPeriodiDiMobilitaStudenteForm(codiceMobilita?: string, tipoPeriodoEnum?: TipoPeriodoEnum, nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, periodoDiMobilitaStatus?: PeriodoDiMobilitaStudenteStatus, statoTemporaleEnum?: StatoTemporaleEnum, periodoRicercaDa?: string, periodoRicercaA?: string, annoAccademico?: string, nomeStrutturaEstera?: string, codiceNazioneStrutturaEstera?: string, hasDataFineNull?: boolean, codiceTipologiaMobilita?: string, numeroTipologiaBonus?: number, codiceTipoInterventoSupporto?: string, dataUltimaModificaAfter?: Date, statiRichiesteDiRetribuzioneMobilita?: Array<RichiestaDiRetribuzioneMobilitaStatus>, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (codiceMobilita !== undefined) {
            formParams = formParams.append('codiceMobilita', <any>codiceMobilita) as any || formParams;
        }
        if (tipoPeriodoEnum !== undefined) {
            formParams = formParams.append('tipoPeriodoEnum', <any>tipoPeriodoEnum) as any || formParams;
        }
        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (codiceFiscaleStudente !== undefined) {
            formParams = formParams.append('codiceFiscaleStudente', <any>codiceFiscaleStudente) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (periodoDiMobilitaStatus !== undefined) {
            formParams = formParams.append('periodoDiMobilitaStatus', <any>periodoDiMobilitaStatus) as any || formParams;
        }
        if (statoTemporaleEnum !== undefined) {
            formParams = formParams.append('statoTemporaleEnum', <any>statoTemporaleEnum) as any || formParams;
        }
        if (periodoRicercaDa !== undefined) {
            formParams = formParams.append('periodoRicercaDa', <any>periodoRicercaDa) as any || formParams;
        }
        if (periodoRicercaA !== undefined) {
            formParams = formParams.append('periodoRicercaA', <any>periodoRicercaA) as any || formParams;
        }
        if (annoAccademico !== undefined) {
            formParams = formParams.append('annoAccademico', <any>annoAccademico) as any || formParams;
        }
        if (nomeStrutturaEstera !== undefined) {
            formParams = formParams.append('nomeStrutturaEstera', <any>nomeStrutturaEstera) as any || formParams;
        }
        if (codiceNazioneStrutturaEstera !== undefined) {
            formParams = formParams.append('codiceNazioneStrutturaEstera', <any>codiceNazioneStrutturaEstera) as any || formParams;
        }
        if (hasDataFineNull !== undefined) {
            formParams = formParams.append('hasDataFineNull', <any>hasDataFineNull) as any || formParams;
        }
        if (codiceTipologiaMobilita !== undefined) {
            formParams = formParams.append('codiceTipologiaMobilita', <any>codiceTipologiaMobilita) as any || formParams;
        }
        if (numeroTipologiaBonus !== undefined) {
            formParams = formParams.append('numeroTipologiaBonus', <any>numeroTipologiaBonus) as any || formParams;
        }
        if (codiceTipoInterventoSupporto !== undefined) {
            formParams = formParams.append('codiceTipoInterventoSupporto', <any>codiceTipoInterventoSupporto) as any || formParams;
        }
        if (dataUltimaModificaAfter !== undefined) {
            formParams = formParams.append('dataUltimaModificaAfter', <any>dataUltimaModificaAfter) as any || formParams;
        }
        if (statiRichiesteDiRetribuzioneMobilita) {
            statiRichiesteDiRetribuzioneMobilita.forEach((element) => {
                formParams = formParams.append('statiRichiesteDiRetribuzioneMobilita', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<PagePeriodoDiMobilitaStudenteInfoView>('post',`${this.basePath}/api/v1/mobilita/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;export-csv per ANS dei periodi di mobilità studente.
     * 
     * @param dataUltimaModificaAfter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiDiMobilitaStudenteExportCsvPerANSForm(dataUltimaModificaAfter?: Date, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPeriodiDiMobilitaStudenteExportCsvPerANSForm(dataUltimaModificaAfter?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPeriodiDiMobilitaStudenteExportCsvPerANSForm(dataUltimaModificaAfter?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPeriodiDiMobilitaStudenteExportCsvPerANSForm(dataUltimaModificaAfter?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dataUltimaModificaAfter !== undefined) {
            formParams = formParams.append('dataUltimaModificaAfter', <any>dataUltimaModificaAfter) as any || formParams;
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/mobilita/export-csv-ans`,
            {
             responseType: "blob" as "json",
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;export-pdf dei periodi di mobilità studente con i filtri applicati.
     * 
     * @param codiceMobilita 
     * @param tipoPeriodoEnum 
     * @param nominativoStudente 
     * @param codiceFiscaleStudente 
     * @param matricolaStudente 
     * @param periodoDiMobilitaStatus 
     * @param statoTemporaleEnum 
     * @param periodoRicercaDa 
     * @param periodoRicercaA 
     * @param annoAccademico 
     * @param nomeStrutturaEstera 
     * @param codiceNazioneStrutturaEstera 
     * @param hasDataFineNull 
     * @param codiceTipologiaMobilita 
     * @param numeroTipologiaBonus 
     * @param codiceTipoInterventoSupporto 
     * @param dataUltimaModificaAfter 
     * @param statiRichiesteDiRetribuzioneMobilita 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiDiMobilitaStudenteExportPdfForm(codiceMobilita?: string, tipoPeriodoEnum?: TipoPeriodoEnum, nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, periodoDiMobilitaStatus?: PeriodoDiMobilitaStudenteStatus, statoTemporaleEnum?: StatoTemporaleEnum, periodoRicercaDa?: string, periodoRicercaA?: string, annoAccademico?: string, nomeStrutturaEstera?: string, codiceNazioneStrutturaEstera?: string, hasDataFineNull?: boolean, codiceTipologiaMobilita?: string, numeroTipologiaBonus?: number, codiceTipoInterventoSupporto?: string, dataUltimaModificaAfter?: Date, statiRichiesteDiRetribuzioneMobilita?: Array<RichiestaDiRetribuzioneMobilitaStatus>, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPeriodiDiMobilitaStudenteExportPdfForm(codiceMobilita?: string, tipoPeriodoEnum?: TipoPeriodoEnum, nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, periodoDiMobilitaStatus?: PeriodoDiMobilitaStudenteStatus, statoTemporaleEnum?: StatoTemporaleEnum, periodoRicercaDa?: string, periodoRicercaA?: string, annoAccademico?: string, nomeStrutturaEstera?: string, codiceNazioneStrutturaEstera?: string, hasDataFineNull?: boolean, codiceTipologiaMobilita?: string, numeroTipologiaBonus?: number, codiceTipoInterventoSupporto?: string, dataUltimaModificaAfter?: Date, statiRichiesteDiRetribuzioneMobilita?: Array<RichiestaDiRetribuzioneMobilitaStatus>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPeriodiDiMobilitaStudenteExportPdfForm(codiceMobilita?: string, tipoPeriodoEnum?: TipoPeriodoEnum, nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, periodoDiMobilitaStatus?: PeriodoDiMobilitaStudenteStatus, statoTemporaleEnum?: StatoTemporaleEnum, periodoRicercaDa?: string, periodoRicercaA?: string, annoAccademico?: string, nomeStrutturaEstera?: string, codiceNazioneStrutturaEstera?: string, hasDataFineNull?: boolean, codiceTipologiaMobilita?: string, numeroTipologiaBonus?: number, codiceTipoInterventoSupporto?: string, dataUltimaModificaAfter?: Date, statiRichiesteDiRetribuzioneMobilita?: Array<RichiestaDiRetribuzioneMobilitaStatus>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPeriodiDiMobilitaStudenteExportPdfForm(codiceMobilita?: string, tipoPeriodoEnum?: TipoPeriodoEnum, nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, periodoDiMobilitaStatus?: PeriodoDiMobilitaStudenteStatus, statoTemporaleEnum?: StatoTemporaleEnum, periodoRicercaDa?: string, periodoRicercaA?: string, annoAccademico?: string, nomeStrutturaEstera?: string, codiceNazioneStrutturaEstera?: string, hasDataFineNull?: boolean, codiceTipologiaMobilita?: string, numeroTipologiaBonus?: number, codiceTipoInterventoSupporto?: string, dataUltimaModificaAfter?: Date, statiRichiesteDiRetribuzioneMobilita?: Array<RichiestaDiRetribuzioneMobilitaStatus>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



















        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (codiceMobilita !== undefined) {
            formParams = formParams.append('codiceMobilita', <any>codiceMobilita) as any || formParams;
        }
        if (tipoPeriodoEnum !== undefined) {
            formParams = formParams.append('tipoPeriodoEnum', <any>tipoPeriodoEnum) as any || formParams;
        }
        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (codiceFiscaleStudente !== undefined) {
            formParams = formParams.append('codiceFiscaleStudente', <any>codiceFiscaleStudente) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (periodoDiMobilitaStatus !== undefined) {
            formParams = formParams.append('periodoDiMobilitaStatus', <any>periodoDiMobilitaStatus) as any || formParams;
        }
        if (statoTemporaleEnum !== undefined) {
            formParams = formParams.append('statoTemporaleEnum', <any>statoTemporaleEnum) as any || formParams;
        }
        if (periodoRicercaDa !== undefined) {
            formParams = formParams.append('periodoRicercaDa', <any>periodoRicercaDa) as any || formParams;
        }
        if (periodoRicercaA !== undefined) {
            formParams = formParams.append('periodoRicercaA', <any>periodoRicercaA) as any || formParams;
        }
        if (annoAccademico !== undefined) {
            formParams = formParams.append('annoAccademico', <any>annoAccademico) as any || formParams;
        }
        if (nomeStrutturaEstera !== undefined) {
            formParams = formParams.append('nomeStrutturaEstera', <any>nomeStrutturaEstera) as any || formParams;
        }
        if (codiceNazioneStrutturaEstera !== undefined) {
            formParams = formParams.append('codiceNazioneStrutturaEstera', <any>codiceNazioneStrutturaEstera) as any || formParams;
        }
        if (hasDataFineNull !== undefined) {
            formParams = formParams.append('hasDataFineNull', <any>hasDataFineNull) as any || formParams;
        }
        if (codiceTipologiaMobilita !== undefined) {
            formParams = formParams.append('codiceTipologiaMobilita', <any>codiceTipologiaMobilita) as any || formParams;
        }
        if (numeroTipologiaBonus !== undefined) {
            formParams = formParams.append('numeroTipologiaBonus', <any>numeroTipologiaBonus) as any || formParams;
        }
        if (codiceTipoInterventoSupporto !== undefined) {
            formParams = formParams.append('codiceTipoInterventoSupporto', <any>codiceTipoInterventoSupporto) as any || formParams;
        }
        if (dataUltimaModificaAfter !== undefined) {
            formParams = formParams.append('dataUltimaModificaAfter', <any>dataUltimaModificaAfter) as any || formParams;
        }
        if (statiRichiesteDiRetribuzioneMobilita) {
            statiRichiesteDiRetribuzioneMobilita.forEach((element) => {
                formParams = formParams.append('statiRichiesteDiRetribuzioneMobilita', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/mobilita/export-pdf`,
            {
             responseType: "blob" as "json",
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista delle strutture estere non codificate eventualmente filtrate per nazione.
     * 
     * @param codiceNazione 
     * @param citta 
     * @param descrizione 
     * @param isVerificata 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStruttureEstereNonCodificateForm(codiceNazione?: string, citta?: string, descrizione?: string, isVerificata?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<StrutturaEsteraNonCodificataInfoViewImpl>>;
    public getStruttureEstereNonCodificateForm(codiceNazione?: string, citta?: string, descrizione?: string, isVerificata?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StrutturaEsteraNonCodificataInfoViewImpl>>>;
    public getStruttureEstereNonCodificateForm(codiceNazione?: string, citta?: string, descrizione?: string, isVerificata?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StrutturaEsteraNonCodificataInfoViewImpl>>>;
    public getStruttureEstereNonCodificateForm(codiceNazione?: string, citta?: string, descrizione?: string, isVerificata?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (codiceNazione !== undefined) {
            formParams = formParams.append('codiceNazione', <any>codiceNazione) as any || formParams;
        }
        if (citta !== undefined) {
            formParams = formParams.append('citta', <any>citta) as any || formParams;
        }
        if (descrizione !== undefined) {
            formParams = formParams.append('descrizione', <any>descrizione) as any || formParams;
        }
        if (isVerificata !== undefined) {
            formParams = formParams.append('isVerificata', <any>isVerificata) as any || formParams;
        }

        return this.httpClient.request<Array<StrutturaEsteraNonCodificataInfoViewImpl>>('post',`${this.basePath}/api/v1/mobilita/strutture-estere-non-codificate`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricercare i decreti di pagamento dei periodi di mobilità con i filtri applicati.
     * 
     * @param nominativoStudente 
     * @param codiceFiscaleStudente 
     * @param matricolaStudente 
     * @param codiceDecreto 
     * @param data 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDecretoPagamentoPeriodiDiMobilitaForm(nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, codiceDecreto?: string, data?: string, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageDecretoPagamentoPeriodiDiMobilitaInfoView>;
    public searchDecretoPagamentoPeriodiDiMobilitaForm(nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, codiceDecreto?: string, data?: string, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDecretoPagamentoPeriodiDiMobilitaInfoView>>;
    public searchDecretoPagamentoPeriodiDiMobilitaForm(nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, codiceDecreto?: string, data?: string, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDecretoPagamentoPeriodiDiMobilitaInfoView>>;
    public searchDecretoPagamentoPeriodiDiMobilitaForm(nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, codiceDecreto?: string, data?: string, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (codiceFiscaleStudente !== undefined) {
            formParams = formParams.append('codiceFiscaleStudente', <any>codiceFiscaleStudente) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (codiceDecreto !== undefined) {
            formParams = formParams.append('codiceDecreto', <any>codiceDecreto) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }

        return this.httpClient.request<PageDecretoPagamentoPeriodiDiMobilitaInfoView>('post',`${this.basePath}/api/v1/mobilita/decreti-di-pagamento/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricercare le delibere di programmazione mobilità con i filtri applicati.
     * 
     * @param nominativoStudente 
     * @param codiceFiscaleStudente 
     * @param matricolaStudente 
     * @param numeroDelibera 
     * @param dataDelibera 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchDelibereDiProgrammazioneMobilitaForm(nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, numeroDelibera?: string, dataDelibera?: string, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageDeliberaDiProgrammazioneMobilitaFiltered>;
    public searchDelibereDiProgrammazioneMobilitaForm(nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, numeroDelibera?: string, dataDelibera?: string, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageDeliberaDiProgrammazioneMobilitaFiltered>>;
    public searchDelibereDiProgrammazioneMobilitaForm(nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, numeroDelibera?: string, dataDelibera?: string, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageDeliberaDiProgrammazioneMobilitaFiltered>>;
    public searchDelibereDiProgrammazioneMobilitaForm(nominativoStudente?: string, codiceFiscaleStudente?: string, matricolaStudente?: string, numeroDelibera?: string, dataDelibera?: string, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (codiceFiscaleStudente !== undefined) {
            formParams = formParams.append('codiceFiscaleStudente', <any>codiceFiscaleStudente) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (numeroDelibera !== undefined) {
            formParams = formParams.append('numeroDelibera', <any>numeroDelibera) as any || formParams;
        }
        if (dataDelibera !== undefined) {
            formParams = formParams.append('dataDelibera', <any>dataDelibera) as any || formParams;
        }

        return this.httpClient.request<PageDeliberaDiProgrammazioneMobilitaFiltered>('post',`${this.basePath}/api/v1/mobilita/delibera-di-programmazione/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare una delibera di programmazione mobilità.
     * 
     * @param body 
     * @param idDeliberaDiProgrammazioneMobilita 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeliberaDiProgrammazioneMobilita(body: UpdateDeliberaDiProgrammazioneMobilitaDTO, idDeliberaDiProgrammazioneMobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DeliberaDiProgrammazioneMobilitaInfoView>>;
    public updateDeliberaDiProgrammazioneMobilita(body: UpdateDeliberaDiProgrammazioneMobilitaDTO, idDeliberaDiProgrammazioneMobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DeliberaDiProgrammazioneMobilitaInfoView>>>;
    public updateDeliberaDiProgrammazioneMobilita(body: UpdateDeliberaDiProgrammazioneMobilitaDTO, idDeliberaDiProgrammazioneMobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DeliberaDiProgrammazioneMobilitaInfoView>>>;
    public updateDeliberaDiProgrammazioneMobilita(body: UpdateDeliberaDiProgrammazioneMobilitaDTO, idDeliberaDiProgrammazioneMobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDeliberaDiProgrammazioneMobilita.');
        }

        if (idDeliberaDiProgrammazioneMobilita === null || idDeliberaDiProgrammazioneMobilita === undefined) {
            throw new Error('Required parameter idDeliberaDiProgrammazioneMobilita was null or undefined when calling updateDeliberaDiProgrammazioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<DeliberaDiProgrammazioneMobilitaInfoView>>('put',`${this.basePath}/api/v1/mobilita/delibera-di-programmazione/${encodeURIComponent(String(idDeliberaDiProgrammazioneMobilita))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

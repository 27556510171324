<ng-container *transloco="let t">

    <div class="h-full w-full" fxLayout="column">
        <!--HEADER-->
        <app-header [title]="'common.information'">
            <!-- SELECT SOTTORUOLO -->
            <ng-container *ngIf="!loading && showSelect">
                <div
                    class="flex flex-col xl:flex-row gap-3 xl:gap-5 xl:items-center md:items-end items-center justify-center md:justify-end">
                    <fuse-alert *ngIf="ctrlSelectFormControl.value && sottoruoli.length > 1"
                                class="w-full"
                                [showIcon]="true"
                                [appearance]="'outline'"
                    >{{ t('students_list.modify_subrole') }}
                    </fuse-alert>
                    <mat-form-field style="width:50%; min-width: 300px; max-width: 500px" appearance="outline"
                                    class="mat-form-field-select-sottoruolo">
                        <mat-label>{{ t('students_list.subrole') }}</mat-label>
                        <mat-select [formControl]="ctrlSelectFormControl">
                            <mat-option *ngFor="let sottoruolo of sottoruoli" [value]="sottoruolo">
                                {{ sottoruolo | removeUnderscores }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
            <ng-container *ngIf="appInitService.isAreaAdministrator">
                <div
                    class="flex flex-col xl:flex-row gap-3 xl:gap-5 xl:items-center md:items-end items-center justify-center md:justify-end">
                    <fuse-alert *ngIf="ctrlCorso.value && corsi.length > 1"
                                class="w-full"
                                [showIcon]="true"
                                [appearance]="'outline'"
                    >{{ t('administration.modify_course') }}
                    </fuse-alert>
                    <mat-form-field style="width:50%; min-width: 300px; max-width: 500px"
                                    [ngClass]="{'mat-form-field-border-red': appInitService.isAreaAdministrator && !ctrlCorso.value}"
                                    appearance="outline" class="mat-form-field-select-sottoruolo">
                        <mat-label>{{ t('common.course') }}</mat-label>
                        <mat-select [disabled]="loading"
                                    (valueChange)="setCurrentCorso($event)"
                                    [formControl]="ctrlCorso">
                            <mat-option *ngFor="let corso of corsi" [value]="corso.codiceEsse3">
                                {{ (corso.codiceEsse3 || '') + ((corso.cicliCorsiDiStudi[0]?.denominazione) ? ' - ' : '') + (corso.cicliCorsiDiStudi[0]?.denominazione || '') }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </app-header>

        <app-loading-screen *ngIf="loading || loadingInfoRequest; else syncTemplate"
                            class="w-full h-full">
        </app-loading-screen>

        <ng-template #syncTemplate>
            <ng-container *ngIf="appInitService.isAreaAdministrator && !ctrlCorso.value; else infoTmp">
                <fuse-alert
                    class="mt-2 w-full"
                            [showIcon]="true"
                            [appearance]="'outline'"
                >{{ t('administration.corse_not_selected') }}
                </fuse-alert>
            </ng-container>
            <ng-template #infoTmp>
                <div class="w-full" fxLayout="column" fxLayoutGap="10px">
                    <div style="margin: 5px !important;"
                         class="flex flex-col flex-auto bg-card shadow overflow-hidden w-full justify-start items-center py-1">

                        <!-- INFORMAZIONI CICLO -->
                        <div class="flex flex-col" style="width: 97%;">
                            <!-- INTESTAZIONE COLLEGIO DOCENTI-->

                            <div class="grid grid-cols-1">
                                <app-input-value-key [label]="'cycle_doctorate.coordinator'"
                                                     [pipe]="UpperCasePipe"
                                                     [hideValue]="true"
                                                     [isFlexBasis12_5]="true"
                                                     [ngContentFlexRow]="true">
                                    <mat-chip-list>
                                        <mat-chip color="primary"
                                                  class="justify-between cursor-pointer"
                                                  (click)="openInfoMember(coordinator)">
                                        <span class="line-clamp-1">
                                            {{ completeNameCoordinator | uppercase }}
                                        </span>
                                            <mat-icon class="ml-2">info</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                    <div
                                        class="flex flex-row gap-4 mt-2 lg:ml-2 lg:mt-0 flex-wrap items-center justify-center">
                                        <button
                                            mat-flat-button
                                            class="multi-line-button"
                                            color="accent"
                                            [matTooltip]="t('cycle_doctorate.show_history')"
                                            (click)="openHistoryDialog(storiciCoordinatori, false, 'coordinators')">
                                            <mat-icon class="icon-size-4 mr-2">history</mat-icon>
                                            {{ t('cycle_doctorate.show_history') }}
                                        </button>
                                        <div *ngIf="!isReadOnly && showModifyCoordinatorCollegioDocenti()">
                                            <button
                                                mat-flat-button
                                                class="multi-line-button"
                                                color="primary"
                                                (click)="confirmModifyCoordinatorCollegioDocenti()">
                                                <mat-icon class="icon-size-4 mr-2">edit</mat-icon>
                                                {{ t('cycle_doctorate.modify_coordinator') }}
                                            </button>
                                        </div>
                                    </div>
                                </app-input-value-key>

                                <!--<div class="flex flex-col lg:flex-row border-b py-5 gap-2 h-full items-center">
                                    <div class="text-lg text-center lg:text-start font-medium break-words basis-1_8">{{t('cycle_doctorate.coordinator')}}</div>
                                    <mat-chip-list>
                                        <mat-chip color="primary"
                                                  style="width:93%"
                                                  class="justify-between cursor-pointer"
                                                  (click)="openInfoMember(coordinator)">
                                        <span class="line-clamp-1">
                                            {{completeNameCoordinator | uppercase}}
                                        </span>
                                            <mat-icon>info</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>-->


                            </div>
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-4 mb-4">

                                <app-input-value-key [label]="'common.phd_cicle'"
                                                     [value]="nCiclo">
                                </app-input-value-key>

                                <app-input-value-key [label]="'common.department'"
                                                     [value]="department">
                                </app-input-value-key>

                                <app-input-value-key [label]="'student.course_code'"
                                                     [value]="codiceCorso">
                                </app-input-value-key>

                                <app-input-value-key [label]="'student.course_name'"
                                                     [value]="titleCycle">
                                </app-input-value-key>

                                <app-input-value-key [label]="'cycle_doctorate.start_year'"
                                                     [value]="startYear">
                                </app-input-value-key>

                                <app-input-value-key [label]="'cycle_doctorate.end_year'"
                                                     [value]="endYear">
                                </app-input-value-key>


                            </div>
                        </div>
                    </div>

                    <div style="margin: 5px !important; padding-top: 20px;"
                         class="flex flex-col flex-auto bg-card shadow overflow-hidden w-full justify-start items-center">
                        <!-- TAB GROUP -->
                        <mat-tab-group class="w-full mat-tab-group-container-no-padding px-5" #tabsGroup>

                            <!-- TAB COLLEGIO DOCENTI-->
                            <mat-tab [label]="t('cycle_doctorate.college_member') | titlecase"
                                     [aria-label]="CycleInfoFragments.COLLEGIODOCENTI"
                                     class="overflow-x-hidden">
                                <app-collegio-docenti-group
                                    [showHistoryShowButton]="showHistoryShowButton()"
                                    [isReadOnly]="isReadOnly"
                                    [showModifyCollegioDocenti]="showModifyCollegioDocenti()"
                                    [storiciCollegio]="storiciCollegio"
                                    [listaMembers]="listaMembers"
                                    (confirmModifyCoollegioDocenti)="confirmModifyCoollegioDocenti($event)"
                                    (openInfoMember)="openInfoMember($event)"
                                    (openHistoryDialog)="openHistoryDialog(storiciCollegio, true, 'professors_board_' + $event.historyLabel, $event.tipoCollegio)"
                                    (replaceNanuallyAddedTeacher)="replaceNanuallyAddedTeacherInCollegio($event)">
                                </app-collegio-docenti-group>
                            </mat-tab>

                            <!-- TAB COMMISSIONE DI AUTOVALUTAZIONE-->
                            <mat-tab [label]="t('cycle_doctorate.self_assessment_commission') | titlecase"
                                     [aria-label]="CycleInfoFragments.COMMISSIONE_DI_AUTOVALUTAZIONE"
                                     class="overflow-x-hidden">
                                <app-comm-autoval-group
                                    [showHistoryShowButton]="showHistoryShowButton()"
                                    [isReadOnly]="isReadOnly"
                                    [showModifyCommissioneDiAutovalutazione]="showModifyCommissioneDiAutovalutazione()"
                                    [storiciCommessioneDiAutovalutazione]="storiciCommessioneDiAutovalutazione"
                                    [membriCommissioneDiAutovalutazione]="membriCommissioneDiAutovalutazione"
                                    (confirmModifyCommissioneDiAutovalutazione)="confirmModifyCommissioneDiAutovalutazione($event)"
                                    (openInfoMember)="openInfoMember($event)"
                                    (openHistoryDialog)="openHistoryDialog(storiciCommessioneDiAutovalutazione, true, 'self_assessment_commission_' + $event.historyLabel, $event.tipoMembro)">
                                </app-comm-autoval-group>
                            </mat-tab>

                            <!-- TAB DIRETTORE DI DIPARTIMENTO-->
                            <mat-tab [label]="t('cycle_doctorate.department_director') | titlecase"
                                     [aria-label]="CycleInfoFragments.DIRETTOREDIPERATIMENTO"
                                     class="overflow-x-hidden">
                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full justify-center items-center">

                                    <fuse-alert
                                        *ngIf="currentSottoruolo === AuthorityType.COORDINATORE"
                                        class="w-full mb-3"
                                        [showIcon]="true"
                                        [appearance]="'outline'"
                                        [type]="'info'">
                                        {{ t('cycle_doctorate.department_director_edit_roles_info') }}
                                    </fuse-alert>

                                    <div class="mt-0.5 flex flex-col lg:flex-row items-center justify-end gap-4"
                                         style="width: 100%;">

                                        <div *ngIf="showHistoryShowButton()">
                                            <button
                                                mat-flat-button
                                                class="multi-line-button"
                                                color="accent"
                                                [matTooltip]="t('cycle_doctorate.show_history')"
                                                (click)="openHistoryDialog(storiciDirettoriDipartimento, true, 'department_director')">
                                                <mat-icon class="icon-size-4 mr-2">history</mat-icon>
                                                {{ t('cycle_doctorate.show_history') }}
                                            </button>
                                        </div>
                                        <div *ngIf="!isReadOnly && showModifyDirettoreDipartimento()">
                                            <button
                                                mat-flat-button
                                                class="multi-line-button"
                                                color="primary"
                                                (click)="openModififyDirettoreDipartimentoDialog()">
                                                <mat-icon class="icon-size-4 mr-2">edit</mat-icon>
                                                {{ t('common.edit') }}
                                            </button>
                                        </div>

                                    </div>

                                    <div class="flex flex-col w-full p-6 ">
                                        <ng-container
                                            *ngIf="!!listaDirettoriDipartimento && listaDirettoriDipartimento.length > 0; else noDepartmentDirector">
                                            <mat-chip-list>
                                                <div
                                                    class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full">
                                                    <ng-container *ngFor="let direttore of sortList(listaDirettoriDipartimento)">
                                                        <div class="flex flex-col w-full items-center">
                                                            <mat-chip color="primary" style="width:93%"
                                                                      class="justify-between cursor-pointer"
                                                                      [ngClass]="!direttore?.docenteNonTrovato ? 'cursor-pointer' : 'pointer-events-none'"
                                                                      (click)="openInfoMember(direttore)">
                                                            <span
                                                                class="line-clamp-1">{{ buildNomeCompleto(direttore) | uppercase }}</span>
                                                                <div class="flex items-center justify-end cursor-pointer">
                                                                    <mat-icon *ngIf="!direttore?.docenteNonTrovato">info
                                                                    </mat-icon>
                                                                </div>
                                                            </mat-chip>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </mat-chip-list>
                                        </ng-container>

                                        <ng-template #noDepartmentDirector>
                                            <div class="mt-4 text-lg font-medium">
                                                <fuse-alert
                                                    class="w-full"
                                                    [showIcon]="true"
                                                    [appearance]="'outline'"
                                                    [type]="'warning'"
                                                >{{ t('cycle_doctorate.no_department_director') }}
                                                </fuse-alert>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </mat-tab>

                            <!-- TAB ESTERNI-->
<!--
                            <mat-tab [label]="t('cycle_doctorate.extern') | titlecase"
                                     *ngIf="currentSottoruolo === AuthorityType.COORDINATORE || currentSottoruolo === AuthorityType.GRUPPODICOORDINAMENTO"
                                     [aria-label]="CycleInfoFragments.EXTERN"
                                     class="overflow-x-hidden">
                                <ng-template matTabContent>

                                    <fuse-alert class="w-full mb-4"
                                                [showIcon]="true"
                                                [appearance]="'outline'">
                                        {{ t('cycle_doctorate.esterni.info_esterni') }}
                                    </fuse-alert>

                                    &lt;!&ndash; ESTERNI &ndash;&gt;
                                    <div class="flex flex-col w-full p-6 ">
                                        <ng-container *ngIf="!!listaEsterni && listaEsterni.length > 0; else noEsterni">
                                            &lt;!&ndash; CHIPS &ndash;&gt;
                                            <mat-chip-list>

                                                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full">
                                                    <ng-container *ngFor="let esterno of sortList(listaEsterni)">
                                                        <div class="flex flex-col w-full items-center">
                                                            <mat-chip color="primary" style="width:93%"
                                                                      class="justify-between"
                                                                      [ngClass]="{'cursor-pointer': esterno?.codiceFiscale || esterno?.mail}"
                                                                      (click)="openInfoEsterno(esterno)">
                                                            <span
                                                                class="line-clamp-1">{{ buildNomeCompletoEsterno(esterno) | uppercase }}</span>
                                                                <mat-icon *ngIf="esterno?.codiceFiscale || esterno?.mail">
                                                                    info
                                                                </mat-icon>
                                                            </mat-chip>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </mat-chip-list>
                                        </ng-container>

                                        <ng-template #noEsterni>
                                            <div class="mt-4 text-lg font-medium">
                                                <fuse-alert
                                                    class="w-full"
                                                    [showIcon]="true"
                                                    [appearance]="'outline'"
                                                    [type]="'warning'"
                                                >{{ t('cycle_doctorate.no_members_extern') }}
                                                </fuse-alert>
                                            </div>
                                        </ng-template>
                                    </div>

                                    &lt;!&ndash;<app-inviti-esterni>

                                    </app-inviti-esterni>&ndash;&gt;

                                </ng-template>
                            </mat-tab>
-->

                            <!-- TAB GRUPPO COORDINAMENTO-->
                            <mat-tab [label]="t('cycle_doctorate.coordinators') | titlecase"
                                     [aria-label]="CycleInfoFragments.GRUPPOCOORDINAMENTO"
                                     class="overflow-x-hidden">
                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full justify-center items-center">

                                    <!-- INTESTAZIONE GRUPPO COORDINAMENTO-->
                                    <div class="mt-0.5 flex flex-col lg:flex-row items-center justify-end gap-4"
                                         style="width: 100%;">

                                        <div *ngIf="showHistoryShowButton()">
                                            <button
                                                mat-flat-button
                                                color="accent"
                                                [matTooltip]="t('cycle_doctorate.show_history')"
                                                (click)="openHistoryDialog(storiciGruppoCoordinamento, true,'coordination_group')">
                                                <mat-icon class="icon-size-4 mr-2">history</mat-icon>
                                                {{ t('cycle_doctorate.show_history') }}
                                            </button>
                                        </div>
                                        <div *ngIf="!isReadOnly && showModifyCoordinators()">
                                            <button
                                                mat-flat-button
                                                color="primary"
                                                (click)="confirmModifyCoordinators()">
                                                <mat-icon class="icon-size-4 mr-2">edit</mat-icon>
                                                {{ t('common.edit') }}
                                            </button>
                                        </div>

                                    </div>

                                    <!-- GRUPPO DI COORDINAMENTO -->
                                    <div class="flex flex-col w-full p-6">
                                        <!-- CHIPS -->
                                        <ng-container
                                            *ngIf="!!listaCoordinatori && listaCoordinatori.length > 0; else noCoordinators">
                                            <mat-chip-list>
                                                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full">
                                                    <ng-container
                                                        *ngFor="let coordinator of sortList(listaCoordinatori)">
                                                        <div class="flex flex-col w-full items-center">
                                                            <mat-chip color="primary" style="width:93%"
                                                                      class="justify-between cursor-pointer"
                                                                      (click)="openInfoMember(coordinator)">
                                                            <span
                                                                class="line-clamp-1">{{ buildNomeCompleto(coordinator) | uppercase }}</span>
                                                                <mat-icon>info</mat-icon>
                                                            </mat-chip>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </mat-chip-list>
                                        </ng-container>

                                        <ng-template #noCoordinators>
                                            <div class="mt-4 text-lg font-medium">
                                                <fuse-alert
                                                    class="w-full"
                                                    [showIcon]="true"
                                                    [appearance]="'outline'"
                                                    [type]="'warning'"
                                                >{{ t('cycle_doctorate.no_members_coordinators') }}
                                                </fuse-alert>
                                            </div>
                                        </ng-template>
                                    </div>

                                </div>
                            </mat-tab>

                            <!-- TAB GRUPPO PTA-->
                            <mat-tab [label]="t('cycle_doctorate.PTA_group')"
                                     [aria-label]="CycleInfoFragments.GRUPPOPTA"
                                     class="overflow-x-hidden">

                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full justify-center items-center">

                                    <!-- INTESTAZIONE GRUPPO PTA-->
                                    <div class="mt-0.5 flex flex-col lg:flex-row items-center justify-end gap-4"
                                         style="width: 100%;">

                                        <div *ngIf="showHistoryShowButton()">
                                            <button
                                                mat-flat-button
                                                color="accent"
                                                [matTooltip]="t('cycle_doctorate.show_history')"
                                                (click)="openHistoryDialog(storiciPTA, true, 'pta')">
                                                <mat-icon class="icon-size-4 mr-2">history</mat-icon>
                                                {{ t('cycle_doctorate.show_history') }}
                                            </button>
                                        </div>

                                        <div *ngIf="!isReadOnly && showModifyGruppoPTA()">
                                            <button
                                                mat-flat-button
                                                color="primary"
                                                (click)="confirmModifyGruppoPTA()">
                                                <mat-icon class="icon-size-4 mr-2">edit</mat-icon>
                                                {{ t('common.edit') }}
                                            </button>
                                        </div>

                                    </div>

                                    <!-- GRUPPO PTA -->
                                    <div class="flex flex-col w-full p-6">
                                        <!-- CHIPS -->
                                        <ng-container
                                            *ngIf="!!listaPTA && listaPTA.length > 0; else noPTAGroup">
                                            <mat-chip-list>
                                                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full">
                                                    <ng-container
                                                        *ngFor="let pta of sortList(listaPTA)">
                                                        <div class="flex flex-col w-full items-center">
                                                            <mat-chip color="primary" style="width:93%"
                                                                      class="justify-between cursor-pointer"
                                                                      (click)="openInfoMember(pta)">
                                                            <span
                                                                class="line-clamp-1">{{ buildNomeCompleto(pta) | uppercase }}</span>
                                                                <mat-icon>info</mat-icon>
                                                            </mat-chip>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </mat-chip-list>
                                        </ng-container>

                                        <ng-template #noPTAGroup>
                                            <div class="mt-4 text-lg font-medium">
                                                <fuse-alert
                                                    class="w-full"
                                                    [showIcon]="true"
                                                    [appearance]="'outline'"
                                                    [type]="'warning'"
                                                >{{ t('cycle_doctorate.no_members_PTA_group') }}
                                                </fuse-alert>
                                            </div>
                                        </ng-template>
                                    </div>

                                </div>
                            </mat-tab>

                            <!-- TAB SEGRETARIO AMMINISTRATIVO DIPARTIMENTO-->
                            <mat-tab [label]="t('cycle_doctorate.department_administrative_secretary') | titlecase"
                                     [aria-label]="CycleInfoFragments.SAD"
                                     class="overflow-x-hidden">
                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full justify-center items-center">

                                    <fuse-alert
                                        *ngIf="currentSottoruolo === AuthorityType.COORDINATORE"
                                        class="w-full mb-3"
                                        [showIcon]="true"
                                        [appearance]="'outline'"
                                        [type]="'info'">
                                        {{ t('cycle_doctorate.SAD_edit_roles_info') }}
                                    </fuse-alert>

                                    <div class="mt-0.5 flex flex-col lg:flex-row items-center justify-end gap-4"
                                         style="width: 100%;">

                                        <div *ngIf="showHistoryShowButton()">
                                            <button
                                                mat-flat-button
                                                class="multi-line-button"
                                                color="accent"
                                                [matTooltip]="t('cycle_doctorate.show_history')"
                                                (click)="openHistoryDialog(storiciSAD, true, 'department_administrative_secretary')">
                                                <mat-icon class="icon-size-4 mr-2">history</mat-icon>
                                                {{ t('cycle_doctorate.show_history') }}
                                            </button>
                                        </div>
                                        <div *ngIf="!isReadOnly && showModifySAD()">
                                            <button
                                                mat-flat-button
                                                class="multi-line-button"
                                                color="primary"
                                                (click)="openModififySADDialog()">
                                                <mat-icon class="icon-size-4 mr-2">edit</mat-icon>
                                                {{ t('common.edit') }}
                                            </button>
                                        </div>

                                    </div>

                                    <div class="flex flex-col w-full p-6 ">
                                        <ng-container
                                            *ngIf="!!listaSAD && listaSAD.length > 0; else noSAD">
                                            <mat-chip-list>
                                                <div
                                                    class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 w-full">
                                                    <ng-container *ngFor="let SAD of sortList(listaSAD)">
                                                        <div class="flex flex-col w-full items-center">
                                                            <mat-chip color="primary" style="width:93%"
                                                                      class="justify-between cursor-pointer"
                                                                      [ngClass]="!SAD?.docenteNonTrovato ? 'cursor-pointer' : 'pointer-events-none'"
                                                                      (click)="openInfoMember(SAD)">
                                                            <span
                                                                class="line-clamp-1">{{ buildNomeCompleto(SAD) | uppercase }}</span>
                                                                <div class="flex items-center justify-end cursor-pointer">
                                                                    <mat-icon *ngIf="!SAD?.docenteNonTrovato">info
                                                                    </mat-icon>
                                                                </div>
                                                            </mat-chip>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </mat-chip-list>
                                        </ng-container>

                                        <ng-template #noSAD>
                                            <div class="mt-4 text-lg font-medium">
                                                <fuse-alert
                                                    class="w-full"
                                                    [showIcon]="true"
                                                    [appearance]="'outline'"
                                                    [type]="'warning'"
                                                >{{ t('cycle_doctorate.no_SAD') }}
                                                </fuse-alert>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </mat-tab>

                            <!-- TAB AMMINISTRATORI-->
                            <mat-tab [label]="t('cycle_doctorate.admins') | titlecase"
                                     [aria-label]="CycleInfoFragments.ADMINS"
                                     class="overflow-x-hidden">
                                <div
                                    class="flex flex-col flex-auto overflow-hidden w-full justify-center items-center">

                                    <!-- INTESTAZIONE AMMINISTRATORI-->
                                    <div class="mt-0.5 flex flex-col lg:flex-row items-center justify-end"
                                         style="width: 100%;">

                                        <fuse-alert
                                            *ngIf="currentSottoruolo === AuthorityType.COORDINATORE"
                                            class="w-full mb-3"
                                            [showIcon]="true"
                                            [appearance]="'outline'"
                                            [type]="'info'">
                                            {{ t('cycle_doctorate.admin_edit_roles_info') }}
                                        </fuse-alert>

                                        <!-- MODIFICA AMMINISTRATORI -->
                                        <div *ngIf="!isReadOnly && showModifyAdmins()">
                                            <button
                                                mat-flat-button
                                                color="primary"
                                                (click)="confirmModifyAdmins()">
                                                <mat-icon class="icon-size-4 mr-2">edit</mat-icon>
                                                {{ t('common.edit') }}
                                            </button>
                                        </div>
                                    </div>


                                    <!-- AMMINISTRATORI -->
                                    <div class="flex flex-col w-full p-6 ">
                                        <ng-container *ngIf="!!listaAdmin && listaAdmin.length > 0; else noAdmins">
                                            <!-- CHIPS -->
                                            <mat-chip-list>

                                                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full">
                                                    <ng-container *ngFor="let admin of sortList(listaAdmin)">
                                                        <div class="flex flex-col w-full items-center">
                                                            <mat-chip color="primary" style="width:93%"
                                                                      class="justify-between cursor-pointer"
                                                                      (click)="openInfoMember(admin)">
                                                            <span
                                                                class="line-clamp-1">{{ buildNomeCompletoRuoloUtente(admin) | uppercase }}</span>
                                                                <mat-icon>info</mat-icon>
                                                            </mat-chip>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </mat-chip-list>
                                        </ng-container>

                                        <ng-template #noAdmins>
                                            <div class="mt-4 text-lg font-medium">
                                                <fuse-alert
                                                    class="w-full"
                                                    [showIcon]="true"
                                                    [appearance]="'outline'"
                                                    [type]="'warning'"
                                                >{{ t('cycle_doctorate.no_members_admins') }}
                                                </fuse-alert>

                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </mat-tab>

                        </mat-tab-group>
                    </div>
                </div>
            </ng-template>

        </ng-template>

    </div>

</ng-container>

<ng-container *transloco="let t">

    <mat-drawer-container class="flex-auto h-full" style="overflow: hidden">
        <!-- Drawer -->
        <mat-drawer #drawer style="overflow: hidden"
                    class="w-72 sm:w-180 dark:bg-gray-900 my-mat-drawer"
                    mode='over'
                    [opened]="drawerOpened"
                    [autoFocus]="false">

            <app-delibere-sidebar-filter-container #sidebarContainer
                                                   style="overflow: hidden"
                                                   (closeDrawer)="closeDrawer()">
            </app-delibere-sidebar-filter-container>


        </mat-drawer>

        <!-- Drawer content -->
        <mat-drawer-content class="flex flex-col overflow-hidden">


            <div class="h-full w-full" fxLayout="column">
                <!--HEADER-->
                <app-header [title]="'documents_dashboard.documents_dashboard'">

                    <!-- SELECT SOTTORUOLO -->
                    <ng-container *ngIf="!loading && mustChooseSubrole">
                        <div
                            class="flex flex-col xl:flex-row gap-3 xl:gap-5 xl:items-center md:items-end items-center justify-center md:justify-end">
                            <fuse-alert *ngIf="ctrlSelectFormControl.value && sottoruoli.length > 1"
                                        class="w-full"
                                        [showIcon]="true"
                                        [appearance]="'outline'"
                            >{{ t('students_list.modify_subrole') }}
                            </fuse-alert>
                            <mat-form-field style="width:50%; min-width: 300px; max-width: 500px" appearance="outline"
                                            [ngClass]="{'mat-form-field-border-red': mustChooseSubrole && !isSubroleSelected}"
                                            class="mat-form-field-select-sottoruolo">
                                <mat-label>{{ t('students_list.subrole') }}</mat-label>
                                <mat-select [formControl]="ctrlSelectFormControl">
                                    <mat-option *ngFor="let sottoruolo of sottoruoli" [value]="sottoruolo">
                                        {{ sottoruolo | removeUnderscores }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </ng-container>

                </app-header>

                <app-loading-screen *ngIf="loading; else contentTemplate" class="w-full h-full"></app-loading-screen>
                <ng-template #contentTemplate>

                    <!-- CONTENT -->
                    <div class="h-full w-full"
                         fxLayout="column"
                         fxLayoutGap="10px"
                         *ngIf="!mustChooseSubrole || (isSubroleSelected && currentSubroleCanAccess)">

                        <div style="padding: 20px 10px 10px 10px"
                             class="flex flex-col flex-auto bg-card shadow overflow-hidden w-full justify-start items-center gap-5"
                             *ngIf="!!delibereListTableConfiguration">

                            <mat-tab-group class="w-full" #tabsGroup appScrollRetainer>

                                <mat-tab [label]="t('documents_dashboard.approval')"
                                         aria-label="delibere">

                                    <app-loading-screen *ngIf="loading; else gestioneDocumentiTemplate" class="w-full h-full">
                                    </app-loading-screen>
                                    <ng-template #gestioneDocumentiTemplate>
                                        <div class="flex flex-col w-full">
                                            <div class="items-end w-full flex  flex-col sm:flex-row gap-3 justify-end">
                                                <ng-container *ngIf="appInitService.isAreaAdministrator">
                                                    <div
                                                        class="flex flex-col xl:flex-row gap-3 xl:gap-5 xl:items-center md:items-end items-center justify-center md:justify-end">
                                                        <mat-form-field style="width:50%; min-width: 300px; max-width: 500px"
                                                                        appearance="outline" class="mat-form-field-select-sottoruolo">
                                                            <mat-label>{{ t('common.course') }}</mat-label>

                                                            <mat-select [disabled]="loading"
                                                                        (valueChange)="setCurrentCorso($event)"
                                                                        [formControl]="ctrlCorso">
                                                                <mat-option [value]="allCourse">
                                                                    {{t('common.all_courses') | uppercase }}
                                                                </mat-option>
                                                                <mat-option *ngFor="let corso of corsi" [value]="corso.codiceEsse3">
                                                                    {{ (corso.codiceEsse3 || '') + ((corso.cicliCorsiDiStudi[0]?.denominazione) ? ' - ' : '') + (corso.cicliCorsiDiStudi[0]?.denominazione || '') }}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </ng-container>

                                                <button mat-flat-button
                                                        class="multi-line-button"
                                                        type="submit"
                                                        (click)="navigateToAssociazioneDelibere()"
                                                        *ngIf="!isReadOnly && (ctrlSelectFormControl?.value === AuthorityType.GRUPPOPTA || ctrlSelectFormControl?.value === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO)"
                                                        [color]="'primary'">
                                                    <mat-icon class="mr-2">grid_view</mat-icon>
                                                    <span>{{ t('sidebar.operazioni_massive_load_delibera') }}</span>
                                                </button>

                                                <button mat-flat-button
                                                        class="multi-line-button"
                                                        type="submit"
                                                        (click)="openAddEditDeliberaDialog()"
                                                        *ngIf="!isReadOnly && (ctrlSelectFormControl?.value === AuthorityType.COORDINATORE || ctrlSelectFormControl?.value === AuthorityType.GRUPPOPTA || ctrlSelectFormControl?.value === AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO)"
                                                        [color]="'primary'">
                                                    <mat-icon class="mr-2">add</mat-icon>
                                                    <span>{{ t('documents_dashboard.add_approval') }}</span>
                                                </button>
                                            </div>


                                            <app-generic-table class="w-full"
                                                               *ngIf="!!delibereListTableConfiguration"
                                                               [configuration]="delibereListTableConfiguration"
                                                               (clickAction)="tableClickAction($event)"
                                                               (pageAction)="pageAction($event)">
                                            </app-generic-table>

                                        </div>

                                    </ng-template>


                                </mat-tab>

                                <mat-tab *ngIf="!appInitService.isAreaAdministrator && currentSottoruolo === AuthorityType.COORDINATORE"
                                         [label]="t('documents_dashboard.delibere_programmazione_mob_ricerca')"
                                         aria-label="delibere-programmazione-mobilita-ricerca">
                                    <app-delibere-programmazione-mobilita-ricerca
                                        *ngIf="currentRuolo"
                                        (toggledFilterContainer)="toggleFilterContainer($event)"
                                        (resettedFilters)="resetFilters()"
                                    ></app-delibere-programmazione-mobilita-ricerca>
                                </mat-tab>

                                <mat-tab [label]="t('documents_dashboard.template_delibere')"
                                         aria-label="delibere">
                                    <app-template-delibere
                                        *ngIf="currentRuolo"
                                        [currentSottoRuolo]="ctrlSelectFormControl.value"
                                        [currentRuolo]="currentRuolo"
                                    ></app-template-delibere>
                                </mat-tab>

                            </mat-tab-group>

                        </div>
                    </div>

                    <div *ngIf="mustChooseSubrole && !isSubroleSelected" class="mt-4">
                        <fuse-alert class="w-full"
                                    [showIcon]="true"
                                    [appearance]="'outline'"
                        >{{ t('documents_dashboard.select_subr') }}
                        </fuse-alert>
                    </div>
                    <div *ngIf="mustChooseSubrole && isSubroleSelected && !currentSubroleCanAccess" class="mt-4">
                        <fuse-alert class="w-full"
                                    [showIcon]="true"
                                    type="warning"
                                    [appearance]="'outline'"
                        >{{ t('documents_dashboard.subrole_not_admitted') }}
                        </fuse-alert>
                    </div>
                </ng-template>

            </div>

        </mat-drawer-content>
    </mat-drawer-container>

</ng-container>

<ng-container *transloco="let t">
    <div class="flex flex-row items-center p-6 border-b bg-card">

        <div class="flex items-center pr-2" *ngIf="isInternalMenuHeader">
            <!-- Sidebar toggle button -->
            <button
                    mat-icon-button
                    (click)="toogleInternalMenu()">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col md:flex-row justify-between gap-4 w-full items-center">

            <div class="flex flex-col">
                <div class="flex flex-col md:flex-row gap-2 items-center">
                    <div class="flex flex-col">
                        <div [ngClass]="isInternalMenuHeader? 'text-3xl font-semibold': 'text-4xl font-extrabold'"
                             class="flex items-center tracking-tight leading-none">{{t(title)}}</div>
                    </div>

                    <a *ngIf="tornIndietro"
                       class="inline-flex items-center leading-6 text-primary hover:underline cursor-pointer mt-2"
                       (click)="goBack()"><span
                        class="inline-flex items-center"><mat-icon
                        class="mat-icon notranslate icon-size-5 text-current mat-icon-no-color">arrow_back</mat-icon><span
                        class="ml-1.5 font-medium leading-5">{{t(tornIndietro.title || 'common.torna_indietro')}}</span></span></a>

                    <div *ngIf="showChip">
                        <mat-chip-list>
                            <div class="flex flex-col w-full items-center mt-2 sm:mt-0">
                                <mat-chip *ngIf="!!dataChip"
                                          [class]="dataChip | statusEnumToColorTheme: PianoDiStudiStatusExtended"
                                          [matTooltip]="dataChip === PianoDiStudiStatusExtended.APPROVATONONVALIDO ? t('study_plan_status.approved_not_valid') : t(dataChip | statusEnumToLanguagePath: PianoDiStudiStatusExtended)"
                                          [ngClass]="{'cursor-pointer': dataChip === PianoDiStudiStatusExtended.APPROVATONONVALIDO}"
                                          (click)="ifNotValidOpenPlanNotValidDialog()"
                                          class="justify-between">
                                    <span class="line-clamp-1">{{t(dataChip | statusEnumToLanguagePath: PianoDiStudiStatusExtended)}}</span>
                                    <mat-icon *ngIf="dataChip === PianoDiStudiStatusExtended.APPROVATONONVALIDO"
                                              class="icon-size-5 text-amber-500 ml-1">
                                        warning_amber
                                    </mat-icon>
                                </mat-chip>
                            </div>
                        </mat-chip-list>
                    </div>
                </div>
                <div *ngIf="subtitle"
                     class="text-md pt-2 font-semibold leading-none text-slate-600">
                    {{subtitle}}
                </div>
            </div>

            <ng-content></ng-content>

        </div>

    </div>
</ng-container>

import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TableData,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AttivitaTrasversaleStatus,
    CorsoPianoDiStudiPerExportViewImpl,
    ElementoOffertaFormativaType,
    PageCorsoPianoDiStudiPerExportViewImpl,
    SpesaStudenteInfoViewImpl, StudenteCicloPianoDiStudiViewImpl,
    StudentiCicloService
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, Observable, ObservedValueOf, of, OperatorFunction, takeUntil} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isEmpty} from "lodash";
import * as moment from "moment/moment";
import {PageEvent} from "@angular/material/paginator";
import {FormGroup} from "@angular/forms";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {
    TrasversaliBarFilterContainerComponent
} from "../../../../../shared/components/trasversali-bar-filter-container/trasversali-bar-filter-container.component";
import {ApprovazioneTrasversaliMassivaDataI} from "../approvazione-trasversale-steps-interface";
import {CAN_GO_AHEAD$, DEFAULT_PAGE_SIZE, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {removeUnderscore} from "../../../../../shared/utils/utils";
import {
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {NoopScrollStrategy} from "@angular/cdk/overlay";
import {TrasversaliBarFilterManagerService} from "../../../../../shared/service/trasversali-bar-filter-manager.service";
import {
    TransversalFilterService
} from "../../../requests/approvazione-attivita-formative-di-ricerca/transversal-sidebar-filter-container-request/transversal-filter.service";
import {PathEnum} from "../../../../../app.routing";
import {Router} from "@angular/router";


@Component({
    selector: 'app-scegli-trasversali-stepper',
    templateUrl: './scegli-trasversali.component.html',
    styleUrls: ['./scegli-trasversali.component.scss']
})
export class ScegliTrasversaliComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {

    loading: boolean;
    attivitaListConfiguration: GenericTableConfigurationModel;
    private pageSize: number = DEFAULT_PAGE_SIZE;
    private fieldsLabelMap: Map<string, string>;
    currentFilterListChipLabels: Array<string>;
    isFirstTime: boolean;
    showMiniLoader: boolean = false;
    filterContainerComponent: TrasversaliBarFilterContainerComponent;
    showTopbar: boolean;
    withDelibera: boolean;
    @ViewChild(TrasversaliBarFilterContainerComponent) set trasversaliBarFilterContainer(component: TrasversaliBarFilterContainerComponent) {
        this.filterContainerComponent = component;
    }

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private translocoService: TranslocoService,
                private studentiCicloService: StudentiCicloService,
                private filterManagerService: TransversalFilterService,
                private logOutService: LogoutService,
                private router: Router,
                private dialog: MatDialog,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneTrasversaliMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.fieldsLabelMap = this.filterManagerService.fieldsLabelMap;
        this.isFirstTime = true;
        // subscribe to filters changes
        this.getDataAndFilterSubscribe();
        this.operazioneMassivaData$?.subscribe(inputOutputData => {
            this.withDelibera = inputOutputData?.showWithDelibera;
            // if confirm component force refresh subscribe to filters changes
            if(inputOutputData.refreshData) {
                console.log('REFRESHING TRASV IN SCEGLI TRASV')
                this.attivitaListConfiguration = undefined;
                this.resetRefreshData();
                this.canGoNext$.next(false);
                this.getAttivitaRequest$(this.isFirstTime, this.filterManagerService?.filterApplied);
            }
        });
    }

    getAttivitaRequest$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10): void {
        this.getAttivitaFormObs$(isFirstTime, filters, page, size).subscribe({
            next: (value) => {},
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {this.logOutService.goToHome()},
                    () => this.getAttivitaRequest$(isFirstTime, filters, page, size),
                    'common.go_to_home',
                    err?.error?.message);
            }
        });
    }

    getAttivitaFormObs$(isFirstTime?: boolean, filters?: any, page: number = 0, size: number = 10): Observable<PageCorsoPianoDiStudiPerExportViewImpl> {
        if (isFirstTime) {
            this.loading = true;
        } else {
            this.fuseConfirmationService.showLoader();
        }
        return this.studentiCicloService.searchAttivitaTrasversaliPianiDiStudiStudentiForm(
            !!filters?.denomDescrAttivita ? filters?.denomDescrAttivita : undefined,
            !!filters?.dettaglioAttivitaTrasversale ? filters?.dettaglioAttivitaTrasversale : undefined,
            !!filters?.annoInsegnamento ? filters?.annoInsegnamento : undefined,
            ElementoOffertaFormativaType.ATTIVITATRASVERSALE,
            [AttivitaTrasversaleStatus.ATTESADELIBERACOORDINATORE],
            !!filters?.nominativoStudente ? filters?.nominativoStudente : undefined,
            !!filters?.emailStudente ? filters?.emailStudente : undefined,
            !!filters?.cfStudente ? filters?.cfStudente : undefined,
            !!filters?.matricolaStudente ? filters?.matricolaStudente : undefined,
            this.withDelibera,
            true, // is not presunta non definita
            page, size, undefined).pipe(
            tap((pageAttivita: PageCorsoPianoDiStudiPerExportViewImpl) => {
                this.attivitaListConfiguration = this.buildAttivitaConfiguration(pageAttivita, size, page);
            }),
            takeUntil(this.destroy$),
            finalize(() => {
                    if (isFirstTime) {
                        this.isFirstTime = false;
                        this.loading = false;
                    } else {
                        this.fuseConfirmationService.hideLoader();
                    }
                }
            )
        );


    }

    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    buildAttivitaConfiguration(pageAttivita: PageCorsoPianoDiStudiPerExportViewImpl, pageSize: number, page: number): GenericTableConfigurationModel {
        return {
            configuration: {
                data: pageAttivita?.content
                    ?.map(attivita => ({
                        ...attivita,
                        annoInsegnamentoFormatted: attivita.annoInsegnamento,
                        studenteForChip: [{nome: attivita.utenteNome, cognome: attivita.utenteCognome}],
                        categoryFormatted: attivita.denominazioneCategoria,
                        idAttivitaTrasversaleForTable: this.buildIdForTable(attivita),
                        dettaglioAttivitaTrasversaleForShow: attivita.dettaglioAttivitaTrasversale ?? attivita.denominazione
                    })),
                sticky: true,
                pageSize: pageSize,
                pageBE: page,
                pageSizeOptions: [10, 20, 30, 40, 50],
                totalElements: pageAttivita.totalElements,
                isPaginatedBE: true,
                messaggioDatiAssenti: 'custom_table.no_data_activities',
                hidePaginator: false,
                selection: {
                    isSelectable: true,
                    selectedColumnKeyToShow: 'dettaglioAttivitaTrasversaleForShow',
                    selectedColumnKeyPrimaryKey: 'idAttivitaTrasversaleForTable',
                },
                configurazioneTabella: [
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'common.detail',
                        colonnaKey: 'dettaglioAttivitaTrasversale',
                        flex: 20,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'cycle_doctorate.activity_typology',
                        colonnaKey: 'denominazione',
                        flex: 20,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'support.category',
                        colonnaKey: 'categoryFormatted',
                        flex: 20,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'budget.year',
                        colonnaKey: 'annoInsegnamentoFormatted',
                        flex: 15,
                    },
                    {
                        tipo: TipoColonnaEnum.CHIP_USER,
                        nomeColonna: 'common.dottorando',
                        colonnaKey: 'studenteForChip',
                        flex: 20,
                    },
                    {
                        tipo: TipoColonnaEnum.STRING,
                        nomeColonna: 'requests.description_CFU',
                        colonnaKey: 'descrizioneCfu',
                        flex: 8,
                    },
                    {
                        tipo: TipoColonnaEnum.NUMBER,
                        nomeColonna: 'cycle_doctorate.number_of_credits_required',
                        colonnaKey: 'cfuPrevisti',
                        flex: 6,
                    },
                    {
                        nomeColonna: 'common.description',
                        colonnaKey: 'descrizione',
                        flex: 10,
                        tipo: TipoColonnaEnum.ACTION_MENU,
                        button: [
                            {
                                nameIconButton: 'library_books',
                                click: TipoClickEnum.GO_TO,
                                tooltip: 'common.go_to_study_plan',
                                color: 'accent',
                            },
                            {
                                nameIconButton: 'description',
                                click: TipoClickEnum.SHOW,
                                tooltip: 'common.description',
                                color: 'accent'
                            }
                        ]
                    },
                ],
            }
        };
    }


    tableClickAction($event: ClickEvent) {
        switch ($event.tipoClick) {
            case TipoClickEnum.SHOW:
                this.openDescriptionDialog($event.value.descrizione);
                break;
            case TipoClickEnum.GO_TO:
                this.openPlanInNewTab(($event.value as CorsoPianoDiStudiPerExportViewImpl)?.utenteId);
                break;
        }
    }

    openPlanInNewTab(id: string): void {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([PathEnum.STUDENTS, id, PathEnum.STUDY_PLAN])
        );
        window.open(url, '_blank');
    }

    openDescriptionDialog(description: string): void {
        const data: DialogInfoI = this.buildDataForDescriptionDIalog(description);
        this.dialog.open(DialogInfoComponent, {
            data,
            panelClass: 'dialog-responsive-full-screen',
            scrollStrategy: new NoopScrollStrategy()
        });
    }

    buildDataForDescriptionDIalog(description: string): DialogInfoI {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        return {
            title: 'common.description',
            icon: {
                name: 'mat_outline:info',
                color: 'primary'
            },
            isTextArea: true,
            text: description
        };
    }


    onStudentDataTableChanged($event: TableData) {
        console.log('SELECTION CHANGED')
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$?.getValue(),
            attivitaSelected: $event.selectedRows.length > 0 ? $event.selectedRows.map(row => row.data) as CorsoPianoDiStudiPerExportViewImpl[] : undefined,
        });
        this.canGoNext$.next($event.selectedRows.length > 0);
    }

    pageAction($event: PageEvent): void {
        console.log('PageEvent', $event);
        const page = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.getAttivitaRequest$(false, this.getMainFormGroup()?.value, page, this.pageSize);
    }

    getMainFormGroup(): FormGroup<any> {
        return this.filterManagerService?.mainFormGroup;
    }

    catchErrorInTrasversaliList$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, {openModalError: {goToHome: true}}, true, true, true, false);
    }

    getListChipsLabel(): Array<string> {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        if (!isEmpty(this.getMainFormGroup()?.value)) {
            const entries: [string, any][] = Object.entries(this.getMainFormGroup()?.value)
                .filter(item => item[0] !== 'statiAttivita');
            const filteredEntries = entries?.filter(item => item.every(value => !!value));
            let labels = filteredEntries?.map(filteredItem => filteredItem?.map((element) => {
                    if (typeof element === 'string') {
                        if (!!this.fieldsLabelMap.get(element)) {

                            return get(translation, this.fieldsLabelMap.get(element), element);
                        }else {
                            return removeUnderscore(element);
                        }
                    }
                    if (typeof element === 'boolean') {
                        return '';
                    }
                    else if (!!element?.codiceFiscale) {
                        const completeName = (element?.cognome || '') + ' ' + (element?.nome || '');
                        return (!!element?.cognome ? completeName : '')?.toUpperCase();
                    }
                    else if (!!element?.id && !!element?.denominazione) {
                        return (!!element?.denominazione ? element.denominazione : '');
                    }
                    else {
                        return element;
                    }
                }
            ).join(': '));
            console.log('Lables chips', labels);
            labels = labels.map(label => label?.endsWith(': ')? label.slice(0, -2) : label);
            return labels;
        } else {
            return [];
        }
    }


    toogleFilterContainer(hasToogleInternalMenu: boolean): void {
        this.filterContainerComponent?.setFilterApplied();
        this.showTopbar = true;
    }

    resetFilters(): void {
        this.getMainFormGroup()?.reset();
        this.filterContainerComponent?.resetFilterApplied();
        this.filterManagerService?.checkApplyFilterClick$.next(true);
    }

    closeBar() {
        this.showTopbar = false;
    }

    private resetRefreshData() {
        console.log(this.operazioneMassivaData$.getValue())
        this.operazioneMassivaData$.next({
            ...this.operazioneMassivaData$.getValue(),
            refreshData: false
        })
    }

    openFilters(){
        this.filterManagerService.openDrawer.next(true)
    }

    private getDataAndFilterSubscribe() {

        this.filterManagerService.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            switchMap(value => {
                if(!this.isFirstTime){
                    return this.getAttivitaFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.pageSize).pipe(
                        this.catchErrorInTrasversaliList$(this.getAttivitaFormObs$(this.isFirstTime,  this.filterManagerService?.filterApplied, 0, this.pageSize))
                    );
                }
                return of(null);
            }),
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    this.filterManagerService.filterApplied$.next( this.getMainFormGroup().getRawValue());
                    this.currentFilterListChipLabels = this.getListChipsLabel();
                }
            }
        );
    }

    private openNotesDialog(spesa: SpesaStudenteInfoViewImpl) {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let closeLabel = get(translation, 'dialog.close', null);
        const data: GenericComponentDialogConfig = {
            title: 'budget.note',
            message: spesa.nota,
            icon: {
                show: true,
                name: 'description',
                color: 'accent',
            },
            actions: {
                cancel: {
                    show: true,
                    label: closeLabel,
                },
                confirm: {
                    show: false,
                    function: form => {}
                }
            },
            dismissible: true,
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }

    private buildIdForTable(attivita: CorsoPianoDiStudiPerExportViewImpl) {
        if(attivita.idAttivitaProposta){
            return 'PROP_ ' + attivita?.idAttivitaProposta;
        } else if(attivita.idAttivitaPresunta){
            return 'PRES_ ' + attivita?.idAttivitaPresunta;
        } else {
            return attivita?.idAttivitaTrasversale;
        }
    }


    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.resetFilters();
    }

    protected readonly AttivitaTrasversaleStatus = AttivitaTrasversaleStatus;
}

import {
    AggiornamentoPianoDiStudiStatus,
    AnnoRiferimentoValues,
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO, PianoDiStudiStatus,
    UserSottoruoloView
} from "../../../../../../api-clients/generated/services";
import {Injectable} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {DEFAULT_PAGE_SIZE} from "../approvazione-piani-formativi.component";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {ApplyFiltersQuicklyStudentsI} from "../../../students-list/students-list.component";

export interface StudyPlanFilters {
    approvedStatus?: PianoDiStudiStatus[];
    approvedValidity?: boolean;
    lastUpdateStatus?: AggiornamentoPianoDiStudiStatus[];
    cf?: string;
    nominative?: string;
    email?: string;
    matricola?: string;
}

@Injectable({
    providedIn: "root"
})
export class StudyPlansFilterService {

    mainFormGroup: FormGroup | undefined;

    pageSize = DEFAULT_PAGE_SIZE;
    page = 0;
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied = undefined;

    defaultFormPathList = [
        'nominative',
        //'cf',
        //'email',
        //'matricola',
        'approvedStatus',
        'approvedValidity',
        'lastUpdateStatus',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['nominative', 'menu_filter.nominative'],
        ['cf', 'common.fiscal_code'],
        ['matricola', 'common.enrollment_number'],
        ['email', 'common.email'],
        ['lastUpdateStatus', 'study_plan_status.last_update_status'],
        ['approvedStatus', 'common.status'],
        ['approvedValidity', 'common.valid_for_plan'],
    ]);

    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;

    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominative: null,
            cf: null,
            email: null,
            matricola: null,
            lastUpdateStatus: null,
            approvedStatus: null,
            approvedValidity: null,
        });
    }



    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if (force) {
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.statiSpesa) {
            this.mainFormGroup.get('statiSpesa').setValue(this.applyFiltersQuickly?.statiSpesa);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
            this.mainFormGroup.patchValue({statoUltimoAggiornamentoPianoDiStudi: true})
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
        this.mainFormGroup.get('approvedValidity').disable();
        this.mainFormGroup.get('approvedStatus').valueChanges.subscribe(status => {
            if(status?.length == 1 && status[0] === PianoDiStudiStatus.APPROVATO){
                this.mainFormGroup.get('approvedValidity').enable();
            } else {
                this.mainFormGroup.get('approvedValidity').setValue(undefined);
                this.mainFormGroup.get('approvedValidity').disable();
            }
        })
    }
    getFieldsByRole(ruolo?: AuthorityType, sottoruolo?: AuthorityType): string[] {
        return this.defaultFormPathList;
    }

    reset(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }

    restore(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}

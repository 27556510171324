import {Component, Inject, OnInit} from '@angular/core';
import {
    DeliberaApprovaPeriodiDiRicercaRequest,
    DeliberaApprovaPeriodiDiRicercaResponse,
    EsitoOperazione,
    EsitoSingolaOperazioneInOperazioneMassiva,
    OperazioniMassiveService,
    PeriodoDiMobilitaStudenteInfoView
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, switchMap, take, takeUntil, tap} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneRicercaMassivaDataI} from "../approvazione-ricerca-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {filter} from "rxjs/operators";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";


@Component({
    selector: 'app-confirm-approvazione-ricerca-stepper',
    templateUrl: './confirm-approvazione-ricerca.component.html',
    styleUrls: ['./confirm-approvazione-ricerca.component.scss']
})
export class ConfirmApprovazioneRicercaComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneRicercaData: ApprovazioneRicercaMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaOperazioneInOperazioneMassiva> | undefined;
    resultsPeriodiSelected: PeriodoDiMobilitaStudenteInfoView[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneRicercaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneRicercaMassivaDataI) => {
            this.approvazioneRicercaData = inputOutputData;
            // when there are studenti selected reset succeed and error flags
            if(this.approvazioneRicercaData?.periodiSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        console.log('confirm compile info', this.approvazioneRicercaData?.compileInfo)
        const requestBody: DeliberaApprovaPeriodiDiRicercaRequest = {
            id_delibera: this.approvazioneRicercaData?.deliberaCollegio?.id,
            specifiche: this.approvazioneRicercaData?.compileInfo?.map(ci => ({
                id_utente: ci.idUtente,
                codice_mobilita: ci.codiceMobilitaPeriodo,
                codice_tipologia_mobilita: ci.esito?.codice_tipologia_mobilita,
                doctor_europeaus: ci.esito?.doctor_europeaus,
                sede_doctor_europeaus: ci.esito?.sede_doctor_europeaus,
                erasmus: ci.esito?.erasmus,
                codice_tipo_intervento_supporto: ci?.esito?.codice_tipo_intervento_supporto,
                data_inizio: ci.esito?.data_inizio,
                data_fine: ci.esito?.data_fine
            }))
        }
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.deliberaApprovaPeriodiDiRicerca(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaRicercaResponse: DeliberaApprovaPeriodiDiRicercaResponse) => {
                this.resultsPeriodiSelected  = cloneDeep(this.approvazioneRicercaData?.periodiSelected);
                // forcing the refresh of studenti
                this.operazioneMassivaData$.next({
                    periodiSelected: undefined,
                    refreshPeriodi: true,
                    deliberaCollegio: undefined,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaRicercaResponse: DeliberaApprovaPeriodiDiRicercaResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaRicercaResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente = this.resultsPeriodiSelected?.find(s => s.studenteCiclo?.utente?.id === id_utente);
        return studente?.studenteCiclo?.utente?.cognome + ' ' + studente?.studenteCiclo?.utente?.nome;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }


}

import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO,
    PeriodoDiMobilitaStudenteStatus, PeriodoRegistroAttivitaStatus,
    SpesaStudenteStatus,
    SpesaStudenteType
} from 'api-clients/generated/services';
import {DEFAULT_PAGE_SIZE} from "../../../audit/audit.component";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {ApplyFiltersQuicklyStudentsI} from "../../../students-list/students-list.component";


export interface CorsiFilter {
    denominazioneAttivita?: string;
    nonEseguita?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class CorsiFilterService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    reset$ = new BehaviorSubject(false);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied = undefined;
    pageSize = 10;
    page = 0;
    defaultFormPathList = [
        'denominazioneAttivita',
        'nonEseguita',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['denominazioneAttivita', 'cycle_doctorate.activity_denomination'],
        ['nonEseguita', 'common.display_activities_with_phd_not_perfomed'],
    ]);

    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;
    applyFilterClick$ = new Subject();

    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            denominazioneAttivita: null,
            nonEseguita: null
        });
    }


    getFieldsByRole(): string[] {
        return this.defaultFormPathList;
    }

    get nonEsguitaCtrl(): FormControl{
        return  this.getCtrlByPath('nonEseguita')
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if(force){
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }

        if(this.applyFiltersQuickly?.attivitaOffertaFormativa) {
            this.mainFormGroup.get('denominazioneAttivita').setValue(this.applyFiltersQuickly?.attivitaOffertaFormativa.denominazione);
            this.filterApplied = this.mainFormGroup.getRawValue();
            this.applyFiltersQuickly = undefined;
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
    }






    reset(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }


    restore(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}

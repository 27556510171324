import {
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO,
    PeriodoDiMobilitaStudenteStatus,
    StatoTemporaleEnum
} from "../../../../../../api-clients/generated/services";
import {Injectable} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {BehaviorSubject, map, Observable, Subject} from "rxjs";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {filter} from "rxjs/operators";
import {ApplyFiltersQuicklyStudentsI} from "../../../students-list/students-list.component";


export interface MobilitaERicercaFilters {
    nominativoStudente?: string,
    statoSvolgimento?: StatoTemporaleEnum,
    periodoDa?: string,
    periodoA?: string,
    annoAccademico?: string,
    nominativoStrutturaEsteraOspitante?: string,
    codiceNazioneStruttura?: string,
    dataFineNull?: boolean,
    codiceTipologiaMobilita?: string,
    codiceTipologiaBonus?: number,
    codiceTipoInterventoSupporto?: string,
    codiceMobilita?: string,
    stato?: PeriodoDiMobilitaStudenteStatus,
    dataUltimaModifica?: any,
}

@Injectable({
    providedIn: 'root'
})
export class RicercaFilterManagerRequestService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$= new BehaviorSubject(undefined);
    filterApplied: any;
    defaultFormPathList = [
        'codiceMobilita',
        'nominativoStudente',
        'statoSvolgimento',
        'stato',
        'periodoDa',
        'periodoA',
        'annoAccademico',
        'nominativoStrutturaEsteraOspitante',
        'codiceNazioneStruttura',
        'dataFineNull',
        'codiceTipologiaMobilita',
        'codiceTipologiaBonus',
        'codiceTipoInterventoSupporto',
        'dataUltimaModifica'
    ];
    supervisorFormPathList = [
        'codiceMobilita',
        'nominativoStudente',
        'statoSvolgimento',
        'stato',
        'periodoDa',
        'periodoA',
        'annoAccademico',
        'nominativoStrutturaEsteraOspitante',
        'codiceNazioneStruttura',
        'dataFineNull',
        'codiceTipologiaMobilita',
        'codiceTipologiaBonus',
        'codiceTipoInterventoSupporto',
        'dataUltimaModifica',
        'richiesteDiRetribuzioneStatus'
    ];
    cosupervisorFormPathList = this.supervisorFormPathList;
    coordinatoreFormPathList = [
        'codiceMobilita',
        'nominativoStudente',
        'statoSvolgimento',
        'stato',
        'periodoDa',
        'periodoA',
        'annoAccademico',
        'nominativoStrutturaEsteraOspitante',
        'codiceNazioneStruttura',
        'dataFineNull',
        'codiceTipologiaMobilita',
        'codiceTipologiaBonus',
        'codiceTipoInterventoSupporto',
        'dataUltimaModifica',
        'richiesteDiRetribuzioneStatus'
    ];
    fieldsLabelMap = new Map<string, string>([
        ['codiceMobilita', 'mobility.mobility_code'],
        ['nominativoStudente', 'mobility.student_nominative'],
        ['statoSvolgimento', 'mobility.execution_state'],
        ['periodoDa', 'mobility.research_period_from'],
        ['periodoA', 'mobility.research_period_to'],
        ['annoAccademico', 'mobility.year'],
        ['nominativoStrutturaEsteraOspitante', 'mobility.foreign_university_nominative'],
        ['codiceNazioneStruttura', 'mobility.foreign_university_nationality'],
        ['dataFineNull', 'mobility.without_datafine'],
        ['codiceTipologiaMobilita','mobility.mobility_type'],
        ['codiceTipologiaBonus','mobility.bonus_tipology'],
        ['codiceTipoInterventoSupporto', 'mobility.support_tipology'],
        ['stato', 'mobility.state'],
        ['dataUltimaModifica', 'mobility.last_modified_date'],
        ['richiesteDiRetribuzioneStatus', 'mobility.have_retribuzione_to_validate']

    ]);
    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;



    constructor(private localStorageService: LocalStorageService,
                private formBuilder: FormBuilder) {
    }

    init(): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            if(!!this.mainFormGroup) {
                this.mainFormGroup.reset();
            } else {
                this.mainFormGroup = this.createDefaultFormGroup();
            }
        }
        if(this.applyFiltersQuickly?.stato) {
            this.mainFormGroup.get('stato').setValue(this.applyFiltersQuickly?.stato);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            nominativoStudente: null,
            statoSvolgimento: null,
            periodoDa: null,
            periodoA: null,
            annoAccademico: null,
            nominativoStrutturaEsteraOspitante: null,
            codiceNazioneStruttura: null,
            dataFineNull: null,
            codiceTipologiaMobilita: null,
            codiceTipologiaBonus: null,
            codiceTipoInterventoSupporto: null,
            codiceMobilita: null,
            stato: null,
            dataUltimaModifica: null,
            richiesteDiRetribuzioneStatus: null,
        });
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }


    getFieldsByRole(ruolo?: AuthorityType, sottoruolo?: AuthorityType): string[] {
        switch(ruolo) {
            case AuthorityType.SUPERADMIN:
            case AuthorityType.ADMIN:
                return this.defaultFormPathList;
            default:
                switch (sottoruolo) {
                    case AuthorityType.GRUPPODICOORDINAMENTO:
                    case AuthorityType.COLLEGIODOCENTI:
                    case AuthorityType.GRUPPOPTA:
                    case AuthorityType.SEGRETARIOAMMINISTRATIVODIDIPARTIMENTO:
                        return this.defaultFormPathList;
                    case AuthorityType.SUPERVISORE:
                        return this.supervisorFormPathList;
                    case AuthorityType.COSUPERVISORE:
                        return this.cosupervisorFormPathList;
                    case AuthorityType.COORDINATORE:
                        return this.coordinatoreFormPathList;
                    default:
                        return null;
                }
        }


    }

    setSelectValues(selectValues?: MobilitaRequestSelectFormValuesDTO): void {
        this.selectValues = selectValues;
        this.checkAggiornamentoSelectValues$.next(this.selectValues);
    }

    appliedFiltersObsevable$(): Observable<MobilitaERicercaFilters> {
        return this.checkApplyFilterClick$.asObservable().pipe(
            filter(Boolean),
            map(() => this.mainFormGroup?.value)
        );
    }

}

import {Component, Inject, OnInit} from '@angular/core';
import {
    ApprovazioneMassivaMissioniStudenteRequest, ApprovazioneMassivaMissioniStudenteResponse,
    DeliberaApprovaSpeseStudentiRequest,
    DeliberaApprovaSpeseStudentiResponse,
    EsitoOperazione, EsitoSingolaApprovazioneMissioneStudente,
    EsitoSingolaApprovazioneSpesa, MissioneAndStudenteInfoView,
    OperazioniMassiveService, SpesaStudentePerApprovMassivaInfoView
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneMissioniMassivaDataI} from "../approvazione-missioni-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {get} from "lodash";
import moment from "moment";


@Component({
    selector: 'app-confirm-approvazione-missioni-stepper',
    templateUrl: './confirm-approvazione-missioni.component.html',
    styleUrls: ['./confirm-approvazione-missioni.component.scss']
})
export class ConfirmApprovazioneMissioniComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneMissioniData: ApprovazioneMissioniMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaApprovazioneMissioneStudente> | undefined;
    resultsMissioniSelected: MissioneAndStudenteInfoView[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneMissioniMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneMissioniMassivaDataI) => {
            this.approvazioneMissioniData = inputOutputData;
            if(this.approvazioneMissioniData?.missioniSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: ApprovazioneMassivaMissioniStudenteRequest = {
            missione_ids: this.approvazioneMissioniData?.missioniSelected?.map(m => m.id)
        }
        console.log(this.approvazioneMissioniData)
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.approvazioneMassivaMissioniDottorandi(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaMissioniResponse: ApprovazioneMassivaMissioniStudenteResponse) => {
                this.resultsMissioniSelected  = cloneDeep(this.approvazioneMissioniData?.missioniSelected);
                // forcing the refresh of spese
                this.operazioneMassivaData$.next({
                    missioniSelected: undefined,
                    refreshData: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaMissioniResponse: ApprovazioneMassivaMissioniStudenteResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaMissioniResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

   /* private buildUtentiSuperamento(): UserSuperamentoCorsoMassivo[] {
        return this.approvazioneSpeseData?.studentiSelected?.map(s => ({
            id_utente: s.idUtente,
            nota_superamento: this.approvazioneSpeseData?.noteSuperamentoCustom?.find(sWithNotaCustom => s.idUtente === sWithNotaCustom.id)?.nota || this.approvazioneSpeseData?.notaSuperamento
        }))
    }*/

/*    getNotaSuperamento(studente: StudenteCicloUtenteViewImpl): string {
        return this.approvazioneSpeseData?.noteSuperamentoCustom?.find(sWithNotaCustom => studente.idUtente === sWithNotaCustom.id)?.nota || this.approvazioneSpeseData?.notaSuperamento;
    }*/

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(id_utente: string) {
        const studente =
            this.resultsMissioniSelected?.find(s => s?.studenteCiclo?.utente?.id === id_utente);
        return studente?.studenteCiclo?.utente?.cognome + ' ' + studente?.studenteCiclo?.utente?.nome;
    }

    getMissioneById(idMissione: string) {
        return this.resultsMissioniSelected?.find(s => s.id === idMissione);
    }


    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

    protected readonly moment = moment;
}

import {Component, Inject, OnInit} from '@angular/core';
import {
    CorsoPianoDiStudiPerExportViewImpl,
    DeliberaApprovaPeriodiDiRicercaResponse,
    DeliberaMassivaAttivitaTrasversaliRequest,
    DeliberaMassivaAttivitaTrasversaliResponse,
    EsitoOperazione,
    EsitoSingolaDeliberaAttivitaTrasversale,
    OperazioniMassiveService
} from "../../../../../../api-clients/generated/services";
import {filter, switchMap, tap} from "rxjs/operators";
import {BehaviorSubject, finalize, take, takeUntil} from "rxjs";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {ApprovazioneTrasversaliMassivaDataI} from "../approvazione-trasversale-steps-interface";
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {cloneDeep} from "lodash-es";
import {get} from "lodash";
import {TranslocoService} from "@ngneat/transloco";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";


@Component({
    selector: 'app-confirm-approvazione-trasversali-stepper',
    templateUrl: './confirm-approvazione-trasversali.component.html',
    styleUrls: ['./confirm-approvazione-trasversali.component.scss']
})
export class ConfirmApprovazioneTrasversaliComponent extends AbstractDefaultComponent implements OnInit {

    approvazioneAttivitaData: ApprovazioneTrasversaliMassivaDataI;
    operationSucceed: boolean;
    operationError: boolean;
    operationEsiti: Array<EsitoSingolaDeliberaAttivitaTrasversale> | undefined;
    resultsAttivitaSelected: CorsoPianoDiStudiPerExportViewImpl[];

    constructor(private appInitService: AppInitService,
                private operazioniMassiveService: OperazioniMassiveService,
                private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneTrasversaliMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneTrasversaliMassivaDataI) => {
            this.approvazioneAttivitaData = inputOutputData;
            // if there are some trasversali selected reset the succeed and error flags
            if(this.approvazioneAttivitaData?.attivitaSelected?.length > 0) {
                this.operationSucceed = false;
                this.operationError = false;
            }
        })
    }


    confirmOperation() {
        this.operationError = undefined;
        this.operationSucceed = undefined;
        this.fuseConfirmationService.showLoader();
        const requestBody: DeliberaMassivaAttivitaTrasversaliRequest = {
            id_delibera: this.approvazioneAttivitaData?.deliberaCollegio?.id,
            attivita_trasversali_ids: this.approvazioneAttivitaData?.attivitaSelected?.map(a => ({
                id_attivita_trasversale: a.idAttivitaTrasversale,
                id_attivita_presunta: a.idAttivitaPresunta,
                id_attivita_proposta: a.idAttivitaProposta,
            }))
        };
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.operazioniMassiveService.deliberaMassivaAttivitaTrasversali(requestBody)),
            takeUntil(this.destroy$),
            tap((approvaAttivitaResponse: DeliberaMassivaAttivitaTrasversaliResponse) => {
                this.resultsAttivitaSelected  = cloneDeep(this.approvazioneAttivitaData?.attivitaSelected);
                // forcing the refresh of trasversali
                this.operazioneMassivaData$.next({
                    attivitaSelected: undefined,
                    deliberaCollegio: undefined,
                    refreshData: true,
                });
            }),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (approvaSpeseResponse: DeliberaApprovaPeriodiDiRicercaResponse) => {
                    this.operationSucceed = true;
                    this.operationEsiti = approvaSpeseResponse?.esiti_operazioni;
                },
                error: (err) => {
                    this.operationError = true;
                    console.log(err)
                }
            }
        );

    }

   /* private buildUtentiSuperamento(): UserSuperamentoCorsoMassivo[] {
        return this.approvazioneSpeseData?.studentiSelected?.map(s => ({
            id_utente: s.idUtente,
            nota_superamento: this.approvazioneSpeseData?.noteSuperamentoCustom?.find(sWithNotaCustom => s.idUtente === sWithNotaCustom.id)?.nota || this.approvazioneSpeseData?.notaSuperamento
        }))
    }*/

/*    getNotaSuperamento(studente: StudenteCicloUtenteViewImpl): string {
        return this.approvazioneSpeseData?.noteSuperamentoCustom?.find(sWithNotaCustom => studente.idUtente === sWithNotaCustom.id)?.nota || this.approvazioneSpeseData?.notaSuperamento;
    }*/

    protected readonly EsitoOperazione = EsitoOperazione;

    getStudenteById(idAttivitaTrasversale: string) {
        const studente = this.resultsAttivitaSelected?.find(s => s.idAttivitaTrasversale === idAttivitaTrasversale);
        return studente?.utenteCognome + ' ' + studente?.utenteNome;
    }


    getDettaglioFromEsito(esito: EsitoSingolaDeliberaAttivitaTrasversale) {
        return this.resultsAttivitaSelected?.find(a => a.idAttivitaTrasversale === esito.id_attivita_trasversale)?.dettaglioAttivitaTrasversale;
    }

    getDenominazioneFromEsito(esito: EsitoSingolaDeliberaAttivitaTrasversale) {
        return this.resultsAttivitaSelected?.find(a => a.idAttivitaTrasversale === esito.id_attivita_trasversale)?.denominazione;
    }

    openConfirmOperationDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        this.fuseConfirmationService.open({
                title: get(translation, 'dialog.attention', null),
                message: get(translation, 'massive_operations.confirm_operation_attention_message', null),
                icon: {
                    name: 'mat_outline:warning',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                actions: [
                    {
                        color: 'accent',
                        label: get(translation, 'common.close', null), icon: 'close',
                    },
                    {
                        color: 'primary',
                        label: get(translation, 'common.confirm', null), icon: 'check',
                        function: () => this.confirmOperation()
                    }]
            }
        );
    }

}

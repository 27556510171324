<ng-container *transloco="let t ">
    <div class="h-full w-full" fxLayout="column">
        <mat-drawer-container class="flex-auto h-full" style="overflow: hidden">
            <!-- Drawer -->
            <mat-drawer #drawer style="overflow: hidden"
                        class="w-72 sm:w-180 dark:bg-gray-900 my-mat-drawer"
                        mode='over'
                        [opened]="drawerOpened"
                        [autoFocus]="false">


                <app-corsi-sidebar-filter-container style="overflow: hidden"
                                                    [hideFilter]="approvalMode"
                                                    (checkApplyFilterClick$)="checkApplyFilterClick()"
                                                    (closeDrawer)="closeDrawer()">
                </app-corsi-sidebar-filter-container>


            </mat-drawer>

            <!-- Drawer content -->
            <mat-drawer-content class="flex flex-col overflow-hidden">
                <app-wrap-request
                    [titleHeader]="'common.didactic_activities'"
                    (resetStep$)="resetStep()"
                    (changeRuolo$)="changeRuolo()"
                    [getCurrentProfileCanAccess]="getCurrentProfileCanAccess()"
                    (canRenderContent)="canRenderContent = $event"
                    [noProfileSelectedLabel]="'requests.no_profile_selected_courses'"
                    [profileNotAdmittedLabel]="'requests.profile_not_admitted_courses'"
                    [approvalMode]="approvalMode"
                    class="h-full w-full">

                    <ng-container *ngIf="canRenderContent && approvalMode">
                        <app-operazioni-massive
                            class="w-full h-full"
                            [operazioneMassivaTipo]="operazioneMassivaTipo"
                        >
                            <ng-container [ngTemplateOutlet]="button"></ng-container>
                        </app-operazioni-massive>

                    </ng-container>

                    <ng-container *ngIf=" canRenderContent && !approvalMode">
                        <div class="w-full h-full bg-card shadow px-2 py-6">
                            <app-table-superamento-corsi
                                (toogleFilterContainer$)="toogleFilterContainer($event)">
                                <ng-container [ngTemplateOutlet]="button"></ng-container>
                            </app-table-superamento-corsi>
                        </div>

                    </ng-container>

                </app-wrap-request>

            </mat-drawer-content>
        </mat-drawer-container>

    </div>

    <ng-template #button>
        <button
            mat-flat-button
            class="multi-line-button"
            *ngIf="currentProfile === AuthorityType.DOCENTECORSO"
            (click)="approvalMode = !approvalMode"
            [color]="'primary'">
            <mat-icon class="mr-2">{{ approvalMode ? 'view_list' : 'check_circle' }}</mat-icon>
            <span>{{ t(!approvalMode ? 'common.approval_mode_text' : 'common.no_approval_mode_text') }}</span>
        </button>
    </ng-template>
</ng-container>

import {Injectable, ViewChild, ViewChildren} from '@angular/core';
import {OperazioniMassiveComponent} from '../operazioni-massive/operazioni-massive.component';
import {
    AbstractDefaultComponent
} from '../../../shared/abstracts/abstract-default-component/abstract-default-component';
import {MatDrawer} from '@angular/material/sidenav';

@Injectable()
export abstract class RequestAbstract extends AbstractDefaultComponent {
    @ViewChild(OperazioniMassiveComponent) operazioniMassiveComponent: OperazioniMassiveComponent;
    @ViewChild('drawer') drawer: MatDrawer;
    approvalMode = false;
    periodsApprovalMode = false;
    validationConclusionApprovalMode = false;
    drawerOpened: boolean = false;

    protected constructor() {
        super();
    }
    changeRuolo(): void {
        this.operazioniMassiveComponent?.changeRuolo();
    }

    resetStep(): void {
        this.operazioniMassiveComponent?.resetStep();
    }

    closeDrawer(): void {
        this.drawer?.close();
    }
    openDrawer(): void {
        this.drawer.toggle();
    }


    toogleFilterContainer(resp: { hasToogleInternalMenu: boolean}): void {
        if (resp.hasToogleInternalMenu) {
            this.drawer.toggle();
        }
    }

}

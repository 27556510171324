import {Component, InjectionToken, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
    AbstractDefaultComponent
} from "../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {AuthorityType} from "../../../../api-clients/generated/services";
import {getOperazioneDescrizione} from "./operazioni-massive-utils";
import {MatStepper} from "@angular/material/stepper";
import {
    getStepsByOperazione,
    OperazioneMassivaDataI,
    OperazioneMassivaI,
    OperazioneMassivaStepConfig,
    OperazioneMassivaTipo
} from './operazioni-massive-config';
import {ScegliCorsoComponent} from "./superamento-corso-steps/scegli-corso/scegli-corso.component";

export const DEFAULT_PAGE_SIZE = 10;

export const CAN_GO_AHEAD$ = new InjectionToken<BehaviorSubject<boolean>>('CAN_GO_AHEAD$');
export const OPERAZIONE_MASSIVA_DATA$ = new InjectionToken<BehaviorSubject<OperazioneMassivaDataI>>('OPERAZIONE_MASSIVA_DATA$');


@Component({
    selector: 'app-operazioni-massive',
    templateUrl: './operazioni-massive.component.html',
    styleUrls: ['./operazioni-massive.component.scss']
})
export class OperazioniMassiveComponent extends AbstractDefaultComponent implements OnInit {

    @ViewChild('stepper') stepper: MatStepper;
    @Input() operazioneMassivaTipo: OperazioneMassivaTipo;
    @ViewChild(ScegliCorsoComponent) scegliCorsoComponent: ScegliCorsoComponent;

    loading: boolean = false;
    title: string;
    subtitle: string;
    sottoruoli: string[];
    ctrlSelectFormControl: FormControl = new FormControl(null);
    selectedOperazioneTipo: OperazioneMassivaTipo;
    operazioniDisponibili: OperazioneMassivaI[];
    currentRuolo: AuthorityType;
    currentSubroleCanAccess: boolean;
    isSubroleSelected: boolean;
    mustChooseSubrole: boolean;
    protected readonly AuthorityType = AuthorityType;
    steps: OperazioneMassivaStepConfig[];
    operazioneFormGroup: FormGroup;

    protected readonly CAN_GO_AHEAD$ = CAN_GO_AHEAD$;
    protected readonly OPERAZIONE_MASSIVA_DATA$ = OPERAZIONE_MASSIVA_DATA$;
    protected readonly getOperazioneDescrizione = getOperazioneDescrizione;

    constructor(
        private fb: FormBuilder,
        private injector: Injector
    ) {
        super();

        this.operazioneFormGroup = this.fb.group({
            operazione: ['', Validators.required],
        });
        this.operazioneFormGroup?.get('operazione')?.valueChanges?.subscribe(operazione => {
            this.steps = getStepsByOperazione(operazione, this.injector)
        });

    }

    ngOnInit(): void {

        this.operazioneFormGroup.get('operazione').setValue(this.operazioneMassivaTipo);
    }


    changeRuolo() {
        this.steps = [];
        this.operazioneFormGroup.get('operazione').setValue(this.operazioneMassivaTipo);
        this.selectedOperazioneTipo = this.operazioneMassivaTipo;
    }

    resetStep() {
        this.steps = []
    }
}




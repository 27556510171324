<!--
<mat-spinner [diameter]="diameter"></mat-spinner>
-->
<div class="flex flex-col items-center">
    <img
        class="w-28"
        src="assets/images/logo/segno_428_503.png"
        alt="atena">
    <div class="spinner">
        <div class="bounce1 bg-active-custom"></div>
        <div class="bounce2 bg-active-custom"></div>
        <div class="bounce3 bg-active-custom"></div>
    </div>
</div>


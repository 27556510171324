import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {
    AuthorityType,
    MobilitaRequestSelectFormValuesDTO,
    PeriodoDiMobilitaStudenteStatus,
    SpesaStudenteStatus,
    SpesaStudenteType
} from 'api-clients/generated/services';
import {DEFAULT_PAGE_SIZE} from "../../../audit/audit.component";
import {LocalStorageService} from "../../../../../shared/service/local-storage.service";
import {ApplyFiltersQuicklyStudentsI} from "../../../students-list/students-list.component";


export interface BudgetFilters {
    codiceSpesa?: string;
    tipologiaSpesa?: SpesaStudenteType;
    statiSpesa?: Array<SpesaStudenteStatus>;
    descrizioneNoteSpesa?: string;
    nominativoStudente?: string;
    emailStudente?: string;
    cfStudente?: string;
    matricolaStudente?: string;
}

@Injectable({
    providedIn: "root"
})
export class BudgetFilterService {

    mainFormGroup: FormGroup | undefined;
    selectValues: MobilitaRequestSelectFormValuesDTO;
    checkAggiornamentoSelectValues$ = new BehaviorSubject(undefined);
    reset$ = new BehaviorSubject(false);
    checkApplyFilterClick$ = new BehaviorSubject(false);
    currentSottoRuolo: AuthorityType | undefined;
    filterApplied$ = new BehaviorSubject(undefined);
    filterApplied = undefined;
    pageSize = 10;
    page = 0;
    defaultFormPathList = [
        'codiceSpesa',
        'tipologiaSpesa',
        'statiSpesa',
        'descrizioneNoteSpesa',
        'nominativoStudente',
        'emailStudente',
        'cfStudente',
        'matricolaStudente',
    ];
    fieldsLabelMap = new Map<string, string>([
        ['codiceSpesa', 'budget.codice_spesa'],
        ['tipologiaSpesa', 'budget.type'],
        ['statiSpesa', 'budget.state'],
        ['descrizioneNoteSpesa', 'common.note'],
        ['nominativoStudente', 'common.nominative'],
        ['emailStudente', 'common.email'],
        ['cfStudente', 'common.fiscal_code'],
        ['matricolaStudente', 'common.enrollment_number'],
    ]);

    openDrawer = new Subject();
    applyFiltersQuickly: ApplyFiltersQuicklyStudentsI;


    constructor(public localStorageService: LocalStorageService,
                public formBuilder: FormBuilder) {
    }


    createDefaultFormGroup(): FormGroup {
        return this.formBuilder.group({
            codiceSpesa: null,
            tipologiaSpesa: null,
            statiSpesa:  null,
            descrizioneNoteSpesa: null,
            nominativoStudente: null,
            emailStudente: null,
            cfStudente: null,
            matricolaStudente: null,
        });
    }


    getFieldsByRole(): string[] {
        return this.defaultFormPathList;
    }

    getCtrlByPath(path: string): FormControl<any> {
        return this.mainFormGroup?.get(path) as FormControl;
    }

    init(force?: boolean): void {
        const sottoruolo = this.localStorageService.dipartimentoRuoloCiclo?.sottoruolo;
        if ((this.currentSottoRuolo !== sottoruolo) || !this.currentSottoRuolo) {
            this.currentSottoRuolo = sottoruolo;
            this.buildForm();
        } else if(force){
            this.buildForm();
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
        if(this.applyFiltersQuickly?.statiSpesa) {
            this.mainFormGroup.get('statiSpesa').setValue(this.applyFiltersQuickly?.statiSpesa);
            this.filterApplied = this.mainFormGroup.getRawValue();
        }
    }

    private buildForm() {
        if (!!this.mainFormGroup) {
            this.mainFormGroup.reset();
        } else {
            this.mainFormGroup = this.createDefaultFormGroup();
        }
    }






    reset(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }


    restore(): void {
        this.page = 0;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.mainFormGroup.reset();
        this.buildForm();
        this.filterApplied = this.mainFormGroup.getRawValue();
        this.checkApplyFilterClick$.next(true);
    }
}

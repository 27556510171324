/**
 * Dottorandi Backend API
 * Servizi per la gestione della piattaforma App-Dottorandi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddMissioneStudenteRequest } from '../model/addMissioneStudenteRequest';
import { AddOrUpdateCosupervisoreStudenteRequestDTO } from '../model/addOrUpdateCosupervisoreStudenteRequestDTO';
import { AddPeriodoRegistroAttivitaDTO } from '../model/addPeriodoRegistroAttivitaDTO';
import { AddRegistroAttivitaDTO } from '../model/addRegistroAttivitaDTO';
import { AddRichiestaDiRetrubuzioneMobilitaDTO } from '../model/addRichiestaDiRetrubuzioneMobilitaDTO';
import { AddSpesaStudenteRequestDTO } from '../model/addSpesaStudenteRequestDTO';
import { AddUpdatePianoDiStudiDTO } from '../model/addUpdatePianoDiStudiDTO';
import { AggiornamentoBudgetStudenteStatus } from '../model/aggiornamentoBudgetStudenteStatus';
import { AggiornamentoPianoDiStudiStatus } from '../model/aggiornamentoPianoDiStudiStatus';
import { AnnoRiferimentoValues } from '../model/annoRiferimentoValues';
import { AnnullamentoPeriodoDiMobilitaDTO } from '../model/annullamentoPeriodoDiMobilitaDTO';
import { AttivitaTrasversaleStatus } from '../model/attivitaTrasversaleStatus';
import { BudgetStudenteInfoView } from '../model/budgetStudenteInfoView';
import { BudgetStudenteInfoViewImpl } from '../model/budgetStudenteInfoViewImpl';
import { CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO } from '../model/calcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO';
import { CorsoPianoDiStudiLightView } from '../model/corsoPianoDiStudiLightView';
import { CosupervisoriStudenteResponseDTO } from '../model/cosupervisoriStudenteResponseDTO';
import { DettaglioBorsaStudente } from '../model/dettaglioBorsaStudente';
import { DettaglioBorsaStudenteUpdateDTO } from '../model/dettaglioBorsaStudenteUpdateDTO';
import { DettaglioCalcoloImportoMobilita } from '../model/dettaglioCalcoloImportoMobilita';
import { ElementoOffertaFormativaType } from '../model/elementoOffertaFormativaType';
import { EsitoAggiornamentoBudgetByGruppoPTA } from '../model/esitoAggiornamentoBudgetByGruppoPTA';
import { EsitoRichiestaApprovazione } from '../model/esitoRichiestaApprovazione';
import { EsitoRichiestaApprovazioneByCoordinatore } from '../model/esitoRichiestaApprovazioneByCoordinatore';
import { EsitoRichiestaApprovazioneByGruppoPTA } from '../model/esitoRichiestaApprovazioneByGruppoPTA';
import { EsitoRichiestaApprovazioneMobilitaPR } from '../model/esitoRichiestaApprovazioneMobilitaPR';
import { EsitoRichiestaApprovazioneMobilitaPRByCoordinatore } from '../model/esitoRichiestaApprovazioneMobilitaPRByCoordinatore';
import { EsitoRichiestaApprovazioneMobilitaSE } from '../model/esitoRichiestaApprovazioneMobilitaSE';
import { EsitoRichiestaApprovazioneMobilitaSEByCoordinatore } from '../model/esitoRichiestaApprovazioneMobilitaSEByCoordinatore';
import { EsitoRichiestaConclusioneMobilita } from '../model/esitoRichiestaConclusioneMobilita';
import { EsitoRichiestaRetribuzioneMobilita } from '../model/esitoRichiestaRetribuzioneMobilita';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { GetDettaglioBorsaStudenteDTO } from '../model/getDettaglioBorsaStudenteDTO';
import { GetInfoStudenteResponse } from '../model/getInfoStudenteResponse';
import { GetZipAllegatiRichiesteDiSpesaDTO } from '../model/getZipAllegatiRichiesteDiSpesaDTO';
import { MissioneInfoView } from '../model/missioneInfoView';
import { MissioneStatus } from '../model/missioneStatus';
import { PageCorsoPianoDiStudiPerExportViewImpl } from '../model/pageCorsoPianoDiStudiPerExportViewImpl';
import { PageSpesaStudentePerApprovMassivaInfoView } from '../model/pageSpesaStudentePerApprovMassivaInfoView';
import { PageStudenteCicloIdAndUtenteInfoView } from '../model/pageStudenteCicloIdAndUtenteInfoView';
import { PageStudenteCicloPianoDiStudiViewImpl } from '../model/pageStudenteCicloPianoDiStudiViewImpl';
import { PageStudenteCicloUtenteViewImpl } from '../model/pageStudenteCicloUtenteViewImpl';
import { PeriodoDiMobilitaStudenteInfoView } from '../model/periodoDiMobilitaStudenteInfoView';
import { PeriodoDiMobilitaStudenteStatus } from '../model/periodoDiMobilitaStudenteStatus';
import { PeriodoDiRicercaStudenteRequestDTO } from '../model/periodoDiRicercaStudenteRequestDTO';
import { PeriodoDiSoggiornoEsteroStudenteRequestDTO } from '../model/periodoDiSoggiornoEsteroStudenteRequestDTO';
import { PeriodoRegistroAttivitaFilterForm } from '../model/periodoRegistroAttivitaFilterForm';
import { PeriodoRegistroAttivitaInfoView } from '../model/periodoRegistroAttivitaInfoView';
import { PeriodoRegistroAttivitaStatus } from '../model/periodoRegistroAttivitaStatus';
import { PianoDiStudiInfoViewImpl } from '../model/pianoDiStudiInfoViewImpl';
import { PianoDiStudiStatus } from '../model/pianoDiStudiStatus';
import { RegistroAttivitaFilterForm } from '../model/registroAttivitaFilterForm';
import { RegistroAttivitaInfoView } from '../model/registroAttivitaInfoView';
import { RegistroAttivitaInfoViewImpl } from '../model/registroAttivitaInfoViewImpl';
import { RemoveCosupervisoreStudenteRequestDTO } from '../model/removeCosupervisoreStudenteRequestDTO';
import { RichiestaDiConclusioneMobilitaDTO } from '../model/richiestaDiConclusioneMobilitaDTO';
import { SpesaStudenteStatus } from '../model/spesaStudenteStatus';
import { SpesaStudenteType } from '../model/spesaStudenteType';
import { StoricoAutorizzazioneMobilitaStudenteInfoView } from '../model/storicoAutorizzazioneMobilitaStudenteInfoView';
import { StoricoMembriStudenteInfoView } from '../model/storicoMembriStudenteInfoView';
import { StudenteCicloMotivoStatus } from '../model/studenteCicloMotivoStatus';
import { StudenteCicloStatus } from '../model/studenteCicloStatus';
import { UpbCicloCorsoDiStudiInfoView } from '../model/upbCicloCorsoDiStudiInfoView';
import { UpdateBudgetStudenteRequest } from '../model/updateBudgetStudenteRequest';
import { UpdateDeliberaPeriodoDiMobilitaRequest } from '../model/updateDeliberaPeriodoDiMobilitaRequest';
import { UpdateSupervisoreStudenteRequestDTO } from '../model/updateSupervisoreStudenteRequestDTO';
import { UpdateSupervisoreStudenteResponseDTO } from '../model/updateSupervisoreStudenteResponseDTO';
import { UtenteIdsDTO } from '../model/utenteIdsDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class StudentiCicloService {

    protected basePath = 'https://api-dev-1-vanvitelli.southengineering.it/dottorandi-backend';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette ad un membro del Gruppo PTA di accettare o rifiutare una richiesta di budget di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param id_aggiornamento codice univoco dell&#x27;aggiornamento richiesto
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaAggiornamentoBudgetStudenteRequestByGruppoPTA(body: EsitoAggiornamentoBudgetByGruppoPTA, id_utente: string, id_aggiornamento: string, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public accettaRifiutaAggiornamentoBudgetStudenteRequestByGruppoPTA(body: EsitoAggiornamentoBudgetByGruppoPTA, id_utente: string, id_aggiornamento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public accettaRifiutaAggiornamentoBudgetStudenteRequestByGruppoPTA(body: EsitoAggiornamentoBudgetByGruppoPTA, id_utente: string, id_aggiornamento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public accettaRifiutaAggiornamentoBudgetStudenteRequestByGruppoPTA(body: EsitoAggiornamentoBudgetByGruppoPTA, id_utente: string, id_aggiornamento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accettaRifiutaAggiornamentoBudgetStudenteRequestByGruppoPTA.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaAggiornamentoBudgetStudenteRequestByGruppoPTA.');
        }

        if (id_aggiornamento === null || id_aggiornamento === undefined) {
            throw new Error('Required parameter id_aggiornamento was null or undefined when calling accettaRifiutaAggiornamentoBudgetStudenteRequestByGruppoPTA.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/budget/${encodeURIComponent(String(id_aggiornamento))}/gruppo-pta`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un coordinatore di accettare o rifiutare un periodo di ricerca di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaPRByCoordinore(body: EsitoRichiestaApprovazioneMobilitaPRByCoordinatore, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaPRByCoordinore(body: EsitoRichiestaApprovazioneMobilitaPRByCoordinatore, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaPRByCoordinore(body: EsitoRichiestaApprovazioneMobilitaPRByCoordinatore, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaPRByCoordinore(body: EsitoRichiestaApprovazioneMobilitaPRByCoordinatore, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRByCoordinore.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRByCoordinore.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRByCoordinore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/ricerca/${encodeURIComponent(String(codice_mobilita))}/coordinatore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di accettare o rifiutare un periodo di ricerca di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaPRBySupervisore(body: EsitoRichiestaApprovazioneMobilitaPR, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaPRBySupervisore(body: EsitoRichiestaApprovazioneMobilitaPR, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaPRBySupervisore(body: EsitoRichiestaApprovazioneMobilitaPR, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaPRBySupervisore(body: EsitoRichiestaApprovazioneMobilitaPR, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRBySupervisore.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRBySupervisore.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaPRBySupervisore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/ricerca/${encodeURIComponent(String(codice_mobilita))}/supervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un coordinatore di accettare o rifiutare un periodo di mobilità (Soggiorno Estero) di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaSEByCoordinore(body: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaSEByCoordinore(body: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaSEByCoordinore(body: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaSEByCoordinore(body: EsitoRichiestaApprovazioneMobilitaSEByCoordinatore, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEByCoordinore.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEByCoordinore.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEByCoordinore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/soggiorno-estero/${encodeURIComponent(String(codice_mobilita))}/coordinatore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di accettare o rifiutare un periodo di mobilità (Soggiorno Estero) di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaPeriodoDiMobilitaSEBySupervisore(body: EsitoRichiestaApprovazioneMobilitaSE, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public accettaRifiutaPeriodoDiMobilitaSEBySupervisore(body: EsitoRichiestaApprovazioneMobilitaSE, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaSEBySupervisore(body: EsitoRichiestaApprovazioneMobilitaSE, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public accettaRifiutaPeriodoDiMobilitaSEBySupervisore(body: EsitoRichiestaApprovazioneMobilitaSE, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEBySupervisore.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEBySupervisore.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling accettaRifiutaPeriodoDiMobilitaSEBySupervisore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/soggiorno-estero/${encodeURIComponent(String(codice_mobilita))}/supervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un coordinatore di accettare o rifiutare una richiesta di spesa di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaSpesaStudenteRequestByCoordinatore(body: EsitoRichiestaApprovazioneByCoordinatore, id_utente: string, codice_spesa: string, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public accettaRifiutaSpesaStudenteRequestByCoordinatore(body: EsitoRichiestaApprovazioneByCoordinatore, id_utente: string, codice_spesa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestByCoordinatore(body: EsitoRichiestaApprovazioneByCoordinatore, id_utente: string, codice_spesa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestByCoordinatore(body: EsitoRichiestaApprovazioneByCoordinatore, id_utente: string, codice_spesa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accettaRifiutaSpesaStudenteRequestByCoordinatore.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaSpesaStudenteRequestByCoordinatore.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling accettaRifiutaSpesaStudenteRequestByCoordinatore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/budget/spesa/${encodeURIComponent(String(codice_spesa))}/coordinatore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un membro del Gruppo PTA di accettare o rifiutare una richiesta di spesa di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaSpesaStudenteRequestByGruppoPTA(body: EsitoRichiestaApprovazioneByGruppoPTA, id_utente: string, codice_spesa: string, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public accettaRifiutaSpesaStudenteRequestByGruppoPTA(body: EsitoRichiestaApprovazioneByGruppoPTA, id_utente: string, codice_spesa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestByGruppoPTA(body: EsitoRichiestaApprovazioneByGruppoPTA, id_utente: string, codice_spesa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestByGruppoPTA(body: EsitoRichiestaApprovazioneByGruppoPTA, id_utente: string, codice_spesa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accettaRifiutaSpesaStudenteRequestByGruppoPTA.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaSpesaStudenteRequestByGruppoPTA.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling accettaRifiutaSpesaStudenteRequestByGruppoPTA.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/budget/spesa/${encodeURIComponent(String(codice_spesa))}/gruppo-pta`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di accettare o rifiutare una richiesta di spesa di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public accettaRifiutaSpesaStudenteRequestBySupervisore(body: EsitoRichiestaApprovazione, id_utente: string, codice_spesa: string, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public accettaRifiutaSpesaStudenteRequestBySupervisore(body: EsitoRichiestaApprovazione, id_utente: string, codice_spesa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestBySupervisore(body: EsitoRichiestaApprovazione, id_utente: string, codice_spesa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public accettaRifiutaSpesaStudenteRequestBySupervisore(body: EsitoRichiestaApprovazione, id_utente: string, codice_spesa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling accettaRifiutaSpesaStudenteRequestBySupervisore.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling accettaRifiutaSpesaStudenteRequestBySupervisore.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling accettaRifiutaSpesaStudenteRequestBySupervisore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/budget/spesa/${encodeURIComponent(String(codice_spesa))}/supervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette lo studente di sottomettere una richiesta di missione.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMissioneStudente(body: AddMissioneStudenteRequest, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MissioneInfoView>>;
    public addMissioneStudente(body: AddMissioneStudenteRequest, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MissioneInfoView>>>;
    public addMissioneStudente(body: AddMissioneStudenteRequest, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MissioneInfoView>>>;
    public addMissioneStudente(body: AddMissioneStudenteRequest, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addMissioneStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addMissioneStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<MissioneInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/missioni/add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette lo studente di richiedere un periodio di mobilità di tipo &#x27;Ricerca&#x27;.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMobilitaPeriodoDiRicercaStudente(body: PeriodoDiRicercaStudenteRequestDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public addMobilitaPeriodoDiRicercaStudente(body: PeriodoDiRicercaStudenteRequestDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public addMobilitaPeriodoDiRicercaStudente(body: PeriodoDiRicercaStudenteRequestDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public addMobilitaPeriodoDiRicercaStudente(body: PeriodoDiRicercaStudenteRequestDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addMobilitaPeriodoDiRicercaStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addMobilitaPeriodoDiRicercaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/ricerca`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette lo studente di richiedere un periodio di mobilità di tipo &#x27;Soggiorno Estero&#x27;.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addMobilitaPeriodoDiSoggiornoEsteroStudente(body: PeriodoDiSoggiornoEsteroStudenteRequestDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public addMobilitaPeriodoDiSoggiornoEsteroStudente(body: PeriodoDiSoggiornoEsteroStudenteRequestDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public addMobilitaPeriodoDiSoggiornoEsteroStudente(body: PeriodoDiSoggiornoEsteroStudenteRequestDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public addMobilitaPeriodoDiSoggiornoEsteroStudente(body: PeriodoDiSoggiornoEsteroStudenteRequestDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addMobilitaPeriodoDiSoggiornoEsteroStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addMobilitaPeriodoDiSoggiornoEsteroStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/soggiorno-estero`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge o aggiorna i permessi approvativi di un Cosupervisore per uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdateCosupervisoreStudente(body: AddOrUpdateCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<CosupervisoriStudenteResponseDTO>;
    public addOrUpdateCosupervisoreStudente(body: AddOrUpdateCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CosupervisoriStudenteResponseDTO>>;
    public addOrUpdateCosupervisoreStudente(body: AddOrUpdateCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CosupervisoriStudenteResponseDTO>>;
    public addOrUpdateCosupervisoreStudente(body: AddOrUpdateCosupervisoreStudenteRequestDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addOrUpdateCosupervisoreStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addOrUpdateCosupervisoreStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CosupervisoriStudenteResponseDTO>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/cosupervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge o aggiorna il piano di studi di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrUpdatePianoDiStudiStudente(body: AddUpdatePianoDiStudiDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoViewImpl>;
    public addOrUpdatePianoDiStudiStudente(body: AddUpdatePianoDiStudiDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoViewImpl>>;
    public addOrUpdatePianoDiStudiStudente(body: AddUpdatePianoDiStudiDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoViewImpl>>;
    public addOrUpdatePianoDiStudiStudente(body: AddUpdatePianoDiStudiDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addOrUpdatePianoDiStudiStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addOrUpdatePianoDiStudiStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<PianoDiStudiInfoViewImpl>('patch',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/piano-di-studi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge un periodo di registro attività di uno studente da validare.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addPeriodoRegistroAttivita(body: AddPeriodoRegistroAttivitaDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public addPeriodoRegistroAttivita(body: AddPeriodoRegistroAttivitaDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public addPeriodoRegistroAttivita(body: AddPeriodoRegistroAttivitaDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public addPeriodoRegistroAttivita(body: AddPeriodoRegistroAttivitaDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addPeriodoRegistroAttivita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addPeriodoRegistroAttivita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/periodi-registro-attivita/add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge un elemento al registro attività di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<RegistroAttivitaInfoView>;
    public addRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RegistroAttivitaInfoView>>;
    public addRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RegistroAttivitaInfoView>>;
    public addRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addRegistroAttivita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addRegistroAttivita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RegistroAttivitaInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/registro-attivita/add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiunge una richiesta di spesa sul budget di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param addSpesaStudenteRequestDTO
     * @param filesAllegato
     * @param fileAttestatoMissione
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addSpesaStudenteRequestForm(id_utente: string, addSpesaStudenteRequestDTO?: AddSpesaStudenteRequestDTO, filesAllegato?: Array<Blob>, fileAttestatoMissione?: Blob, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoView>;
    public addSpesaStudenteRequestForm(id_utente: string, addSpesaStudenteRequestDTO?: AddSpesaStudenteRequestDTO, filesAllegato?: Array<Blob>, fileAttestatoMissione?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoView>>;
    public addSpesaStudenteRequestForm(id_utente: string, addSpesaStudenteRequestDTO?: AddSpesaStudenteRequestDTO, filesAllegato?: Array<Blob>, fileAttestatoMissione?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoView>>;
    public addSpesaStudenteRequestForm(id_utente: string, addSpesaStudenteRequestDTO?: AddSpesaStudenteRequestDTO, filesAllegato?: Array<Blob>, fileAttestatoMissione?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling addSpesaStudenteRequest.');
        }




        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (addSpesaStudenteRequestDTO !== undefined) {
            formParams = formParams.append('addSpesaStudenteRequestDTO', new Blob([JSON.stringify(addSpesaStudenteRequestDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (filesAllegato) {
            filesAllegato.forEach((element) => {
                formParams = formParams.append('filesAllegato', <any>element) as any || formParams;
            })
        }
        if (fileAttestatoMissione !== undefined) {
            formParams = formParams.append('fileAttestatoMissione', <any>fileAttestatoMissione) as any || formParams;
        }

        return this.httpClient.request<BudgetStudenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/budget/spesa`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di annullare un periodo di mobilità di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public annullamentoPeriodoDiMobilita(body: AnnullamentoPeriodoDiMobilitaDTO, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public annullamentoPeriodoDiMobilita(body: AnnullamentoPeriodoDiMobilitaDTO, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public annullamentoPeriodoDiMobilita(body: AnnullamentoPeriodoDiMobilitaDTO, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public annullamentoPeriodoDiMobilita(body: AnnullamentoPeriodoDiMobilitaDTO, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling annullamentoPeriodoDiMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling annullamentoPeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling annullamentoPeriodoDiMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/annullamento`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di calcolare l&#x27;importo di una richiesta di retribuzione su un periodo di mobilità in base ai periodi forniti.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(body: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<DettaglioCalcoloImportoMobilita>;
    public calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(body: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DettaglioCalcoloImportoMobilita>>;
    public calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(body: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DettaglioCalcoloImportoMobilita>>;
    public calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita(body: CalcolaDettaglioImportoRichiestaDiRetribuzioneMobilitaDTO, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling calcolaDettaglioImportoRichiestaDiRetribuzioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DettaglioCalcoloImportoMobilita>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/calcola-dettaglio-importo`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Check validità resgistro attività studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkRegistroAttivitaStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public checkRegistroAttivitaStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public checkRegistroAttivitaStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public checkRegistroAttivitaStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling checkRegistroAttivitaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/check-registro-attivita`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di eliminare un Periodo di Registro Attività in stato BOZZA.
     * 
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public deletePeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public deletePeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public deletePeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling deletePeriodoRegistroAttivitaStudente.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling deletePeriodoRegistroAttivitaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('delete',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove un elemento del registro attività di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param id_attivita id dell&#x27;attività da modificare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteRegistroAttivita(id_utente: string, id_attivita: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteRegistroAttivita(id_utente: string, id_attivita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteRegistroAttivita(id_utente: string, id_attivita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteRegistroAttivita(id_utente: string, id_attivita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling deleteRegistroAttivita.');
        }

        if (id_attivita === null || id_attivita === undefined) {
            throw new Error('Required parameter id_attivita was null or undefined when calling deleteRegistroAttivita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/registro-attivita/${encodeURIComponent(String(id_attivita))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;attestato di partecipazione di una missione dello studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param id_missione id missione
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAttestatoMissione(id_utente: string, id_missione: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadAttestatoMissione(id_utente: string, id_missione: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadAttestatoMissione(id_utente: string, id_missione: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadAttestatoMissione(id_utente: string, id_missione: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling downloadAttestatoMissione.');
        }

        if (id_missione === null || id_missione === undefined) {
            throw new Error('Required parameter id_missione was null or undefined when calling downloadAttestatoMissione.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/missioni/${encodeURIComponent(String(id_missione))}/download-attestato`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente nel dettaglio borsa di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param nome_file nome del file allegato da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoDettaglioBorsaStudente(id_utente: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoDettaglioBorsaStudente(id_utente: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoDettaglioBorsaStudente(id_utente: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoDettaglioBorsaStudente(id_utente: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getAllegatoDettaglioBorsaStudente.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getAllegatoDettaglioBorsaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/dettaglio-borsa/get-allegato`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un allegato presente nel periodo registro attività di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param nome_file nome del file allegato da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllegatoPeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAllegatoPeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAllegatoPeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAllegatoPeriodoRegistroAttivitaStudente(id_utente: string, id_periodo_registro_attivita: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getAllegatoPeriodoRegistroAttivitaStudente.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling getAllegatoPeriodoRegistroAttivitaStudente.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getAllegatoPeriodoRegistroAttivitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}/get-allegato`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array di un attestato di conclusione di un periodo di moiblità.
     *
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param nome_file nome del file allegato da scaricare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAttestatoConclusionePeriodoDiMobilita(id_utente: string, codice_mobilita: string, nome_file: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getAttestatoConclusionePeriodoDiMobilita(id_utente: string, codice_mobilita: string, nome_file: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getAttestatoConclusionePeriodoDiMobilita(id_utente: string, codice_mobilita: string, nome_file: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getAttestatoConclusionePeriodoDiMobilita(id_utente: string, codice_mobilita: string, nome_file: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getAttestatoConclusionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling getAttestatoConclusionePeriodoDiMobilita.');
        }

        if (nome_file === null || nome_file === undefined) {
            throw new Error('Required parameter nome_file was null or undefined when calling getAttestatoConclusionePeriodoDiMobilita.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (nome_file !== undefined && nome_file !== null) {
            queryParameters = queryParameters.set('nome_file', <any>nome_file);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/get-attestato-conclusione`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il budget di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBudgetStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoViewImpl>;
    public getBudgetStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoViewImpl>>;
    public getBudgetStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoViewImpl>>;
    public getBudgetStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getBudgetStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BudgetStudenteInfoViewImpl>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/budget`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il dettaglio borsa e l&#x27;extra fields mapper di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDettaglioBorsaStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<GetDettaglioBorsaStudenteDTO>;
    public getDettaglioBorsaStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetDettaglioBorsaStudenteDTO>>;
    public getDettaglioBorsaStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetDettaglioBorsaStudenteDTO>>;
    public getDettaglioBorsaStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getDettaglioBorsaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetDettaglioBorsaStudenteDTO>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/dettaglio-borsa`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInfoStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<GetInfoStudenteResponse>;
    public getInfoStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetInfoStudenteResponse>>;
    public getInfoStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetInfoStudenteResponse>>;
    public getInfoStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getInfoStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetInfoStudenteResponse>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array della lettera di ammissione di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLetteraDiAmmissioneStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getLetteraDiAmmissioneStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getLetteraDiAmmissioneStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getLetteraDiAmmissioneStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getLetteraDiAmmissioneStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/lettera-di-ammissione`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce una missione del dottorando a partire dal suo id.
     *
     * @param id_utente id dell&#x27;utente
     * @param id_missione id della missione
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMissioneStudente(id_utente: string, id_missione: string, observe?: 'body', reportProgress?: boolean): Observable<MissioneInfoView>;
    public getMissioneStudente(id_utente: string, id_missione: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MissioneInfoView>>;
    public getMissioneStudente(id_utente: string, id_missione: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MissioneInfoView>>;
    public getMissioneStudente(id_utente: string, id_missione: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getMissioneStudente.');
        }

        if (id_missione === null || id_missione === undefined) {
            throw new Error('Required parameter id_missione was null or undefined when calling getMissioneStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MissioneInfoView>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/missioni/${encodeURIComponent(String(id_missione))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista delle richieste di missioni del dottorando ordinate per data richiesta decrescente.
     *
     * @param id_utente id dell&#x27;utente
     * @param stati_missione
     * @param anno_riferimento
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMissioniStudente(id_utente: string, stati_missione?: Array<MissioneStatus>, anno_riferimento?: AnnoRiferimentoValues, observe?: 'body', reportProgress?: boolean): Observable<Array<MissioneInfoView>>;
    public getMissioniStudente(id_utente: string, stati_missione?: Array<MissioneStatus>, anno_riferimento?: AnnoRiferimentoValues, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MissioneInfoView>>>;
    public getMissioniStudente(id_utente: string, stati_missione?: Array<MissioneStatus>, anno_riferimento?: AnnoRiferimentoValues, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MissioneInfoView>>>;
    public getMissioniStudente(id_utente: string, stati_missione?: Array<MissioneStatus>, anno_riferimento?: AnnoRiferimentoValues, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getMissioniStudente.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stati_missione) {
            stati_missione.forEach((element) => {
                queryParameters = queryParameters.append('stati_missione', <any>element);
            })
        }
        if (anno_riferimento !== undefined && anno_riferimento !== null) {
            queryParameters = queryParameters.set('anno_riferimento', <any>anno_riferimento);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<MissioneInfoView>>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/missioni`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del pdf dell&#x27;anagrafica di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfAnagraficaStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfAnagraficaStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfAnagraficaStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfAnagraficaStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPdfAnagraficaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/anagrafica/pdf`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del pdf del piano di studi di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfPianoDiStudiStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfPianoDiStudiStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfPianoDiStudiStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfPianoDiStudiStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPdfPianoDiStudiStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/piano-di-studi/pdf`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array del pdf del registro attività di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param data_inizio data inizio del registro (dd/MM/yyyy)
     * @param data_fine data fine del registro (dd/MM/yyyy)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPdfRegistroAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getPdfRegistroAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getPdfRegistroAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getPdfRegistroAttivitaStudente(id_utente: string, data_inizio: string, data_fine: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPdfRegistroAttivitaStudente.');
        }

        if (data_inizio === null || data_inizio === undefined) {
            throw new Error('Required parameter data_inizio was null or undefined when calling getPdfRegistroAttivitaStudente.');
        }

        if (data_fine === null || data_fine === undefined) {
            throw new Error('Required parameter data_fine was null or undefined when calling getPdfRegistroAttivitaStudente.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (data_inizio !== undefined && data_inizio !== null) {
            queryParameters = queryParameters.set('data_inizio', <any>data_inizio);
        }
        if (data_fine !== undefined && data_fine !== null) {
            queryParameters = queryParameters.set('data_fine', <any>data_fine);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/registro-attivita/pdf`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di periodi di mobilità di tipo &#x27;Ricerca&#x27; ordinati per data richiesta decrescente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiDiRicercaStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public getPeriodiDiRicercaStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public getPeriodiDiRicercaStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public getPeriodiDiRicercaStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPeriodiDiRicercaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/ricerca`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista di periodi di mobilità di tipo &#x27;Soggiorno Estero&#x27; ordinati per data richiesta decrescente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiDiSoggiornoEsteroStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public getPeriodiDiSoggiornoEsteroStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public getPeriodiDiSoggiornoEsteroStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public getPeriodiDiSoggiornoEsteroStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPeriodiDiSoggiornoEsteroStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/soggiorno-estero`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;elenco dei periodi registro attività di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPeriodiRegistroAttivitaStudente(id_utente: string, body?: PeriodoRegistroAttivitaFilterForm, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public getPeriodiRegistroAttivitaStudente(id_utente: string, body?: PeriodoRegistroAttivitaFilterForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public getPeriodiRegistroAttivitaStudente(id_utente: string, body?: PeriodoRegistroAttivitaFilterForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public getPeriodiRegistroAttivitaStudente(id_utente: string, body?: PeriodoRegistroAttivitaFilterForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPeriodiRegistroAttivitaStudente.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/periodi-registro-attivita`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;elenco dei dottorandi con lo stato e la validità del proprio piano di studi con i filtri applicati.
     * 
     * @param nominativo 
     * @param statiStudente 
     * @param statiPianoDiStudi 
     * @param isPianoNonValido 
     * @param statiUltimoAggiornamentoPianoDiStudi 
     * @param isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi 
     * @param hasDeliberaSuUltimoAggiornamentoPianoDiStudi 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPianiDiStudiStudentiForm(nominativo?: string, statiStudente?: Array<StudenteCicloStatus>, statiPianoDiStudi?: Array<PianoDiStudiStatus>, isPianoNonValido?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageStudenteCicloPianoDiStudiViewImpl>;
    public getPianiDiStudiStudentiForm(nominativo?: string, statiStudente?: Array<StudenteCicloStatus>, statiPianoDiStudi?: Array<PianoDiStudiStatus>, isPianoNonValido?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStudenteCicloPianoDiStudiViewImpl>>;
    public getPianiDiStudiStudentiForm(nominativo?: string, statiStudente?: Array<StudenteCicloStatus>, statiPianoDiStudi?: Array<PianoDiStudiStatus>, isPianoNonValido?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStudenteCicloPianoDiStudiViewImpl>>;
    public getPianiDiStudiStudentiForm(nominativo?: string, statiStudente?: Array<StudenteCicloStatus>, statiPianoDiStudi?: Array<PianoDiStudiStatus>, isPianoNonValido?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (statiStudente) {
            statiStudente.forEach((element) => {
                formParams = formParams.append('statiStudente', <any>element) as any || formParams;
            })
        }
        if (statiPianoDiStudi) {
            statiPianoDiStudi.forEach((element) => {
                formParams = formParams.append('statiPianoDiStudi', <any>element) as any || formParams;
            })
        }
        if (isPianoNonValido !== undefined) {
            formParams = formParams.append('isPianoNonValido', <any>isPianoNonValido) as any || formParams;
        }
        if (statiUltimoAggiornamentoPianoDiStudi) {
            statiUltimoAggiornamentoPianoDiStudi.forEach((element) => {
                formParams = formParams.append('statiUltimoAggiornamentoPianoDiStudi', <any>element) as any || formParams;
            })
        }
        if (isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi', <any>isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }
        if (hasDeliberaSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('hasDeliberaSuUltimoAggiornamentoPianoDiStudi', <any>hasDeliberaSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }

        return this.httpClient.request<PageStudenteCicloPianoDiStudiViewImpl>('post',`${this.basePath}/api/v1/studente-ciclo/piani-di-studi/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info del piano di studi di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPianoDiStudiStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<PianoDiStudiInfoViewImpl>;
    public getPianoDiStudiStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PianoDiStudiInfoViewImpl>>;
    public getPianoDiStudiStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PianoDiStudiInfoViewImpl>>;
    public getPianoDiStudiStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPianoDiStudiStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PianoDiStudiInfoViewImpl>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/piano-di-studi`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info del piano formativo del piano di studi di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPianoFormativoStudente(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CorsoPianoDiStudiLightView>>;
    public getPianoFormativoStudente(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CorsoPianoDiStudiLightView>>>;
    public getPianoFormativoStudente(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CorsoPianoDiStudiLightView>>>;
    public getPianoFormativoStudente(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getPianoFormativoStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CorsoPianoDiStudiLightView>>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/piano-di-studi/piano-formativo`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le info del registro attività di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistroAttivitaStudente(body: RegistroAttivitaFilterForm, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<RegistroAttivitaInfoViewImpl>>;
    public getRegistroAttivitaStudente(body: RegistroAttivitaFilterForm, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<RegistroAttivitaInfoViewImpl>>>;
    public getRegistroAttivitaStudente(body: RegistroAttivitaFilterForm, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<RegistroAttivitaInfoViewImpl>>>;
    public getRegistroAttivitaStudente(body: RegistroAttivitaFilterForm, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getRegistroAttivitaStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getRegistroAttivitaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<RegistroAttivitaInfoViewImpl>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/registro-attivita`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli storici di autorizzazione alla mobilità per il dottorando.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStoriciAutorizzazioneMobilitaStudenteCiclo(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StoricoAutorizzazioneMobilitaStudenteInfoView>>;
    public getStoriciAutorizzazioneMobilitaStudenteCiclo(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StoricoAutorizzazioneMobilitaStudenteInfoView>>>;
    public getStoriciAutorizzazioneMobilitaStudenteCiclo(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StoricoAutorizzazioneMobilitaStudenteInfoView>>>;
    public getStoriciAutorizzazioneMobilitaStudenteCiclo(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getStoriciAutorizzazioneMobilitaStudenteCiclo.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<StoricoAutorizzazioneMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/storici-autorizzazione-mobilita-dottorando`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli storici membri studente per i supervisori e cosupervisori attuali di una lista di studenti.
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo(body: UtenteIdsDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<StoricoMembriStudenteInfoView>>;
    public getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo(body: UtenteIdsDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StoricoMembriStudenteInfoView>>>;
    public getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo(body: UtenteIdsDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StoricoMembriStudenteInfoView>>>;
    public getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo(body: UtenteIdsDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getStoriciCurrentSupervisoreCosupervisoreStudentiCiclo.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<StoricoMembriStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/storici-current-supervisore-cosupervisore-studenti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli studenti con i filtri applicati.
     *
     * @param nominativo
     * @param codiceFiscale
     * @param statiStudente
     * @param motiviStatoStudente
     * @param ateneoProvenienza
     * @param nazionalita
     * @param idCorsoInOffertaFormativa
     * @param corsoSuperato
     * @param statiUltimoAggiornamentoPianoDiStudi
     * @param isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi
     * @param statoSpesaStudente
     * @param statoUltimoAggiornamentoBudgetStudente
     * @param codiceFiscaleSupervisore
     * @param ssdSupervisore
     * @param afferenzaOrganizzativaSupervisore
     * @param codiceFiscaleCosupervisore
     * @param ssdCosupervisore
     * @param afferenzaOrganizzativaCosupervisore
     * @param codiceFiscaleRelatore
     * @param codiceFiscaleDocenteCorso
     * @param statiPeriodiDiMobilita
     * @param codiceTipoBorsaPerCiclo
     * @param statoPeriodoRegistroAttivita
     * @param hasDeliberaDiProgrammazioneMobilita
     * @param isInRegolaTasse
     * @param hasDeliberaSuUltimoAggiornamentoPianoDiStudi
     * @param hasDeliberaInizioAttivita
     * @param hasNotSupervisore
     * @param hasNotCosupervisore
     * @param hasAutorizzazioneMobilita
     * @param hasNumGiorniMobilitaInferioreAlMinimoPrevisto
     * @param hasNumGiorniMobilitaSuperioreAlPrevisto
     * @param statiMissioneDaApprovare
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStudentiForm(nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, idCorsoInOffertaFormativa?: string, corsoSuperato?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, codiceFiscaleSupervisore?: string, ssdSupervisore?: string, afferenzaOrganizzativaSupervisore?: string, codiceFiscaleCosupervisore?: string, ssdCosupervisore?: string, afferenzaOrganizzativaCosupervisore?: string, codiceFiscaleRelatore?: string, codiceFiscaleDocenteCorso?: string, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, codiceTipoBorsaPerCiclo?: string, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, hasDeliberaDiProgrammazioneMobilita?: boolean, isInRegolaTasse?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotSupervisore?: boolean, hasNotCosupervisore?: boolean, hasAutorizzazioneMobilita?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, statiMissioneDaApprovare?: Array<MissioneStatus>, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageStudenteCicloUtenteViewImpl>;
    public getStudentiForm(nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, idCorsoInOffertaFormativa?: string, corsoSuperato?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, codiceFiscaleSupervisore?: string, ssdSupervisore?: string, afferenzaOrganizzativaSupervisore?: string, codiceFiscaleCosupervisore?: string, ssdCosupervisore?: string, afferenzaOrganizzativaCosupervisore?: string, codiceFiscaleRelatore?: string, codiceFiscaleDocenteCorso?: string, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, codiceTipoBorsaPerCiclo?: string, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, hasDeliberaDiProgrammazioneMobilita?: boolean, isInRegolaTasse?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotSupervisore?: boolean, hasNotCosupervisore?: boolean, hasAutorizzazioneMobilita?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, statiMissioneDaApprovare?: Array<MissioneStatus>, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStudenteCicloUtenteViewImpl>>;
    public getStudentiForm(nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, idCorsoInOffertaFormativa?: string, corsoSuperato?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, codiceFiscaleSupervisore?: string, ssdSupervisore?: string, afferenzaOrganizzativaSupervisore?: string, codiceFiscaleCosupervisore?: string, ssdCosupervisore?: string, afferenzaOrganizzativaCosupervisore?: string, codiceFiscaleRelatore?: string, codiceFiscaleDocenteCorso?: string, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, codiceTipoBorsaPerCiclo?: string, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, hasDeliberaDiProgrammazioneMobilita?: boolean, isInRegolaTasse?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotSupervisore?: boolean, hasNotCosupervisore?: boolean, hasAutorizzazioneMobilita?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, statiMissioneDaApprovare?: Array<MissioneStatus>, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStudenteCicloUtenteViewImpl>>;
    public getStudentiForm(nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, idCorsoInOffertaFormativa?: string, corsoSuperato?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, codiceFiscaleSupervisore?: string, ssdSupervisore?: string, afferenzaOrganizzativaSupervisore?: string, codiceFiscaleCosupervisore?: string, ssdCosupervisore?: string, afferenzaOrganizzativaCosupervisore?: string, codiceFiscaleRelatore?: string, codiceFiscaleDocenteCorso?: string, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, codiceTipoBorsaPerCiclo?: string, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, hasDeliberaDiProgrammazioneMobilita?: boolean, isInRegolaTasse?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotSupervisore?: boolean, hasNotCosupervisore?: boolean, hasAutorizzazioneMobilita?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, statiMissioneDaApprovare?: Array<MissioneStatus>, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





































        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (statiStudente) {
            statiStudente.forEach((element) => {
                formParams = formParams.append('statiStudente', <any>element) as any || formParams;
            })
        }
        if (motiviStatoStudente) {
            motiviStatoStudente.forEach((element) => {
                formParams = formParams.append('motiviStatoStudente', <any>element) as any || formParams;
            })
        }
        if (ateneoProvenienza !== undefined) {
            formParams = formParams.append('ateneoProvenienza', <any>ateneoProvenienza) as any || formParams;
        }
        if (nazionalita !== undefined) {
            formParams = formParams.append('nazionalita', <any>nazionalita) as any || formParams;
        }
        if (idCorsoInOffertaFormativa !== undefined) {
            formParams = formParams.append('idCorsoInOffertaFormativa', <any>idCorsoInOffertaFormativa) as any || formParams;
        }
        if (corsoSuperato !== undefined) {
            formParams = formParams.append('corsoSuperato', <any>corsoSuperato) as any || formParams;
        }
        if (statiUltimoAggiornamentoPianoDiStudi) {
            statiUltimoAggiornamentoPianoDiStudi.forEach((element) => {
                formParams = formParams.append('statiUltimoAggiornamentoPianoDiStudi', <any>element) as any || formParams;
            })
        }
        if (isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi', <any>isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }
        if (statoSpesaStudente !== undefined) {
            formParams = formParams.append('statoSpesaStudente', <any>statoSpesaStudente) as any || formParams;
        }
        if (statoUltimoAggiornamentoBudgetStudente !== undefined) {
            formParams = formParams.append('statoUltimoAggiornamentoBudgetStudente', <any>statoUltimoAggiornamentoBudgetStudente) as any || formParams;
        }
        if (codiceFiscaleSupervisore !== undefined) {
            formParams = formParams.append('codiceFiscaleSupervisore', <any>codiceFiscaleSupervisore) as any || formParams;
        }
        if (ssdSupervisore !== undefined) {
            formParams = formParams.append('ssdSupervisore', <any>ssdSupervisore) as any || formParams;
        }
        if (afferenzaOrganizzativaSupervisore !== undefined) {
            formParams = formParams.append('afferenzaOrganizzativaSupervisore', <any>afferenzaOrganizzativaSupervisore) as any || formParams;
        }
        if (codiceFiscaleCosupervisore !== undefined) {
            formParams = formParams.append('codiceFiscaleCosupervisore', <any>codiceFiscaleCosupervisore) as any || formParams;
        }
        if (ssdCosupervisore !== undefined) {
            formParams = formParams.append('ssdCosupervisore', <any>ssdCosupervisore) as any || formParams;
        }
        if (afferenzaOrganizzativaCosupervisore !== undefined) {
            formParams = formParams.append('afferenzaOrganizzativaCosupervisore', <any>afferenzaOrganizzativaCosupervisore) as any || formParams;
        }
        if (codiceFiscaleRelatore !== undefined) {
            formParams = formParams.append('codiceFiscaleRelatore', <any>codiceFiscaleRelatore) as any || formParams;
        }
        if (codiceFiscaleDocenteCorso !== undefined) {
            formParams = formParams.append('codiceFiscaleDocenteCorso', <any>codiceFiscaleDocenteCorso) as any || formParams;
        }
        if (statiPeriodiDiMobilita) {
            statiPeriodiDiMobilita.forEach((element) => {
                formParams = formParams.append('statiPeriodiDiMobilita', <any>element) as any || formParams;
            })
        }
        if (codiceTipoBorsaPerCiclo !== undefined) {
            formParams = formParams.append('codiceTipoBorsaPerCiclo', <any>codiceTipoBorsaPerCiclo) as any || formParams;
        }
        if (statoPeriodoRegistroAttivita !== undefined) {
            formParams = formParams.append('statoPeriodoRegistroAttivita', <any>statoPeriodoRegistroAttivita) as any || formParams;
        }
        if (hasDeliberaDiProgrammazioneMobilita !== undefined) {
            formParams = formParams.append('hasDeliberaDiProgrammazioneMobilita', <any>hasDeliberaDiProgrammazioneMobilita) as any || formParams;
        }
        if (isInRegolaTasse !== undefined) {
            formParams = formParams.append('isInRegolaTasse', <any>isInRegolaTasse) as any || formParams;
        }
        if (hasDeliberaSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('hasDeliberaSuUltimoAggiornamentoPianoDiStudi', <any>hasDeliberaSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }
        if (hasDeliberaInizioAttivita !== undefined) {
            formParams = formParams.append('hasDeliberaInizioAttivita', <any>hasDeliberaInizioAttivita) as any || formParams;
        }
        if (hasNotSupervisore !== undefined) {
            formParams = formParams.append('hasNotSupervisore', <any>hasNotSupervisore) as any || formParams;
        }
        if (hasNotCosupervisore !== undefined) {
            formParams = formParams.append('hasNotCosupervisore', <any>hasNotCosupervisore) as any || formParams;
        }
        if (hasAutorizzazioneMobilita !== undefined) {
            formParams = formParams.append('hasAutorizzazioneMobilita', <any>hasAutorizzazioneMobilita) as any || formParams;
        }
        if (hasNumGiorniMobilitaInferioreAlMinimoPrevisto !== undefined) {
            formParams = formParams.append('hasNumGiorniMobilitaInferioreAlMinimoPrevisto', <any>hasNumGiorniMobilitaInferioreAlMinimoPrevisto) as any || formParams;
        }
        if (hasNumGiorniMobilitaSuperioreAlPrevisto !== undefined) {
            formParams = formParams.append('hasNumGiorniMobilitaSuperioreAlPrevisto', <any>hasNumGiorniMobilitaSuperioreAlPrevisto) as any || formParams;
        }
        if (statiMissioneDaApprovare) {
            statiMissioneDaApprovare.forEach((element) => {
                formParams = formParams.append('statiMissioneDaApprovare', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<PageStudenteCicloUtenteViewImpl>('post',`${this.basePath}/api/v1/studente-ciclo/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il byte array dell&#x27;allegato di una richiesta di spesa studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param codice_spesa codice univoco della spesa richiesta
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZipAllegatiRichiestaDiSpesaStudente(id_utente: string, codice_spesa: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getZipAllegatiRichiestaDiSpesaStudente(id_utente: string, codice_spesa: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getZipAllegatiRichiestaDiSpesaStudente(id_utente: string, codice_spesa: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getZipAllegatiRichiestaDiSpesaStudente(id_utente: string, codice_spesa: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getZipAllegatiRichiestaDiSpesaStudente.');
        }

        if (codice_spesa === null || codice_spesa === undefined) {
            throw new Error('Required parameter codice_spesa was null or undefined when calling getZipAllegatiRichiestaDiSpesaStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/budget/spesa/${encodeURIComponent(String(codice_spesa))}/get-zip-allegati-richiesta`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Consente di scaricare un file zip contenente gli allegati delle richieste di spesa a partire dalla lista di codici spesa in input.
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZipAllegatiRichiesteDiSpesa(body: GetZipAllegatiRichiesteDiSpesaDTO, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getZipAllegatiRichiesteDiSpesa(body: GetZipAllegatiRichiesteDiSpesaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getZipAllegatiRichiesteDiSpesa(body: GetZipAllegatiRichiesteDiSpesaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getZipAllegatiRichiesteDiSpesa(body: GetZipAllegatiRichiesteDiSpesaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getZipAllegatiRichiesteDiSpesa.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Blob>('post',`${this.basePath}/api/v1/studente-ciclo/get-zip-allegati-spese`,
            {
             responseType: "blob" as "json",
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Consente di scaricare un file zip contenente gli attestati della richiesta di retribuzione di un periodo di mobilità.
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZipAttestatiRichiestaDiRetribuzioneMobilita(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getZipAttestatiRichiestaDiRetribuzioneMobilita(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getZipAttestatiRichiestaDiRetribuzioneMobilita(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getZipAttestatiRichiestaDiRetribuzioneMobilita(id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getZipAttestatiRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling getZipAttestatiRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling getZipAttestatiRichiestaDiRetribuzioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/get-zip-attestati`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Consente di scaricare un file zip contenente gli attestati della richiesta di conclusione di un periodo di moiblità.
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getZipAttestatiRichiesteDiConclusioneMobilita(id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getZipAttestatiRichiesteDiConclusioneMobilita(id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getZipAttestatiRichiesteDiConclusioneMobilita(id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getZipAttestatiRichiesteDiConclusioneMobilita(id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling getZipAttestatiRichiesteDiConclusioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling getZipAttestatiRichiesteDiConclusioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/get-zip-attestati-conclusione`,
            {
             responseType: "blob" as "json",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove un Cosupervisore di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeCosupervisoreStudente(body: RemoveCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<CosupervisoriStudenteResponseDTO>;
    public removeCosupervisoreStudente(body: RemoveCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CosupervisoriStudenteResponseDTO>>;
    public removeCosupervisoreStudente(body: RemoveCosupervisoreStudenteRequestDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CosupervisoriStudenteResponseDTO>>;
    public removeCosupervisoreStudente(body: RemoveCosupervisoreStudenteRequestDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling removeCosupervisoreStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling removeCosupervisoreStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CosupervisoriStudenteResponseDTO>('delete',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/cosupervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Revoca l&#x27;autorizzazione alla mobilità per il dottorando.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokaAutorizzazioneMobilitaDottorando(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public revokaAutorizzazioneMobilitaDottorando(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public revokaAutorizzazioneMobilitaDottorando(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public revokaAutorizzazioneMobilitaDottorando(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling revokaAutorizzazioneMobilitaDottorando.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/revoca-autorizzazione-mobilita-dottorando`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di richiedere la conclusione di un periodo di mobilità caricando gli attestati.
     *
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param richiestaDiConclusioneMobilitaDTO
     * @param fileAttestato
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public richiediConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, richiestaDiConclusioneMobilitaDTO?: RichiestaDiConclusioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public richiediConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, richiestaDiConclusioneMobilitaDTO?: RichiestaDiConclusioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public richiediConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, richiestaDiConclusioneMobilitaDTO?: RichiestaDiConclusioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public richiediConclusionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, richiestaDiConclusioneMobilitaDTO?: RichiestaDiConclusioneMobilitaDTO, fileAttestato?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling richiediConclusionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling richiediConclusionePeriodoDiMobilita.');
        }



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (richiestaDiConclusioneMobilitaDTO !== undefined) {
            formParams = formParams.append('richiestaDiConclusioneMobilitaDTO', new Blob([JSON.stringify(richiestaDiConclusioneMobilitaDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAttestato) {
            fileAttestato.forEach((element) => {
                formParams = formParams.append('fileAttestato', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiedi-conclusione`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al dottorando di effettuare una richiesta di retribuzione su un periodo di mobilità caricando gli attestati.
     *
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param addRichiestaDiRetrubuzioneMobilitaDTO
     * @param fileAttestato
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public richiediRetribuzionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, addRichiestaDiRetrubuzioneMobilitaDTO?: AddRichiestaDiRetrubuzioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public richiediRetribuzionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, addRichiestaDiRetrubuzioneMobilitaDTO?: AddRichiestaDiRetrubuzioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public richiediRetribuzionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, addRichiestaDiRetrubuzioneMobilitaDTO?: AddRichiestaDiRetrubuzioneMobilitaDTO, fileAttestato?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public richiediRetribuzionePeriodoDiMobilitaForm(id_utente: string, codice_mobilita: string, addRichiestaDiRetrubuzioneMobilitaDTO?: AddRichiestaDiRetrubuzioneMobilitaDTO, fileAttestato?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling richiediRetribuzionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling richiediRetribuzionePeriodoDiMobilita.');
        }



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (addRichiestaDiRetrubuzioneMobilitaDTO !== undefined) {
            formParams = formParams.append('addRichiestaDiRetrubuzioneMobilitaDTO', new Blob([JSON.stringify(addRichiestaDiRetrubuzioneMobilitaDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAttestato) {
            fileAttestato.forEach((element) => {
                formParams = formParams.append('fileAttestato', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiedi-retribuzione`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista paginata delle attività trasversali nei piani di studi degli studenti con i filtri applicati.
     * 
     * @param denomDescrAttivita 
     * @param dettaglioAttivitaTrasversale 
     * @param annoInsegnamento 
     * @param tipo 
     * @param statiAttivitaTrasversale 
     * @param nominativoStudente 
     * @param emailStudente 
     * @param cfStudente 
     * @param matricolaStudente 
     * @param hasDelibera 
     * @param isNotPresuntaNonDefinita 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAttivitaTrasversaliPianiDiStudiStudentiForm(denomDescrAttivita?: string, dettaglioAttivitaTrasversale?: string, annoInsegnamento?: AnnoRiferimentoValues, tipo?: ElementoOffertaFormativaType, statiAttivitaTrasversale?: Array<AttivitaTrasversaleStatus>, nominativoStudente?: string, emailStudente?: string, cfStudente?: string, matricolaStudente?: string, hasDelibera?: boolean, isNotPresuntaNonDefinita?: boolean, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageCorsoPianoDiStudiPerExportViewImpl>;
    public searchAttivitaTrasversaliPianiDiStudiStudentiForm(denomDescrAttivita?: string, dettaglioAttivitaTrasversale?: string, annoInsegnamento?: AnnoRiferimentoValues, tipo?: ElementoOffertaFormativaType, statiAttivitaTrasversale?: Array<AttivitaTrasversaleStatus>, nominativoStudente?: string, emailStudente?: string, cfStudente?: string, matricolaStudente?: string, hasDelibera?: boolean, isNotPresuntaNonDefinita?: boolean, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageCorsoPianoDiStudiPerExportViewImpl>>;
    public searchAttivitaTrasversaliPianiDiStudiStudentiForm(denomDescrAttivita?: string, dettaglioAttivitaTrasversale?: string, annoInsegnamento?: AnnoRiferimentoValues, tipo?: ElementoOffertaFormativaType, statiAttivitaTrasversale?: Array<AttivitaTrasversaleStatus>, nominativoStudente?: string, emailStudente?: string, cfStudente?: string, matricolaStudente?: string, hasDelibera?: boolean, isNotPresuntaNonDefinita?: boolean, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageCorsoPianoDiStudiPerExportViewImpl>>;
    public searchAttivitaTrasversaliPianiDiStudiStudentiForm(denomDescrAttivita?: string, dettaglioAttivitaTrasversale?: string, annoInsegnamento?: AnnoRiferimentoValues, tipo?: ElementoOffertaFormativaType, statiAttivitaTrasversale?: Array<AttivitaTrasversaleStatus>, nominativoStudente?: string, emailStudente?: string, cfStudente?: string, matricolaStudente?: string, hasDelibera?: boolean, isNotPresuntaNonDefinita?: boolean, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (denomDescrAttivita !== undefined) {
            formParams = formParams.append('denomDescrAttivita', <any>denomDescrAttivita) as any || formParams;
        }
        if (dettaglioAttivitaTrasversale !== undefined) {
            formParams = formParams.append('dettaglioAttivitaTrasversale', <any>dettaglioAttivitaTrasversale) as any || formParams;
        }
        if (annoInsegnamento !== undefined) {
            formParams = formParams.append('annoInsegnamento', <any>annoInsegnamento) as any || formParams;
        }
        if (tipo !== undefined) {
            formParams = formParams.append('tipo', <any>tipo) as any || formParams;
        }
        if (statiAttivitaTrasversale) {
            statiAttivitaTrasversale.forEach((element) => {
                formParams = formParams.append('statiAttivitaTrasversale', <any>element) as any || formParams;
            })
        }
        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (emailStudente !== undefined) {
            formParams = formParams.append('emailStudente', <any>emailStudente) as any || formParams;
        }
        if (cfStudente !== undefined) {
            formParams = formParams.append('cfStudente', <any>cfStudente) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }
        if (hasDelibera !== undefined) {
            formParams = formParams.append('hasDelibera', <any>hasDelibera) as any || formParams;
        }
        if (isNotPresuntaNonDefinita !== undefined) {
            formParams = formParams.append('isNotPresuntaNonDefinita', <any>isNotPresuntaNonDefinita) as any || formParams;
        }

        return this.httpClient.request<PageCorsoPianoDiStudiPerExportViewImpl>('post',`${this.basePath}/api/v1/studente-ciclo/search-attivita-trasversali`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce la lista paginata delle spese degli studenti con i filtri applicati.
     *
     * @param codiceSpesa
     * @param tipologiaSpesa
     * @param statiSpesa
     * @param descrizioneNoteSpesa
     * @param nominativoStudente
     * @param emailStudente
     * @param cfStudente
     * @param matricolaStudente
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSpeseStudentiCicloForm(codiceSpesa?: string, tipologiaSpesa?: SpesaStudenteType, statiSpesa?: Array<SpesaStudenteStatus>, descrizioneNoteSpesa?: string, nominativoStudente?: string, emailStudente?: string, cfStudente?: string, matricolaStudente?: string, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageSpesaStudentePerApprovMassivaInfoView>;
    public searchSpeseStudentiCicloForm(codiceSpesa?: string, tipologiaSpesa?: SpesaStudenteType, statiSpesa?: Array<SpesaStudenteStatus>, descrizioneNoteSpesa?: string, nominativoStudente?: string, emailStudente?: string, cfStudente?: string, matricolaStudente?: string, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSpesaStudentePerApprovMassivaInfoView>>;
    public searchSpeseStudentiCicloForm(codiceSpesa?: string, tipologiaSpesa?: SpesaStudenteType, statiSpesa?: Array<SpesaStudenteStatus>, descrizioneNoteSpesa?: string, nominativoStudente?: string, emailStudente?: string, cfStudente?: string, matricolaStudente?: string, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSpesaStudentePerApprovMassivaInfoView>>;
    public searchSpeseStudentiCicloForm(codiceSpesa?: string, tipologiaSpesa?: SpesaStudenteType, statiSpesa?: Array<SpesaStudenteStatus>, descrizioneNoteSpesa?: string, nominativoStudente?: string, emailStudente?: string, cfStudente?: string, matricolaStudente?: string, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (codiceSpesa !== undefined) {
            formParams = formParams.append('codiceSpesa', <any>codiceSpesa) as any || formParams;
        }
        if (tipologiaSpesa !== undefined) {
            formParams = formParams.append('tipologiaSpesa', <any>tipologiaSpesa) as any || formParams;
        }
        if (statiSpesa) {
            statiSpesa.forEach((element) => {
                formParams = formParams.append('statiSpesa', <any>element) as any || formParams;
            })
        }
        if (descrizioneNoteSpesa !== undefined) {
            formParams = formParams.append('descrizioneNoteSpesa', <any>descrizioneNoteSpesa) as any || formParams;
        }
        if (nominativoStudente !== undefined) {
            formParams = formParams.append('nominativoStudente', <any>nominativoStudente) as any || formParams;
        }
        if (emailStudente !== undefined) {
            formParams = formParams.append('emailStudente', <any>emailStudente) as any || formParams;
        }
        if (cfStudente !== undefined) {
            formParams = formParams.append('cfStudente', <any>cfStudente) as any || formParams;
        }
        if (matricolaStudente !== undefined) {
            formParams = formParams.append('matricolaStudente', <any>matricolaStudente) as any || formParams;
        }

        return this.httpClient.request<PageSpesaStudentePerApprovMassivaInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/search-spese`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce una proiezione leggera degli studenti ricercati con i filtri applicati.
     *
     * @param nominativo
     * @param codiceFiscale
     * @param statiStudente
     * @param motiviStatoStudente
     * @param ateneoProvenienza
     * @param nazionalita
     * @param idCorsoInOffertaFormativa
     * @param corsoSuperato
     * @param statiUltimoAggiornamentoPianoDiStudi
     * @param isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi
     * @param statoSpesaStudente
     * @param statoUltimoAggiornamentoBudgetStudente
     * @param codiceFiscaleSupervisore
     * @param ssdSupervisore
     * @param afferenzaOrganizzativaSupervisore
     * @param codiceFiscaleCosupervisore
     * @param ssdCosupervisore
     * @param afferenzaOrganizzativaCosupervisore
     * @param codiceFiscaleRelatore
     * @param codiceFiscaleDocenteCorso
     * @param statiPeriodiDiMobilita
     * @param codiceTipoBorsaPerCiclo
     * @param statoPeriodoRegistroAttivita
     * @param hasDeliberaDiProgrammazioneMobilita
     * @param isInRegolaTasse
     * @param hasDeliberaSuUltimoAggiornamentoPianoDiStudi
     * @param hasDeliberaInizioAttivita
     * @param hasNotSupervisore
     * @param hasNotCosupervisore
     * @param hasAutorizzazioneMobilita
     * @param hasNumGiorniMobilitaInferioreAlMinimoPrevisto
     * @param hasNumGiorniMobilitaSuperioreAlPrevisto
     * @param statiMissioneDaApprovare
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc),(ignorecase)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchStudentiViewForm(nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, idCorsoInOffertaFormativa?: string, corsoSuperato?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, codiceFiscaleSupervisore?: string, ssdSupervisore?: string, afferenzaOrganizzativaSupervisore?: string, codiceFiscaleCosupervisore?: string, ssdCosupervisore?: string, afferenzaOrganizzativaCosupervisore?: string, codiceFiscaleRelatore?: string, codiceFiscaleDocenteCorso?: string, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, codiceTipoBorsaPerCiclo?: string, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, hasDeliberaDiProgrammazioneMobilita?: boolean, isInRegolaTasse?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotSupervisore?: boolean, hasNotCosupervisore?: boolean, hasAutorizzazioneMobilita?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, statiMissioneDaApprovare?: Array<MissioneStatus>, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageStudenteCicloIdAndUtenteInfoView>;
    public searchStudentiViewForm(nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, idCorsoInOffertaFormativa?: string, corsoSuperato?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, codiceFiscaleSupervisore?: string, ssdSupervisore?: string, afferenzaOrganizzativaSupervisore?: string, codiceFiscaleCosupervisore?: string, ssdCosupervisore?: string, afferenzaOrganizzativaCosupervisore?: string, codiceFiscaleRelatore?: string, codiceFiscaleDocenteCorso?: string, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, codiceTipoBorsaPerCiclo?: string, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, hasDeliberaDiProgrammazioneMobilita?: boolean, isInRegolaTasse?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotSupervisore?: boolean, hasNotCosupervisore?: boolean, hasAutorizzazioneMobilita?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, statiMissioneDaApprovare?: Array<MissioneStatus>, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStudenteCicloIdAndUtenteInfoView>>;
    public searchStudentiViewForm(nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, idCorsoInOffertaFormativa?: string, corsoSuperato?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, codiceFiscaleSupervisore?: string, ssdSupervisore?: string, afferenzaOrganizzativaSupervisore?: string, codiceFiscaleCosupervisore?: string, ssdCosupervisore?: string, afferenzaOrganizzativaCosupervisore?: string, codiceFiscaleRelatore?: string, codiceFiscaleDocenteCorso?: string, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, codiceTipoBorsaPerCiclo?: string, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, hasDeliberaDiProgrammazioneMobilita?: boolean, isInRegolaTasse?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotSupervisore?: boolean, hasNotCosupervisore?: boolean, hasAutorizzazioneMobilita?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, statiMissioneDaApprovare?: Array<MissioneStatus>, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStudenteCicloIdAndUtenteInfoView>>;
    public searchStudentiViewForm(nominativo?: string, codiceFiscale?: string, statiStudente?: Array<StudenteCicloStatus>, motiviStatoStudente?: Array<StudenteCicloMotivoStatus>, ateneoProvenienza?: string, nazionalita?: string, idCorsoInOffertaFormativa?: string, corsoSuperato?: boolean, statiUltimoAggiornamentoPianoDiStudi?: Array<AggiornamentoPianoDiStudiStatus>, isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi?: boolean, statoSpesaStudente?: SpesaStudenteStatus, statoUltimoAggiornamentoBudgetStudente?: AggiornamentoBudgetStudenteStatus, codiceFiscaleSupervisore?: string, ssdSupervisore?: string, afferenzaOrganizzativaSupervisore?: string, codiceFiscaleCosupervisore?: string, ssdCosupervisore?: string, afferenzaOrganizzativaCosupervisore?: string, codiceFiscaleRelatore?: string, codiceFiscaleDocenteCorso?: string, statiPeriodiDiMobilita?: Array<PeriodoDiMobilitaStudenteStatus>, codiceTipoBorsaPerCiclo?: string, statoPeriodoRegistroAttivita?: PeriodoRegistroAttivitaStatus, hasDeliberaDiProgrammazioneMobilita?: boolean, isInRegolaTasse?: boolean, hasDeliberaSuUltimoAggiornamentoPianoDiStudi?: boolean, hasDeliberaInizioAttivita?: boolean, hasNotSupervisore?: boolean, hasNotCosupervisore?: boolean, hasAutorizzazioneMobilita?: boolean, hasNumGiorniMobilitaInferioreAlMinimoPrevisto?: boolean, hasNumGiorniMobilitaSuperioreAlPrevisto?: boolean, statiMissioneDaApprovare?: Array<MissioneStatus>, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





































        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (codiceFiscale !== undefined) {
            formParams = formParams.append('codiceFiscale', <any>codiceFiscale) as any || formParams;
        }
        if (statiStudente) {
            statiStudente.forEach((element) => {
                formParams = formParams.append('statiStudente', <any>element) as any || formParams;
            })
        }
        if (motiviStatoStudente) {
            motiviStatoStudente.forEach((element) => {
                formParams = formParams.append('motiviStatoStudente', <any>element) as any || formParams;
            })
        }
        if (ateneoProvenienza !== undefined) {
            formParams = formParams.append('ateneoProvenienza', <any>ateneoProvenienza) as any || formParams;
        }
        if (nazionalita !== undefined) {
            formParams = formParams.append('nazionalita', <any>nazionalita) as any || formParams;
        }
        if (idCorsoInOffertaFormativa !== undefined) {
            formParams = formParams.append('idCorsoInOffertaFormativa', <any>idCorsoInOffertaFormativa) as any || formParams;
        }
        if (corsoSuperato !== undefined) {
            formParams = formParams.append('corsoSuperato', <any>corsoSuperato) as any || formParams;
        }
        if (statiUltimoAggiornamentoPianoDiStudi) {
            statiUltimoAggiornamentoPianoDiStudi.forEach((element) => {
                formParams = formParams.append('statiUltimoAggiornamentoPianoDiStudi', <any>element) as any || formParams;
            })
        }
        if (isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi', <any>isNuovaPresentazioneSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }
        if (statoSpesaStudente !== undefined) {
            formParams = formParams.append('statoSpesaStudente', <any>statoSpesaStudente) as any || formParams;
        }
        if (statoUltimoAggiornamentoBudgetStudente !== undefined) {
            formParams = formParams.append('statoUltimoAggiornamentoBudgetStudente', <any>statoUltimoAggiornamentoBudgetStudente) as any || formParams;
        }
        if (codiceFiscaleSupervisore !== undefined) {
            formParams = formParams.append('codiceFiscaleSupervisore', <any>codiceFiscaleSupervisore) as any || formParams;
        }
        if (ssdSupervisore !== undefined) {
            formParams = formParams.append('ssdSupervisore', <any>ssdSupervisore) as any || formParams;
        }
        if (afferenzaOrganizzativaSupervisore !== undefined) {
            formParams = formParams.append('afferenzaOrganizzativaSupervisore', <any>afferenzaOrganizzativaSupervisore) as any || formParams;
        }
        if (codiceFiscaleCosupervisore !== undefined) {
            formParams = formParams.append('codiceFiscaleCosupervisore', <any>codiceFiscaleCosupervisore) as any || formParams;
        }
        if (ssdCosupervisore !== undefined) {
            formParams = formParams.append('ssdCosupervisore', <any>ssdCosupervisore) as any || formParams;
        }
        if (afferenzaOrganizzativaCosupervisore !== undefined) {
            formParams = formParams.append('afferenzaOrganizzativaCosupervisore', <any>afferenzaOrganizzativaCosupervisore) as any || formParams;
        }
        if (codiceFiscaleRelatore !== undefined) {
            formParams = formParams.append('codiceFiscaleRelatore', <any>codiceFiscaleRelatore) as any || formParams;
        }
        if (codiceFiscaleDocenteCorso !== undefined) {
            formParams = formParams.append('codiceFiscaleDocenteCorso', <any>codiceFiscaleDocenteCorso) as any || formParams;
        }
        if (statiPeriodiDiMobilita) {
            statiPeriodiDiMobilita.forEach((element) => {
                formParams = formParams.append('statiPeriodiDiMobilita', <any>element) as any || formParams;
            })
        }
        if (codiceTipoBorsaPerCiclo !== undefined) {
            formParams = formParams.append('codiceTipoBorsaPerCiclo', <any>codiceTipoBorsaPerCiclo) as any || formParams;
        }
        if (statoPeriodoRegistroAttivita !== undefined) {
            formParams = formParams.append('statoPeriodoRegistroAttivita', <any>statoPeriodoRegistroAttivita) as any || formParams;
        }
        if (hasDeliberaDiProgrammazioneMobilita !== undefined) {
            formParams = formParams.append('hasDeliberaDiProgrammazioneMobilita', <any>hasDeliberaDiProgrammazioneMobilita) as any || formParams;
        }
        if (isInRegolaTasse !== undefined) {
            formParams = formParams.append('isInRegolaTasse', <any>isInRegolaTasse) as any || formParams;
        }
        if (hasDeliberaSuUltimoAggiornamentoPianoDiStudi !== undefined) {
            formParams = formParams.append('hasDeliberaSuUltimoAggiornamentoPianoDiStudi', <any>hasDeliberaSuUltimoAggiornamentoPianoDiStudi) as any || formParams;
        }
        if (hasDeliberaInizioAttivita !== undefined) {
            formParams = formParams.append('hasDeliberaInizioAttivita', <any>hasDeliberaInizioAttivita) as any || formParams;
        }
        if (hasNotSupervisore !== undefined) {
            formParams = formParams.append('hasNotSupervisore', <any>hasNotSupervisore) as any || formParams;
        }
        if (hasNotCosupervisore !== undefined) {
            formParams = formParams.append('hasNotCosupervisore', <any>hasNotCosupervisore) as any || formParams;
        }
        if (hasAutorizzazioneMobilita !== undefined) {
            formParams = formParams.append('hasAutorizzazioneMobilita', <any>hasAutorizzazioneMobilita) as any || formParams;
        }
        if (hasNumGiorniMobilitaInferioreAlMinimoPrevisto !== undefined) {
            formParams = formParams.append('hasNumGiorniMobilitaInferioreAlMinimoPrevisto', <any>hasNumGiorniMobilitaInferioreAlMinimoPrevisto) as any || formParams;
        }
        if (hasNumGiorniMobilitaSuperioreAlPrevisto !== undefined) {
            formParams = formParams.append('hasNumGiorniMobilitaSuperioreAlPrevisto', <any>hasNumGiorniMobilitaSuperioreAlPrevisto) as any || formParams;
        }
        if (statiMissioneDaApprovare) {
            statiMissioneDaApprovare.forEach((element) => {
                formParams = formParams.append('statiMissioneDaApprovare', <any>element) as any || formParams;
            })
        }

        return this.httpClient.request<PageStudenteCicloIdAndUtenteInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/view/search`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al Dottorando di richiede via email il settaggio dell&#x27;UPB per il corso e anno che sta frequentando.
     *
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendMailDiRichiestaDefinizioneUPB(id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<UpbCicloCorsoDiStudiInfoView>;
    public sendMailDiRichiestaDefinizioneUPB(id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpbCicloCorsoDiStudiInfoView>>;
    public sendMailDiRichiestaDefinizioneUPB(id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpbCicloCorsoDiStudiInfoView>>;
    public sendMailDiRichiestaDefinizioneUPB(id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling sendMailDiRichiestaDefinizioneUPB.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UpbCicloCorsoDiStudiInfoView>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/richiedi-definizione-upb`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Modifica il budget di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBudgetStudente(body: UpdateBudgetStudenteRequest, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<BudgetStudenteInfoViewImpl>;
    public updateBudgetStudente(body: UpdateBudgetStudenteRequest, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BudgetStudenteInfoViewImpl>>;
    public updateBudgetStudente(body: UpdateBudgetStudenteRequest, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BudgetStudenteInfoViewImpl>>;
    public updateBudgetStudente(body: UpdateBudgetStudenteRequest, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateBudgetStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateBudgetStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<BudgetStudenteInfoViewImpl>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/budget`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di aggiornare la delibera di approvazione associata ad un periodo di mobilità.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeliberaPeriodoDiMobilita(body: UpdateDeliberaPeriodoDiMobilitaRequest, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public updateDeliberaPeriodoDiMobilita(body: UpdateDeliberaPeriodoDiMobilitaRequest, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public updateDeliberaPeriodoDiMobilita(body: UpdateDeliberaPeriodoDiMobilitaRequest, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public updateDeliberaPeriodoDiMobilita(body: UpdateDeliberaPeriodoDiMobilitaRequest, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateDeliberaPeriodoDiMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateDeliberaPeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling updateDeliberaPeriodoDiMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/update-delibera`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il dettaglio borsa di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param dettaglioBorsaStudente
     * @param multipartFile1
     * @param multipartFile2
     * @param multipartFile3
     * @param multipartFile4
     * @param multipartFile5
     * @param multipartFile6
     * @param multipartFile7
     * @param multipartFile8
     * @param multipartFile9
     * @param multipartFile10
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDettaglioBorsaStudenteForm(id_utente: string, dettaglioBorsaStudente?: DettaglioBorsaStudenteUpdateDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, multipartFile6?: Blob, multipartFile7?: Blob, multipartFile8?: Blob, multipartFile9?: Blob, multipartFile10?: Blob, observe?: 'body', reportProgress?: boolean): Observable<DettaglioBorsaStudente>;
    public updateDettaglioBorsaStudenteForm(id_utente: string, dettaglioBorsaStudente?: DettaglioBorsaStudenteUpdateDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, multipartFile6?: Blob, multipartFile7?: Blob, multipartFile8?: Blob, multipartFile9?: Blob, multipartFile10?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DettaglioBorsaStudente>>;
    public updateDettaglioBorsaStudenteForm(id_utente: string, dettaglioBorsaStudente?: DettaglioBorsaStudenteUpdateDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, multipartFile6?: Blob, multipartFile7?: Blob, multipartFile8?: Blob, multipartFile9?: Blob, multipartFile10?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DettaglioBorsaStudente>>;
    public updateDettaglioBorsaStudenteForm(id_utente: string, dettaglioBorsaStudente?: DettaglioBorsaStudenteUpdateDTO, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, multipartFile6?: Blob, multipartFile7?: Blob, multipartFile8?: Blob, multipartFile9?: Blob, multipartFile10?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateDettaglioBorsaStudente.');
        }












        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (dettaglioBorsaStudente !== undefined) {
            formParams = formParams.append('dettaglioBorsaStudente', new Blob([JSON.stringify(dettaglioBorsaStudente)], { type: 'application/json'})) as any || formParams;
        }
        if (multipartFile1 !== undefined) {
            formParams = formParams.append('multipartFile1', <any>multipartFile1) as any || formParams;
        }
        if (multipartFile2 !== undefined) {
            formParams = formParams.append('multipartFile2', <any>multipartFile2) as any || formParams;
        }
        if (multipartFile3 !== undefined) {
            formParams = formParams.append('multipartFile3', <any>multipartFile3) as any || formParams;
        }
        if (multipartFile4 !== undefined) {
            formParams = formParams.append('multipartFile4', <any>multipartFile4) as any || formParams;
        }
        if (multipartFile5 !== undefined) {
            formParams = formParams.append('multipartFile5', <any>multipartFile5) as any || formParams;
        }
        if (multipartFile6 !== undefined) {
            formParams = formParams.append('multipartFile6', <any>multipartFile6) as any || formParams;
        }
        if (multipartFile7 !== undefined) {
            formParams = formParams.append('multipartFile7', <any>multipartFile7) as any || formParams;
        }
        if (multipartFile8 !== undefined) {
            formParams = formParams.append('multipartFile8', <any>multipartFile8) as any || formParams;
        }
        if (multipartFile9 !== undefined) {
            formParams = formParams.append('multipartFile9', <any>multipartFile9) as any || formParams;
        }
        if (multipartFile10 !== undefined) {
            formParams = formParams.append('multipartFile10', <any>multipartFile10) as any || formParams;
        }

        return this.httpClient.request<DettaglioBorsaStudente>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/dettaglio-borsa`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna un elemento del registro attività di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param id_attivita id dell&#x27;attività da modificare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, id_attivita: string, observe?: 'body', reportProgress?: boolean): Observable<RegistroAttivitaInfoView>;
    public updateRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, id_attivita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RegistroAttivitaInfoView>>;
    public updateRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, id_attivita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RegistroAttivitaInfoView>>;
    public updateRegistroAttivita(body: AddRegistroAttivitaDTO, id_utente: string, id_attivita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateRegistroAttivita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateRegistroAttivita.');
        }

        if (id_attivita === null || id_attivita === undefined) {
            throw new Error('Required parameter id_attivita was null or undefined when calling updateRegistroAttivita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<RegistroAttivitaInfoView>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/registro-attivita/${encodeURIComponent(String(id_attivita))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna il Supervisore di uno studente.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSupervisoreStudente(body: UpdateSupervisoreStudenteRequestDTO, id_utente: string, observe?: 'body', reportProgress?: boolean): Observable<UpdateSupervisoreStudenteResponseDTO>;
    public updateSupervisoreStudente(body: UpdateSupervisoreStudenteRequestDTO, id_utente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateSupervisoreStudenteResponseDTO>>;
    public updateSupervisoreStudente(body: UpdateSupervisoreStudenteRequestDTO, id_utente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateSupervisoreStudenteResponseDTO>>;
    public updateSupervisoreStudente(body: UpdateSupervisoreStudenteRequestDTO, id_utente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateSupervisoreStudente.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling updateSupervisoreStudente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateSupervisoreStudenteResponseDTO>('put',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/supervisore`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un coordinatore di validare un periodo registro attività di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param esitoRichiestaApprovazione
     * @param fileAllegato
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaPeriodoRegistroAttivitaByCoordinatoreForm(id_utente: string, id_periodo_registro_attivita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Blob, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public validaPeriodoRegistroAttivitaByCoordinatoreForm(id_utente: string, id_periodo_registro_attivita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaByCoordinatoreForm(id_utente: string, id_periodo_registro_attivita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaByCoordinatoreForm(id_utente: string, id_periodo_registro_attivita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaPeriodoRegistroAttivitaByCoordinatore.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling validaPeriodoRegistroAttivitaByCoordinatore.');
        }



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAllegato !== undefined) {
            formParams = formParams.append('fileAllegato', <any>fileAllegato) as any || formParams;
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}/coordinatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad uno studente di caricate l&#x27;allegato in una richiesta di periodo registro attività.
     *
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param fileAllegato
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaPeriodoRegistroAttivitaByStudenteForm(id_utente: string, id_periodo_registro_attivita: string, fileAllegato?: Blob, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public validaPeriodoRegistroAttivitaByStudenteForm(id_utente: string, id_periodo_registro_attivita: string, fileAllegato?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaByStudenteForm(id_utente: string, id_periodo_registro_attivita: string, fileAllegato?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaByStudenteForm(id_utente: string, id_periodo_registro_attivita: string, fileAllegato?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaPeriodoRegistroAttivitaByStudente.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling validaPeriodoRegistroAttivitaByStudente.');
        }


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fileAllegato !== undefined) {
            formParams = formParams.append('fileAllegato', <any>fileAllegato) as any || formParams;
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}/studente`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette ad un supervisore di validare un periodo registro attività di uno studente.
     *
     * @param id_utente id dell&#x27;utente
     * @param id_periodo_registro_attivita id del periodo registro attività
     * @param esitoRichiestaApprovazione
     * @param fileAllegato
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaPeriodoRegistroAttivitaBySupervisoreForm(id_utente: string, id_periodo_registro_attivita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Blob, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoRegistroAttivitaInfoView>>;
    public validaPeriodoRegistroAttivitaBySupervisoreForm(id_utente: string, id_periodo_registro_attivita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaBySupervisoreForm(id_utente: string, id_periodo_registro_attivita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoRegistroAttivitaInfoView>>>;
    public validaPeriodoRegistroAttivitaBySupervisoreForm(id_utente: string, id_periodo_registro_attivita: string, esitoRichiestaApprovazione?: EsitoRichiestaApprovazione, fileAllegato?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaPeriodoRegistroAttivitaBySupervisore.');
        }

        if (id_periodo_registro_attivita === null || id_periodo_registro_attivita === undefined) {
            throw new Error('Required parameter id_periodo_registro_attivita was null or undefined when calling validaPeriodoRegistroAttivitaBySupervisore.');
        }



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (esitoRichiestaApprovazione !== undefined) {
            formParams = formParams.append('esitoRichiestaApprovazione', new Blob([JSON.stringify(esitoRichiestaApprovazione)], { type: 'application/json'})) as any || formParams;
        }
        if (fileAllegato !== undefined) {
            formParams = formParams.append('fileAllegato', <any>fileAllegato) as any || formParams;
        }

        return this.httpClient.request<Array<PeriodoRegistroAttivitaInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/periodi-registro-attivita/${encodeURIComponent(String(id_periodo_registro_attivita))}/supervisore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al PTA_ATENEO_MOBILITA di validare una richiesta di retribuzione su un periodo di mobilità.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validaPtaRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public validaPtaRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public validaPtaRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public validaPtaRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling validaPtaRichiestaDiRetribuzioneMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling validaPtaRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling validaPtaRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling validaPtaRichiestaDiRetribuzioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/valida-pta`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al supervisore/cosupervisore di approvare o rifiutare una richiesta di conclusione periodo di mobilità.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaParzialeRichiestaDiConclusionePeriodoDiMobilita(body: EsitoRichiestaConclusioneMobilita, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public verificaParzialeRichiestaDiConclusionePeriodoDiMobilita(body: EsitoRichiestaConclusioneMobilita, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaParzialeRichiestaDiConclusionePeriodoDiMobilita(body: EsitoRichiestaConclusioneMobilita, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaParzialeRichiestaDiConclusionePeriodoDiMobilita(body: EsitoRichiestaConclusioneMobilita, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling verificaParzialeRichiestaDiConclusionePeriodoDiMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaParzialeRichiestaDiConclusionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling verificaParzialeRichiestaDiConclusionePeriodoDiMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/verifica-parziale-richiesta-conclusione`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al Supervisore o Cosupervisore di verificare una richiesta di retribuzione su un periodo di mobilità.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaParzialeRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public verificaParzialeRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaParzialeRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaParzialeRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling verificaParzialeRichiestaDiRetribuzioneMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaParzialeRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling verificaParzialeRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling verificaParzialeRichiestaDiRetribuzioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/verifica-parziale`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al coordinatore di approvare o rifiutare una richiesta di conclusione periodo di mobilità.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaRichiestaDiConclusionePeriodoDiMobilita(body: EsitoRichiestaConclusioneMobilita, id_utente: string, codice_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public verificaRichiestaDiConclusionePeriodoDiMobilita(body: EsitoRichiestaConclusioneMobilita, id_utente: string, codice_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaRichiestaDiConclusionePeriodoDiMobilita(body: EsitoRichiestaConclusioneMobilita, id_utente: string, codice_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaRichiestaDiConclusionePeriodoDiMobilita(body: EsitoRichiestaConclusioneMobilita, id_utente: string, codice_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling verificaRichiestaDiConclusionePeriodoDiMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaRichiestaDiConclusionePeriodoDiMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling verificaRichiestaDiConclusionePeriodoDiMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/verifica-richiesta-conclusione`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette al Coordinatore di verificare una richiesta di retribuzione su un periodo di mobilità.
     *
     * @param body
     * @param id_utente id dell&#x27;utente
     * @param codice_mobilita codice univoco del periodo di mobilità
     * @param id_richiesta_di_retribuzione_mobilita codice univoco della richiesta di retribuzione mobilità
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PeriodoDiMobilitaStudenteInfoView>>;
    public verificaRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PeriodoDiMobilitaStudenteInfoView>>>;
    public verificaRichiestaDiRetribuzioneMobilita(body: EsitoRichiestaRetribuzioneMobilita, id_utente: string, codice_mobilita: string, id_richiesta_di_retribuzione_mobilita: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling verificaRichiestaDiRetribuzioneMobilita.');
        }

        if (id_utente === null || id_utente === undefined) {
            throw new Error('Required parameter id_utente was null or undefined when calling verificaRichiestaDiRetribuzioneMobilita.');
        }

        if (codice_mobilita === null || codice_mobilita === undefined) {
            throw new Error('Required parameter codice_mobilita was null or undefined when calling verificaRichiestaDiRetribuzioneMobilita.');
        }

        if (id_richiesta_di_retribuzione_mobilita === null || id_richiesta_di_retribuzione_mobilita === undefined) {
            throw new Error('Required parameter id_richiesta_di_retribuzione_mobilita was null or undefined when calling verificaRichiestaDiRetribuzioneMobilita.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PeriodoDiMobilitaStudenteInfoView>>('post',`${this.basePath}/api/v1/studente-ciclo/${encodeURIComponent(String(id_utente))}/mobilita/${encodeURIComponent(String(codice_mobilita))}/richiesta-di-retribuzione/${encodeURIComponent(String(id_richiesta_di_retribuzione_mobilita))}/verifica`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

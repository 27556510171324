import {Component, Inject, OnInit, Sanitizer} from '@angular/core';
import {ClickEvent, TipoClickEnum} from "../../../../../shared/components/table/model/generic-table-model";
import {
    AddDeliberaDTO,
    AuthorityType,
    DeliberaInfoView,
    DeliberaInfoViewImpl,
    DelibereService, MediaService,
    MobilitaRequestSelectFormValuesDTO,
    PageDeliberaInfoViewImpl,
    PeriodiDiMobilitaService,
    PeriodoDiMobilitaStudenteInfoView,
    StudentiCicloService,
    TipoPeriodoEnum
} from "../../../../../../api-clients/generated/services";
import {BehaviorSubject, finalize, map, startWith, switchMap, take, takeUntil, tap} from "rxjs";
import {
    AbstractDefaultComponent
} from "../../../../../shared/abstracts/abstract-default-component/abstract-default-component";
import {TranslocoService} from "@ngneat/transloco";
import * as moment from "moment/moment";
import {
    formGroupConfigInterface,
    GenericComponentDialogConfig
} from "../../../../../layout/common/generic-components/generic-components.interface";
import {TypeDialogFormEnum} from "../../../../../layout/common/generic-components/generic-components-enum";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApprovazioneMobilitaMassivaDataI} from '../approvazione-mobilita-steps-interface';
import {CAN_GO_AHEAD$, OPERAZIONE_MASSIVA_DATA$} from "../../operazioni-massive.component";
import {get, orderBy} from "lodash";
import {optionFile} from "../../../../../shared/costants/app-constants";
import {
    GenericDialogComponent
} from "../../../../../layout/common/generic-components/generic-dialog/generic-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {SnackbarTypes} from "../../../../../../@fuse/services/confirmation/snackbar/snackbar.component";
import {FuseConfirmationService} from "../../../../../../@fuse/services/confirmation";
import {AppInitService} from "../../../../../shared/service/app-init.service";
import {makeFilename} from "../../../../../shared/utils/utils";
import {LogoutService} from "../../../../../shared/service/logout.service";
import {CustomValidators} from "../../../../../shared/validators/custom-validators";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {DialogInfoComponent, DialogInfoI} from "../../../../../shared/components/dialog-info/dialog-info.component";
import {UpperCasePipe} from "@angular/common";


@Component({
    selector: 'app-info-approvazione-mobilita-stepper',
    templateUrl: './compila-info-approvazione-mobilita.component.html',
    styleUrls: ['./compila-info-approvazione-mobilita.component.scss']
})
export class CompilaInfoApprovazioneMobilitaComponent extends AbstractDefaultComponent implements OnInit {

    loading: boolean;
    formConfig: formGroupConfigInterface[];
    valueForm: any;
    form: FormGroup;
    periodiSelected: PeriodoDiMobilitaStudenteInfoView[];
    delibere: DeliberaInfoViewImpl[];
    outputFormatData = 'DD/MM/YYYY';
    periodiDetailsFormArray: FormArray;
    formSelectValues: MobilitaRequestSelectFormValuesDTO;
    protected readonly AuthorityType = AuthorityType;
    protected readonly TipoPeriodoEnum = TipoPeriodoEnum;

    constructor(private translocoService: TranslocoService,
                private fuseConfirmationService: FuseConfirmationService,
                private appInitService: AppInitService,
                private delibereService: DelibereService,
                private fb: FormBuilder,
                private dialog: MatDialog,
                private studentiCicloService: StudentiCicloService,
                private logoutService: LogoutService,
                private periodiDiMobilitaService: PeriodiDiMobilitaService,
                @Inject(CAN_GO_AHEAD$) protected canGoNext$: BehaviorSubject<boolean>,
                @Inject(OPERAZIONE_MASSIVA_DATA$) protected operazioneMassivaData$: BehaviorSubject<ApprovazioneMobilitaMassivaDataI>,) {
        super();
    }

    ngOnInit(): void {
        this.initForm();
        this.operazioneMassivaData$.subscribe((inputOutputData: ApprovazioneMobilitaMassivaDataI) => {
            // if confirm step force refresh set as not completed the step
            if(inputOutputData.refreshPeriodi){
                this.canGoNext$.next(false);
                this.periodiDetailsFormArray = undefined;
            }
            // if studenti selected changes reset delibera form and get plans for selected students
            if(inputOutputData.periodiSelected !== this.periodiSelected){
                this.periodiSelected = inputOutputData?.periodiSelected;
                this.form.get('deliberaCollegio').setValue(inputOutputData.deliberaCollegio?.id, {emitEvent: false});
                this.buildPeriodiDetailsFormGroup(this.periodiSelected);
            }
        });
        // get delibere and form select values
        this.getDelibereRequestAndFormSelectValues();
    }


    formatDay(date: string) {
        return moment(new Date(date)).format('DD/MM/YYYY');
    }

    openAddNewDeliberaDialog() {
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        let confirmLabel = get(translation, 'dialog.confirm', null);
        let cancelLabel = get(translation, 'dialog.cancel', null);
        const data: GenericComponentDialogConfig = {
            title: 'common.add_delibera',
            //message: get(translation, 'mobility.approve_message', null),
            icon: {
                show: true,
                name: 'mat_outline:add',
                color: 'basic'
            },
            actions: {
                confirm: {
                    show: true,
                    label: confirmLabel,
                    color: 'primary',
                    function: (form, dialogRef) => this.addNewDeliberaRequest(form, form.getRawValue(), dialogRef)
                },
                cancel: {
                    show: true,
                    label: cancelLabel,
                }
            },
            dismissible: true,
            formConfig: [
                {
                    show: true,
                    name: 'fileDelibera',
                    transloco: 'mobility.delibera',
                    //required: true,
                    type: TypeDialogFormEnum.FILE,
                    optionFile: optionFile
                },
                {
                    show: true,
                    name: 'dataDelibera',
                    transloco: 'budget.approval_date',
                    required: true,
                    type: TypeDialogFormEnum.DATA,
                },
                {
                    show: true,
                    name: 'numeroDelibera',
                    transloco: 'budget.approval_number',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                },
            ],
            valueForm: {
                fileDelibera: undefined,
                dataDelibera: undefined,
                numeroDelibera: undefined
            }
        };
        this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: data.dismissible,
            disableClose: true,
        });
    }


    private addNewDeliberaRequest(form: FormGroup, rawValue: any, dialogRef: MatDialogRef<GenericDialogComponent>) {
        console.log(rawValue)
        const activeLang = this.translocoService.getActiveLang();
        const translation = this.translocoService.getTranslation().get(activeLang);
        const requestBody: AddDeliberaDTO = {
            numero: rawValue.numeroDelibera,
            data: rawValue.dataDelibera
        }
        this.fuseConfirmationService.showLoader();
        this.appInitService.isDipartimentoRuoloCicloSelectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.delibereService.addDeliberaForm(requestBody, rawValue.fileDelibera)),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (addedDelibera: DeliberaInfoView) => {
                this.fuseConfirmationService.openSnackBar({
                    message: get(translation,'common.operation_success_delibera_added', null),
                    type: SnackbarTypes.Success,
                    duration: 5000,
                });
                this.delibere.push(addedDelibera);
                this.delibere = orderBy(this.delibere, [delibera => moment(delibera.data)], ['desc']);
                this.form?.get('deliberaCollegio')?.patchValue(addedDelibera.id);
                dialogRef.close();
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.addNewDeliberaRequest(form, rawValue, dialogRef), 'dialog.cancel',
                    err?.error?.message);
            }
        });

    }

    private getDelibereRequestAndFormSelectValues() {
        this.fuseConfirmationService.showLoader();
        this.appInitService.selectedInService.pipe(
            filter(Boolean),
            take(1),
            switchMap(() => this.periodiDiMobilitaService.getMobilitaRequestSelectFormValues()),
            tap(selectValues => this.formSelectValues = selectValues),
            switchMap(() => this.delibereService.searchDelibereForm()),
            takeUntil(this.destroy$),
            finalize(() => {
                this.fuseConfirmationService.hideLoader();
            })
        ).subscribe({
            next: (delibere: PageDeliberaInfoViewImpl) => {
                this.delibere = delibere?.content || [];
            },
            error: (err) => {
                this.fuseConfirmationService.openErrorDialog({error: err}, this.translocoService,
                    () => {},() => this.getDelibereRequestAndFormSelectValues(),
                    'dialog.cancel',
                    err?.error?.message);
            }
        });
    }

    formatDeliberaDate(dateFromBE: string): string {
        return moment(dateFromBE).format(this.outputFormatData);
    }

    tableClickAction($event: ClickEvent) {

    }


    private initForm() {
        this.form = this.fb.group({
            deliberaCollegio: undefined,
        });
        this.form.get('deliberaCollegio').valueChanges.subscribe(deliberaId => {
            this.canGoNext$.next(this.periodiDetailsFormArray.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                deliberaCollegio: this.delibere?.find(d => d.id === deliberaId)
            });
        })
    }


    private buildPeriodiDetailsFormGroup(selectedPeriods: PeriodoDiMobilitaStudenteInfoView[]) {
        this.periodiDetailsFormArray = this.fb.array(selectedPeriods?.map(period =>
            this.getPeriodoDetailsForm(period)));
        // subscribe to form group change
        this.periodiDetailsFormArray.valueChanges?.pipe(startWith(this.periodiDetailsFormArray.value)).subscribe(fa => {
            console.log('details value changed', fa)
            this.canGoNext$.next(this.periodiDetailsFormArray.valid);
            this.operazioneMassivaData$.next({
                ...this.operazioneMassivaData$?.getValue(),
                compileInfo: fa?.map(value => ({
                    codiceMobilitaPeriodo: value.codiceMobilita,
                    idUtente: value.idUtente,
                    esito: {
                        approvato: true,
                        codice_tipologia_mobilita: value.tipologiaMobilita,
                        //numero_tipologia_bonus: formRawValue.tipologiaBonus,
                        doctor_europeaus: value.doctorEuropeaus.doctorEuropeausFlag || false,
                        sede_doctor_europeaus: value.doctorEuropeaus.sedeDoctorEuropeaus,
                        data_inizio: moment(value.dataInizio).format(this.outputFormatData),
                        data_fine: value.dataFine ? moment(value.dataFine).format(this.outputFormatData) : undefined,
                        erasmus: value.erasmus,
                    }
                }))
            });
        })
    }

    private getPeriodoDetailsForm(period: PeriodoDiMobilitaStudenteInfoView) {
        return this.fb.group({
            codiceMobilita: period?.codiceMobilita,
            idUtente: period?.studenteCiclo?.utente?.id,
            erasmus: period?.erasmus,
            dataInizio: this.fb.control(period?.dataInizio ? moment(period?.dataInizio, 'DD/MM/YYYY').toDate() : undefined),
            dataFine: this.fb.control(period?.dataFine ? moment(period?.dataFine, 'DD/MM/YYYY').toDate() : undefined),
            tipologiaMobilita: [period?.tipologiaMobilita?.codice, Validators.required],
            doctorEuropeaus: this.fb.group({
                doctorEuropeausFlag: period?.doctorEuropeaus,
                sedeDoctorEuropeaus: period?.sedeDoctorEuropeaus,
            }, {validators: CustomValidators.DoctorEuropeausValidator()}),
            deliberaCollegio: period?.delibera?.id,
        }, {validators: CustomValidators.dataAMaggioreDiDataA('dataInizio', 'dataFine', this.outputFormatData)});
    }

    clearDateField(fieldCtrl: AbstractControl) {
        fieldCtrl.setValue(undefined);
        fieldCtrl.setErrors(null);
    }


}

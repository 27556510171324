<div fxFlexFill fxLayout="column" fxLayoutAlign="center center">

    <ng-container *ngIf="showLogoSpinner; else tmpSpinner">
        <img
            class="w-28"
            src="assets/images/logo/segno_428_503.png"
            alt="atena">
        <div class="spinner">
            <div class="bounce1 bg-active-custom"></div>
            <div class="bounce2 bg-active-custom"></div>
            <div class="bounce3 bg-active-custom"></div>
        </div>
    </ng-container>

    <ng-template #tmpSpinner>
        <div class="container-spinner">
            <mat-spinner [diameter]="100" [mode]="'indeterminate'" [strokeWidth]="11"></mat-spinner>
        </div>
    </ng-template>



    <div *ngIf="message" class="flex items-center mt-4">
        <ng-container *transloco="let t">
            <mat-icon color="primary" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>

            <div class="ml-1.5 leading-6 truncate text-secondary">{{ t(message) }}</div>
        </ng-container>

    </div>
</div>

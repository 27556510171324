import {
    AnnoRiferimentoValues,
    ConfigurazioneCategoriaPerAnni,
    ConfigurazioneOffertaFormativa, CorsoAggiornamentoPianoDiStudi,
    ElementoOffertaFormativaType,
    OffertaFormativaInfoViewImpl,
    VincoliCfuPerTipoAttivita
} from "../../../../../../../api-clients/generated/services";
import {
    TrainingOfferCategoryDataUI
} from "../../../../training-offer-study-plan-shared-module/components/activities-categories/activities-categories.component";
import {annoInsegnamentoValueToNumber, annoRiferimentoFromRomanNumeral} from "../../../../../../shared/utils/utils";
import {
    StudyPlanAggiornamentoDataUI,
    StudyPlanCategoryDataUI
} from "../../../../../../shared/interface/piano-di-studi-data-u-i";
import {
    TrainingOfferCategoryByTypeDataUI,
    TrainingOfferCategoryByYearDataUI
} from "../../../../cycle/training-offer/training-offer.component";

export function getTotaleCFUOverConfYears(confForYear: ConfigurazioneCategoriaPerAnni, categoryConfiguration: TrainingOfferCategoryDataUI, pianoOrOffertaByYears: TrainingOfferCategoryByYearDataUI[]) {
    let totCfu = 0;
    const typeIndex = categoryConfiguration?.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? 0 : (categoryConfiguration?.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? 1 : 2);
    if (confForYear?.primo_anno) {
        totCfu += (pianoOrOffertaByYears?.[0]?.categoriesByActivityType?.[typeIndex]?.categoriesConfiguration?.find(c => c.id === categoryConfiguration?.id)?.totaleCfu ?? 0);
    }
    if (confForYear?.secondo_anno) {
        totCfu += (pianoOrOffertaByYears?.[1]?.categoriesByActivityType?.[typeIndex]?.categoriesConfiguration?.find(c => c.id === categoryConfiguration?.id)?.totaleCfu ?? 0);
    }
    if (confForYear?.terzo_anno) {
        totCfu += (pianoOrOffertaByYears?.[2]?.categoriesByActivityType?.[typeIndex]?.categoriesConfiguration?.find(c => c.id === categoryConfiguration?.id)?.totaleCfu ?? 0);
    }
    return totCfu;
}

export function getNumeroAttivitaOverConfYears(confForYear: ConfigurazioneCategoriaPerAnni, categoryConfiguration: TrainingOfferCategoryDataUI, pianoOrOffertaByYears: TrainingOfferCategoryByYearDataUI[]) {
    let numAtt = 0;
    const typeIndex = categoryConfiguration?.tipoAttivitaContenute === ElementoOffertaFormativaType.CORSO ? 0 : (categoryConfiguration?.tipoAttivitaContenute === ElementoOffertaFormativaType.ATTIVITAEXTRA ? 1 : 2);
    if(confForYear){
        if(confForYear.primo_anno){
            numAtt += (pianoOrOffertaByYears?.[0]?.categoriesByActivityType?.[typeIndex]?.categoriesConfiguration?.find(c => c.id === categoryConfiguration?.id)?.numeroAttivitaSelezionate ?? 0);
        }
        if(confForYear.secondo_anno){
            numAtt += (pianoOrOffertaByYears?.[1]?.categoriesByActivityType?.[typeIndex]?.categoriesConfiguration?.find(c => c.id === categoryConfiguration?.id)?.numeroAttivitaSelezionate ?? 0);
        }
        if(confForYear.terzo_anno){
            numAtt += (pianoOrOffertaByYears?.[2]?.categoriesByActivityType?.[typeIndex]?.categoriesConfiguration?.find(c => c.id === categoryConfiguration?.id)?.numeroAttivitaSelezionate ?? 0);
        }
    }
    return numAtt;
}




// compute the compliance of the whole draft of the plan
export function getDraftInEditModeCompliance(draftData: StudyPlanAggiornamentoDataUI, offertaFormativa: OffertaFormativaInfoViewImpl): boolean {
    draftData?.draftInEditModeCategoriesByYear?.forEach(categoriesByYear => {
        categoriesByYear?.categoriesByActivityType?.forEach(categoriesByType => {
            if(categoriesByType?.categoriesConfiguration?.length > 0){
                // foreach category compute the compliance
                categoriesByType?.categoriesConfiguration.forEach(category => {
                    category.compliant = getDraftInEditModeComplianceForCategory(category, draftData, offertaFormativa);
                });
            }
            // foreach type (corso, extra, trasv) compute the compliance of all the categories in it
            categoriesByType.compliant = getDraftInEditModeComplianceForType(categoriesByType, annoRiferimentoFromRomanNumeral(categoriesByYear.year), offertaFormativa);
        });
        // foreach year compute the compliance of all the categories in it
        categoriesByYear.compliant = getDraftInEditModeComplianceForYear(categoriesByYear)
    });
    // return the final compliance
    return draftData?.draftInEditModeCategoriesByYear
        ?.reduce(
            (prev, curr) => prev &&
                (curr.compliant || mustSkipCompliancyCheckForYear(annoRiferimentoFromRomanNumeral(curr.year), draftData, offertaFormativa?.configurazione)),
            true);
}

export function getDraftInReadModeCompliance(draftData: StudyPlanAggiornamentoDataUI, offertaFormativa: OffertaFormativaInfoViewImpl): boolean {
    draftData?.readModeCategoriesByYear?.forEach(categoriesByYear => {
        categoriesByYear?.categoriesByActivityType?.forEach(categoriesByType => {
            if(categoriesByType?.categoriesConfiguration?.length > 0){
                categoriesByType?.categoriesConfiguration.forEach(category => {
                    category.compliant = getDraftInReadModeComplianceForCategory(category, draftData, offertaFormativa);
                })
            }
            categoriesByType.compliant = getDraftInEditModeComplianceForType(categoriesByType, annoRiferimentoFromRomanNumeral(categoriesByYear.year), offertaFormativa);
        })
        categoriesByYear.compliant = getDraftInEditModeComplianceForYear(categoriesByYear)
    });
    return draftData?.readModeCategoriesByYear
        ?.reduce(
            (prev, curr) => prev &&
                (curr.compliant || mustSkipCompliancyCheckForYear(annoRiferimentoFromRomanNumeral(curr.year), draftData, offertaFormativa?.configurazione)),
            true);
}




export function getDraftInEditModeComplianceForCategory(category: TrainingOfferCategoryDataUI, draftData: StudyPlanAggiornamentoDataUI, offertaFormativa: OffertaFormativaInfoViewImpl): boolean {
    // check if cfu configuration for the category is met
    const cfuCompliance = getTotaleCFUOverConfYears(category.configurazioneForThisYear, category, draftData.draftInEditModeCategoriesByYear) >= category.configurazioneForThisYear?.numero_minimo_cfu;
    // check if num of mandatory activities configuration for the category is met
    const activityNumberCompliance = getNumeroAttivitaOverConfYears(category.configurazioneForThisYear, category, draftData.draftInEditModeCategoriesByYear) >= category.configurazioneForThisYear?.numero_attivita_obbligatorie;
    // check if mandatory transversal automatically added filled
    const mandatoryTransversalFilled = category.tipoAttivitaContenute !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE ? true
        : category.attivitaCategoriaAssociations?.reduce((prev, curr) => prev && curr.attivitaOffertaFormativa?.cfu !== 0, true)

    const skipCheckBecauseFutureYearInConfigExists = !offertaFormativa?.configurazione?.is_possibile_inserire_corsi_per_anni_successivi
        && getSkipComplianceCheckBecauseFutureYearInConfigExists(category, draftData);

    return category.isArchiviata || ((skipCheckBecauseFutureYearInConfigExists || (cfuCompliance && activityNumberCompliance)) && mandatoryTransversalFilled);
}

export function getDraftInReadModeComplianceForCategory(category: StudyPlanCategoryDataUI, draftData: StudyPlanAggiornamentoDataUI, offertaFormativa: OffertaFormativaInfoViewImpl): boolean {
    // check if cfu configuration for the category is met
    const cfuCompliance = getTotaleCFUOverConfYears(category.configurazioneForThisYear, category, draftData.readModeCategoriesByYear) >= category.configurazioneForThisYear?.numero_minimo_cfu;
    // check if num of mandatory activities configuration for the category is met
    const activityNumberCompliance = getNumeroAttivitaOverConfYears(category.configurazioneForThisYear, category, draftData.readModeCategoriesByYear) >= category.configurazioneForThisYear?.numero_attivita_obbligatorie;
    // check if mandatory transversal automatically added filled
    const mandatoryTransversalFilled = category.tipoAttivitaContenute !== ElementoOffertaFormativaType.ATTIVITATRASVERSALE ? true
        : category.corsi?.reduce((prev, curr) => prev && (curr as CorsoAggiornamentoPianoDiStudi)?.datiCorsoOffertaFormativa?.cfu !== 0, true)

    const skipCheckBecauseFutureYearInConfigExists = !offertaFormativa?.configurazione?.is_possibile_inserire_corsi_per_anni_successivi
        && getSkipComplianceCheckBecauseFutureYearInConfigExists(category, draftData);

    return category.isArchiviata || ((skipCheckBecauseFutureYearInConfigExists || (cfuCompliance && activityNumberCompliance)) && mandatoryTransversalFilled);
}

function getConfYearsSet(configurazioneForThisYear: ConfigurazioneCategoriaPerAnni) {
    const years = []
    if(configurazioneForThisYear.primo_anno){
        years.push(AnnoRiferimentoValues.PRIMO);
    }
    if(configurazioneForThisYear.secondo_anno){
        years.push(AnnoRiferimentoValues.SECONDO);
    }
    if(configurazioneForThisYear.terzo_anno){
        years.push(AnnoRiferimentoValues.TERZO);
    }
    return years;
}

function getStudentAnniCorsoEditable(annoCorso: AnnoRiferimentoValues) {
    const years = []
    if(annoCorso === AnnoRiferimentoValues.PRIMO){
        years.push(AnnoRiferimentoValues.PRIMO);
    }
    if(annoCorso === AnnoRiferimentoValues.SECONDO){
        years.push(AnnoRiferimentoValues.PRIMO);
        years.push(AnnoRiferimentoValues.SECONDO);
    }
    if(annoCorso === AnnoRiferimentoValues.TERZO){
        years.push(AnnoRiferimentoValues.PRIMO);
        years.push(AnnoRiferimentoValues.SECONDO);
        years.push(AnnoRiferimentoValues.TERZO);
    }
    return years;
}

export function getSkipComplianceCheckBecauseFutureYearInConfigExists(category: StudyPlanCategoryDataUI | TrainingOfferCategoryDataUI, draftData: StudyPlanAggiornamentoDataUI) {
    const studentYears = getStudentAnniCorsoEditable(draftData.annoCorso);
    const confYears = getConfYearsSet(category.configurazioneForThisYear);
    return !!confYears.find(yearInConf => !studentYears.includes(yearInConf))
}

export function getDraftInEditModeComplianceForType(categoriesByType: TrainingOfferCategoryByTypeDataUI, year: AnnoRiferimentoValues, offertaFormativa: OffertaFormativaInfoViewImpl): boolean {
    // derive the compliance from all compliances in the type
    const categoriesCompliances = categoriesByType?.categoriesConfiguration
        ?.reduce((prev, curr) => prev && curr.compliant, true);
    // check if vincoli cfu are met
    const vincoloCfuCompliance = getCfuVincoloCompliance(categoriesByType, year, offertaFormativa);

    return categoriesCompliances && vincoloCfuCompliance;
}

export function getDraftInEditModeComplianceForYear(categoriesByYear: TrainingOfferCategoryByYearDataUI): boolean {
    // derive the compliance from all compliances of all type
    //todo add the check that cfu sum of types are at least 60
    const yearCfuCompliant = categoriesByYear.totaleCfu >= 60;
    return yearCfuCompliant && categoriesByYear?.categoriesByActivityType
        ?.reduce((prev, curr) => prev && curr.compliant, true);
}

export function getCfuVincoloCompliance(categoriesByType: TrainingOfferCategoryByTypeDataUI, year: AnnoRiferimentoValues, offertaFormativa: OffertaFormativaInfoViewImpl) {
    const vincoliCfu = offertaFormativa?.configurazione?.vincoli_cfu_per_tipo_attivita;
    if (!vincoliCfu){
        return true;
    } else {
        //todo add the check that cfu sum of type is in the range
        let vincoloCfu = getVincoloCfu(year, categoriesByType, vincoliCfu);
        return categoriesByType.totaleCfu >= vincoloCfu.min && categoriesByType.totaleCfu <= vincoloCfu.max;
    }
}

export function getVincoloCfu(year: AnnoRiferimentoValues, categoriesByType: TrainingOfferCategoryByTypeDataUI, vincoliCfu: VincoliCfuPerTipoAttivita): {min: number; max: number} {
    let vincoloCfuMin;
    let vincoloCfuMax;
    switch (year) {
        case AnnoRiferimentoValues.PRIMO:
            switch (categoriesByType.activityType) {
                case ElementoOffertaFormativaType.CORSO:
                    vincoloCfuMin = vincoliCfu?.cfu_primo_anno_attivita_didattiche_min;
                    vincoloCfuMax = vincoliCfu?.cfu_primo_anno_attivita_didattiche_max;
                    break;
                case ElementoOffertaFormativaType.ATTIVITAEXTRA:
                    vincoloCfuMin = vincoliCfu?.cfu_primo_anno_altre_attivita_min;
                    vincoloCfuMax = vincoliCfu?.cfu_primo_anno_altre_attivita_max;
                    break;
                case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
                    vincoloCfuMin = vincoliCfu?.cfu_primo_anno_attivita_dottorando_min;
                    vincoloCfuMax = vincoliCfu?.cfu_primo_anno_attivita_dottorando_max;
                    break;
            }
            break;
        case AnnoRiferimentoValues.SECONDO:
            switch (categoriesByType.activityType) {
                case ElementoOffertaFormativaType.CORSO:
                    vincoloCfuMin = vincoliCfu?.cfu_secondo_anno_attivita_didattiche_min;
                    vincoloCfuMax = vincoliCfu?.cfu_secondo_anno_attivita_didattiche_max;
                    break;
                case ElementoOffertaFormativaType.ATTIVITAEXTRA:
                    vincoloCfuMin = vincoliCfu?.cfu_secondo_anno_altre_attivita_min;
                    vincoloCfuMax = vincoliCfu?.cfu_secondo_anno_altre_attivita_max;
                    break;
                case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
                    vincoloCfuMin = vincoliCfu?.cfu_secondo_anno_attivita_dottorando_min;
                    vincoloCfuMax = vincoliCfu?.cfu_secondo_anno_attivita_dottorando_max;
                    break;
            }
            break;
        case AnnoRiferimentoValues.TERZO:
            switch (categoriesByType.activityType) {
                case ElementoOffertaFormativaType.CORSO:
                    vincoloCfuMin = vincoliCfu?.cfu_terzo_anno_attivita_didattiche_min;
                    vincoloCfuMax = vincoliCfu?.cfu_terzo_anno_attivita_didattiche_max;
                    break;
                case ElementoOffertaFormativaType.ATTIVITAEXTRA:
                    vincoloCfuMin = vincoliCfu?.cfu_terzo_anno_altre_attivita_min;
                    vincoloCfuMax = vincoliCfu?.cfu_terzo_anno_altre_attivita_max;
                    break;
                case ElementoOffertaFormativaType.ATTIVITATRASVERSALE:
                    vincoloCfuMin = vincoliCfu?.cfu_terzo_anno_attivita_dottorando_min;
                    vincoloCfuMax = vincoliCfu?.cfu_terzo_anno_attivita_dottorando_max;
                    break;
            }
            break;
    }
    return {min: vincoloCfuMin, max: vincoloCfuMax};
}

export function mustSkipCompliancyCheckForYear(anno: AnnoRiferimentoValues, draft: StudyPlanAggiornamentoDataUI, configurazioneOfferta: ConfigurazioneOffertaFormativa) {
    return !configurazioneOfferta?.is_possibile_inserire_corsi_per_anni_successivi && (
        annoInsegnamentoValueToNumber(anno) > annoInsegnamentoValueToNumber(draft.annoCorso)
    );
}



